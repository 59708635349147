import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

export type CustomTypograhyProps = TypographyProps & { weight?: number; size?: string | number };

export const H1: React.FC<CustomTypograhyProps> = ({ children, weight, size, ...otherProps }) => {
  return (
    <Typography
      variant='h1'
      color='textPrimary'
      {...otherProps}
      style={{
        fontWeight: weight ? weight : 700,
        fontSize: size ? size : '2.5rem',
        ...otherProps.style,
      }}
    >
      {children}
    </Typography>
  );
};

export const H2: React.FC<CustomTypograhyProps> = ({ children, weight, size, ...otherProps }) => {
  return (
    <Typography
      variant='h2'
      color='textPrimary'
      {...otherProps}
      style={{
        fontWeight: weight ? weight : 700,
        fontSize: size ? size : '1.5rem',
        ...otherProps.style,
      }}
    >
      {children}
    </Typography>
  );
};

export const Body1: React.FC<CustomTypograhyProps> = ({
  children,
  weight,
  size,
  ...otherProps
}) => {
  return (
    <Typography
      variant='body1'
      color='textPrimary'
      {...otherProps}
      style={{
        fontWeight: weight ? weight : 400,
        fontSize: size ? size : '1rem',
        ...otherProps.style,
      }}
    >
      {children}
    </Typography>
  );
};

export const Body2: React.FC<CustomTypograhyProps> = ({
  children,
  weight,
  size,
  ...otherProps
}) => {
  return (
    <Typography
      variant='body2'
      color='textPrimary'
      {...otherProps}
      style={{
        fontWeight: weight ? weight : 300,
        fontSize: size ? size : '1rem',
        ...otherProps.style,
      }}
    >
      {children}
    </Typography>
  );
};
