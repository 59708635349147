import React from 'react';
import { Body2 } from './ui/Typography';
import { Box } from '@mui/material';
import { Maybe, Module } from '../graphql/codegen/graphql-react';
import { capitalize } from '../helpers/utils';
import ErrorMessage from './ErrorMessage';
import Center from './ui/Center';
import { useSetState } from 'react-use';
import { ls } from '../i18n/translations';
import { useMQ } from '../hook/useMQ';
import { MeasurementsType, ModuleSettingType } from '../graphql/dataTypes';
import NotificationsForm from './forms/NotificationsForm';

interface NotificationsDetailProps {
  module: Maybe<Module>;
  onNotifSavedSuccessfully?: () => void;
}

const NotificationsDetail: React.FC<NotificationsDetailProps> = ({
  module,
  onNotifSavedSuccessfully,
}) => {
  const [state, setState] = useSetState({});
  const [errorFromServer, setErrorFromServer] = React.useState('');
  const { isMobile, isMobileOrTablet } = useMQ();

  if (!module) {
    return null;
  }

  const moduleSettings = (module.ModuleSettings?.settings || []) as ModuleSettingType[];
  const moduleMeasurements = module?.Measurement?.measurements as MeasurementsType;

  return (
    <Box>
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={errorFromServer}
      />
      {Object.keys(moduleMeasurements || {}).length === 0 ||
      !moduleSettings ||
      moduleSettings.length === 0 ? (
        <Center my={4}>
          <Body2 size='1.1rem' textAlign='center'>
            {capitalize(ls.noMeasurementFound)}
          </Body2>
        </Center>
      ) : (
        <>
          <Box my={4} mx={isMobile ? 0 : 4}>
            {Object.keys(moduleSettings || {}).map((ms, i) => {
              return (
                <Box key={i} mt={1.5}>
                  <NotificationsForm
                    moduleSetting={moduleSettings?.[i]}
                    title={`${capitalize(ls.measure)} ${i + 1}`}
                    index={i}
                    module={module}
                    onSettingsSaved={onNotifSavedSuccessfully}
                  />
                </Box>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};

export default NotificationsDetail;
