import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, CheckboxProps } from '@mui/material';
import { Body2, CustomTypograhyProps } from './Typography';
import { colors } from '../../theme';
import Center from './Center';
import { useDarkMode } from '../../hook/useDarkMode';

interface SteielCheckboxProps {
  label: string;
  checked: boolean;
  onChangeValue: (value: boolean) => void;
  labelProps?: CustomTypograhyProps;
  checkedColor?: string;
}

const SteielCheckbox: React.FC<SteielCheckboxProps & CheckboxProps> = ({
  label,
  checked,
  onChangeValue,
  labelProps,
  checkedColor,
  ...checkboxProps
}) => {
  const { isDarkMode } = useDarkMode();
  return (
    <Center>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={(e) => {
                onChangeValue(e.target.checked);
              }}
              sx={{
                mt: -0.3,
                color: isDarkMode ? colors.lightGrey : colors.stroke,
                opacity: 0.6,
                '&.Mui-checked': {
                  color: checkedColor || colors.green,
                  opacity: 1,
                },
              }}
              {...checkboxProps}
            />
          }
          label={
            <Body2
              color={isDarkMode ? colors.lightGrey : colors.stroke}
              weight={600}
              size='0.9rem'
              {...labelProps}
            >
              {label}
            </Body2>
          }
        />
      </FormGroup>
    </Center>
  );
};

export default SteielCheckbox;
