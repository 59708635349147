import React from 'react';
import { Box, LinearProgress } from '@mui/material';
import { ls } from '../../i18n/translations';
import SteielTextInput from '../ui/SteielTextInput';
import SteielButton from '../ui/SteielButton';
import ErrorMessage from '../ErrorMessage';
import { Form, Formik } from 'formik';
import {
  PASSWORD_MIN_LENGTH,
  validateEmail,
  validatePassword,
  validateRequiredField,
} from '../../helpers/validators';
import { capitalize, catchErrors, isObjEmpty, logErr } from '../../helpers/utils';
import { useNavigate } from 'react-router-dom';
import { Body2 } from '../ui/Typography';
import { useMQ } from '../../hook/useMQ';
import { colors } from '../../theme';
import Flex from '../ui/Flex';
import SteielSelect from '../ui/SteielSelect';
import { useSearchParams } from '../../hook/useSearchParams';

interface FormData {
  name: string;
  surname: string;
  company: string;
  email: string;
  password: string;
  repeatPassword: string;
  role: string;
}

const initialFormData: FormData = {
  name: '',
  repeatPassword: '',
  role: ls.viewer,
  surname: '',
  company: '',
  email: '',
  password: '',
};

interface EditCustomerFormProps {
  isNewCustomer?: boolean;
}

const EditCustomerForm: React.FC<EditCustomerFormProps> = ({ isNewCustomer }) => {
  const [errorFromServer, setErrorFromServer] = React.useState('');
  const navigate = useNavigate();
  const { isMobileOrTablet } = useMQ();
  const { userIdParam } = useSearchParams();

  return (
    <Box>
      <Formik
        initialValues={initialFormData}
        validate={(values) => {
          const errors: Partial<FormData> = {};
          const name = validateRequiredField(values.name);
          const surname = validateRequiredField(values.surname);
          const company = validateRequiredField(values.company);
          const email = validateEmail(values.email);
          const password = validatePassword(values.password);
          if (!name.isValid) {
            errors.name = ls.getString(name.errorTranslationKey);
          }
          if (!surname.isValid) {
            errors.surname = ls.getString(surname.errorTranslationKey);
          }
          if (!company.isValid) {
            errors.company = ls.getString(company.errorTranslationKey);
          }
          if (!email.isValid) {
            errors.email = ls.getString(email.errorTranslationKey);
          }
          if (!password.isValid) {
            errors.password = ls
              .getString(password.errorTranslationKey)
              .replace('{0}', `${PASSWORD_MIN_LENGTH}`);
          }
          if (values.password !== values.repeatPassword) {
            errors.password = ls.passwordMismatch;
          }
          // reset errorFromServer
          if (!isObjEmpty(errors)) {
            setErrorFromServer('');
          }
          return errors;
        }}
        onSubmit={async (values, actions) => {
          try {
            // log('submitting', values);
            setErrorFromServer('');
          } catch (error) {
            logErr('error on submit edit user form', error);
            catchErrors(error, setErrorFromServer);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {(props) => {
          const nameError = (props.dirty && props.touched.name && props.errors.name) || '';
          const surnameError = (props.dirty && props.touched.surname && props.errors.surname) || '';
          const companyError = (props.dirty && props.touched.company && props.errors.company) || '';
          const emailError = (props.dirty && props.touched.email && props.errors.email) || '';
          const passwordError =
            (props.dirty && props.touched.password && props.errors.password) || '';

          const firstError =
            emailError || passwordError || surnameError || companyError || errorFromServer || '';

          const labelWidth = isMobileOrTablet ? undefined : 180;
          const textFieldWidth = isMobileOrTablet ? '100%' : 500;
          const rootBoxWidth = isMobileOrTablet
            ? '100%'
            : // @ts-ignore
              labelWidth + textFieldWidth;

          return (
            <Form>
              <Box width={rootBoxWidth}>
                <Flex justifyContent='flex-start'>
                  <SteielTextInput
                    fieldName='name'
                    formProps={props}
                    label={capitalize(ls.name)}
                    labelStyle={{
                      weight: 600,
                      width: labelWidth,
                    }}
                    textFieldProps={{
                      placeholder: capitalize(ls.name),
                      required: true,
                      error: Boolean(nameError),
                      size: 'small',
                      style: { backgroundColor: '#fff', width: textFieldWidth },
                    }}
                  />
                </Flex>
                <Flex mt={1} justifyContent='flex-start'>
                  <SteielTextInput
                    fieldName='surname'
                    formProps={props}
                    label={capitalize(ls.surname)}
                    labelStyle={{
                      weight: 600,
                      width: labelWidth,
                    }}
                    textFieldProps={{
                      placeholder: capitalize(ls.surname),
                      required: true,
                      error: Boolean(surnameError),
                      size: 'small',
                      style: { backgroundColor: '#fff', width: textFieldWidth },
                    }}
                  />
                </Flex>
                <Flex mt={1} justifyContent='flex-start'>
                  <SteielTextInput
                    fieldName='company'
                    formProps={props}
                    label={capitalize(ls.company)}
                    labelStyle={{
                      weight: 600,
                      width: labelWidth,
                    }}
                    textFieldProps={{
                      placeholder: capitalize(ls.company),
                      required: true,
                      error: Boolean(companyError),
                      size: 'small',
                      style: { backgroundColor: '#fff', width: textFieldWidth },
                    }}
                  />
                </Flex>
                <Flex mt={1} justifyContent='flex-start'>
                  <SteielTextInput
                    fieldName='email'
                    formProps={props}
                    label={capitalize(ls.email)}
                    labelStyle={{
                      weight: 600,
                      width: labelWidth,
                    }}
                    textFieldProps={{
                      placeholder: capitalize(ls.email),
                      required: true,
                      type: 'email',
                      error: Boolean(emailError),
                      size: 'small',
                      style: { backgroundColor: '#fff', width: textFieldWidth },
                    }}
                  />
                </Flex>
                <Flex mt={1} justifyContent='flex-start'>
                  <SteielTextInput
                    fieldName='password'
                    formProps={props}
                    eyeIconColor={colors.lightGrey}
                    label={capitalize(ls.password)}
                    labelStyle={{
                      weight: 600,
                      width: labelWidth,
                    }}
                    textFieldProps={{
                      placeholder: capitalize(ls.password),
                      required: true,
                      error: Boolean(passwordError),
                      size: 'small',
                      style: { backgroundColor: '#fff', width: textFieldWidth },
                    }}
                    isPassword
                  />
                </Flex>
                <Flex mt={1} justifyContent='flex-start'>
                  <SteielTextInput
                    fieldName='repeatPassword'
                    formProps={props}
                    eyeIconColor={colors.lightGrey}
                    label={capitalize(ls.confirmPassword)}
                    labelStyle={{
                      weight: 600,
                      width: labelWidth,
                    }}
                    textFieldProps={{
                      placeholder: capitalize(ls.confirmPassword),
                      required: true,
                      size: 'small',
                      style: {
                        backgroundColor: '#fff',
                        width: textFieldWidth,
                      },
                    }}
                    isPassword
                  />
                </Flex>
                <Flex
                  mt={1}
                  justifyContent='flex-start'
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                >
                  <Box>
                    <Body2 weight={600} width={labelWidth || 0}>{`${capitalize(ls.type)}:`}</Body2>
                  </Box>
                  <Box width={isMobileOrTablet ? undefined : 250} mt={isMobileOrTablet ? 0.5 : 0}>
                    <SteielSelect
                      allowedValues={[capitalize(ls.superTechnician)]}
                      onChange={(value) => {}}
                      initialVal={capitalize(ls.superTechnician)}
                    />
                  </Box>
                </Flex>
              </Box>
              {firstError ? (
                <ErrorMessage
                  containerStyle={{
                    my: 2,
                  }}
                  message={firstError}
                />
              ) : (
                <Box />
              )}
              {props.isSubmitting && (
                <Box my={2}>
                  <LinearProgress />
                </Box>
              )}
              <Flex
                mt={6}
                mb={2}
                width={
                  isMobileOrTablet
                    ? undefined
                    : // @ts-ignore
                      textFieldWidth + labelWidth
                }
              >
                <Box flex={1} />
                <Box width={150}>
                  <SteielButton
                    text={isNewCustomer ? ls.create : ls.apply}
                    type='submit'
                    disabled={props.isSubmitting}
                    fullWidth
                    style={{
                      backgroundColor: colors.green,
                      borderColor: colors.green,
                    }}
                  />
                </Box>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default EditCustomerForm;
