import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth } from '../hook/useAuth';
import DeviceSetup from '../pages/authenticated/DeviceSetup';
import DeviceDetail from '../pages/authenticated/DeviceDetail';
import Customers from '../pages/authenticated/Customers';
import Users from '../pages/authenticated/Users';
import Notifications from '../pages/authenticated/Notifications';
import DeviceMeasurements from '../pages/authenticated/DeviceMeasurements';
import Home from '../pages/authenticated/Home';
import NewCustomer from '../pages/authenticated/NewCustomer';
import SignIn from '../pages/not-authenticated/SignIn';
import PasswordRecovery from '../pages/not-authenticated/PasswordRecovery';
import SetNewPassword from '../pages/not-authenticated/SetNewPassword';
import Profile from '../pages/authenticated/Profile';
import DevicesManagement from '../pages/authenticated/DevicesManagement';
import EditCustomer from '../pages/authenticated/EditCustomer';
import NewUser from '../pages/authenticated/NewUser';
import EditUser from '../pages/authenticated/EditUser';
import NewDevice from '../pages/authenticated/NewDevice';
import EditDevice from '../pages/authenticated/EditDevice';
import ChangePassword from '../pages/authenticated/ChangePassword';

export enum PageName {
  root = '/',
  // not authenticated
  signin = '/signin',
  passwordrecovery = '/password-recovery',
  setnewpassword = '/set-new-password',
  // authenticated
  home = '/home',
  profile = '/profile',
  device = '/device?deviceId={0}',
  customers = '/customers',
  deviceMeasurements = '/device-measurements?deviceId={0}&moduleId={1}',
  devicesManagement = '/devices-management',
  deviceSetup = '/device-setup?deviceId={0}',
  newCustomer = '/new-customer',
  editCustomer = '/edit-customer?userId={0}',
  notificationsManagement = '/notifications-management',
  users = '/users',
  newUser = '/new-user',
  editUser = '/edit-user?userId={0}',
  newDevice = '/new-device',
  editDevice = '/edit-device?deviceId={0}',
  changePassword = '/change-password',
}

const Navigator: React.FC = () => {
  const auth = useAuth();

  if (auth.isLogged) {
    return (
      <Routes>
        <Route
          path={PageName.deviceMeasurements.replace('?deviceId={0}&moduleId={1}', '')}
          element={<DeviceMeasurements />}
        />
        <Route path={PageName.deviceSetup.replace('?deviceId={0}', '')} element={<DeviceSetup />} />
        <Route path={PageName.device.replace('?deviceId={0}', '')} element={<DeviceDetail />} />
        <Route path={PageName.notificationsManagement} element={<Notifications />} />
        <Route path={PageName.devicesManagement} element={<DevicesManagement />} />
        <Route path={PageName.customers} element={<Customers />} />
        <Route path={PageName.users} element={<Users />} />
        <Route path={PageName.newUser} element={<NewUser />} />
        <Route path={PageName.editUser.replace('?userId={0}', '')} element={<EditUser />} />
        <Route path={PageName.newDevice} element={<NewDevice />} />
        <Route path={PageName.editDevice.replace('?deviceId={0}', '')} element={<EditDevice />} />
        <Route path={PageName.profile} element={<Profile />} />
        <Route path={PageName.newCustomer} element={<NewCustomer />} />
        <Route path={PageName.editCustomer.replace('?userId={0}', '')} element={<EditCustomer />} />
        <Route path={PageName.changePassword} element={<ChangePassword />} />
        <Route path={PageName.home} element={<Home />} />
        <Route path={PageName.root} element={<Home />} />
        <Route path='*' element={<Home />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path={PageName.setnewpassword} element={<SetNewPassword />} />
      <Route path={PageName.passwordrecovery} element={<PasswordRecovery />} />
      <Route path={PageName.signin} element={<SignIn />} />
      <Route path={PageName.root} element={<SignIn />} />
      <Route path='*' element={<SignIn />} />
    </Routes>
  );
};

export default Navigator;
