import { proxy } from 'valtio';

interface GlobalState {
  deviceInterfaceOpen: boolean[];
  secondaryParameterOpen: boolean;
  companyLogoUrl?: string;
}

export const initialGlobalState: GlobalState = {
  deviceInterfaceOpen: [false, false, false, false],
  secondaryParameterOpen: false,
  companyLogoUrl: undefined,
};

export const globalState = proxy<GlobalState>(initialGlobalState);
