import React, { PropsWithChildren } from 'react';
import {
  Box,
  SwipeableDrawer,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  Hidden,
  Drawer,
  createStyles,
  Theme,
  AppBar,
  Toolbar,
  IconButtonProps,
} from '@mui/material';
import { useAuth } from '../../hook/useAuth';
import { capitalize, logErr, removeHTHBgBody } from '../../helpers/utils';
import { assets, CustomSvgIcon } from '../../helpers/assets';
import { ls } from '../../i18n/translations';
import { useMQ } from '../../hook/useMQ';
import Logo from './Logo';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PageName } from '../../routes';
import SteielDialog from './SteielDialog';
import { navbarHeight } from './MainNavbar';
import { Body1, Body2 } from './Typography';
import Center from './Center';
import { colors } from '../../theme';
import { makeStyles } from 'tss-react/mui';
import config from '../../config';
import Flex from './Flex';
import { OnlyOnTabletAndDesktop, OnlyOnMobileAndTablet } from './Responsive';
import LightDarkModeSwitch from './LightDarkModeSwitch';
import { useDarkMode } from '../../hook/useDarkMode';
import { useMount } from 'react-use';

export const drawerWidth = 270;
export const DASHBOARD_VERSION =
  '1.18.12' + (config.env.isDev ? '-dev' : config.env.isStaging ? '-staging' : '');

const useStyles = makeStyles()((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('xl')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      height: navbarHeight,
      [theme.breakpoints.down('xs')]: {
        background: '#fff',
        color: '#fff',
      },
      [theme.breakpoints.up('xl')]: {
        background: '#fff',
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        zIndex: 9999,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('xl')]: {
        display: 'none',
      },
    },
    settingsButton: {
      marginRight: theme.spacing(2),
    },
    toolbar: {
      height: navbarHeight,
      color: theme.palette.text.primary,
      background: '#fff',
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

interface NavItemProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  Icon: React.ReactNode;
  textIcon?: string;
}

export const NavItem: React.FC<NavItemProps & IconButtonProps> = ({
  Icon,
  onClick,
  textIcon,
  ...iconButtonProps
}) => {
  const { isMobile } = useMQ();

  return (
    <IconButton
      color='inherit'
      onClick={onClick}
      {...iconButtonProps}
      style={{
        height: navbarHeight - 8,
        width: navbarHeight - 8,
        color: colors.blue,
        ...iconButtonProps.style,
      }}
    >
      <Center flexDirection='column'>
        {Icon}
        {textIcon ? (
          <Body1 style={{ marginTop: -6 }} size={isMobile ? '0.8rem' : undefined}>
            {textIcon}
          </Body1>
        ) : null}
      </Center>
    </IconButton>
  );
};

interface DrawerItemProps {
  title: string;
  srcIcon?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  noIcon?: boolean;
  imgStyle?: React.CSSProperties;
  itemStyle?: React.CSSProperties;
}

const DrawerItem: React.FC<DrawerItemProps> = ({
  onClick,
  title,
  srcIcon,
  noIcon,
  imgStyle,
  itemStyle,
}) => {
  const theme = useTheme();
  return (
    <ListItem
      button
      onClick={onClick}
      style={{
        background: theme.palette.text.primary,
        paddingLeft: 32,
        ...itemStyle,
      }}
    >
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        {srcIcon && !noIcon ? (
          <ListItemIcon>
            <CustomSvgIcon
              src={srcIcon}
              style={{ color: 'white', ...imgStyle }}
              onClick={() => {}}
            />
          </ListItemIcon>
        ) : null}
        <ListItemText>
          <Body2 style={{ color: '#fff', marginLeft: -16 }}>{title}</Body2>
        </ListItemText>
      </Box>
    </ListItem>
  );
};

const DashboardLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const [showDrawer, setShowDrawer] = React.useState(false);
  const theme = useTheme();
  const auth = useAuth();
  const { classes } = useStyles();
  const { isUpSm, isDownMd, isDownLg, isDownSm, isUpLg, isUpXl, isMobile, isTablet, isDesktop } =
    useMQ();
  const [showDisconnectDialog, setShowDisconnectDialog] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode } = useDarkMode();

  useMount(() => {
    removeHTHBgBody();
  });

  const isInUserManagementPage =
    location.pathname.startsWith(PageName.users) ||
    location.pathname.startsWith(PageName.editUser.split('?')[0]) ||
    location.pathname.startsWith(PageName.customers) ||
    location.pathname.startsWith(PageName.editCustomer.split('?')[0]) ||
    location.pathname.startsWith(PageName.profile) ||
    location.pathname.startsWith(PageName.newUser) ||
    location.pathname.startsWith(PageName.newCustomer) ||
    location.pathname.startsWith(PageName.changePassword) ||
    location.pathname.startsWith(PageName.notificationsManagement);
  const isInDevicesManagementPage =
    location.pathname.startsWith(PageName.devicesManagement) ||
    location.pathname.startsWith(PageName.deviceSetup.split('?')[0]) ||
    location.pathname.startsWith(PageName.editDevice.split('?')[0]) ||
    location.pathname.startsWith(PageName.newDevice);
  const isInDashboardPage =
    (location.pathname.startsWith(PageName.home) ||
      location.pathname === PageName.root ||
      location.pathname.startsWith(PageName.device.split('?')[0]) ||
      location.pathname.startsWith(PageName.deviceMeasurements.split('?')[0])) &&
    !isInDevicesManagementPage;

  const drawer = (
    <Box
      bgcolor={isDarkMode ? colors.darkMenuBg : colors.white}
      height='100%'
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='space-between'
      // @ts-ignore
      className={classes.drawerPaper}
    >
      <Box width='100%'>
        <Box bgcolor={isDarkMode ? colors.darkMenuBg : '#fff'} style={{ height: navbarHeight }}>
          <Box ml={5} pt={1.4}>
            <Link
              to={PageName.home}
              onClick={() => {
                setShowDrawer(false);
              }}
            >
              <Logo />
            </Link>
          </Box>
        </Box>
        <Box width='100%' bgcolor={isDarkMode ? colors.darkMenuBg : colors.white}>
          <Link
            to={PageName.home}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              setShowDrawer(false);
            }}
          >
            <Box display='flex' width='100%' sx={{ padding: 2 }}>
              <Box width={45}>
                <img
                  src={isInDashboardPage ? assets.menuDashboardActive : assets.menuDashboard}
                  alt=''
                  style={{ width: 24, height: 24, marginLeft: 8, marginRight: 16 }}
                />
              </Box>
              <Body1 mt={0.2} color={isInDashboardPage ? colors.lightBlue : colors.blue}>
                {ls.dashboard}
              </Body1>
            </Box>
          </Link>
          {auth.isAdmin || auth.isAdmin2 || auth.isSuperTechnician || auth.isTechnician ? (
            <>
              <Box className='separator' mx={2} mt={-1} />
              <Link
                to={PageName.devicesManagement}
                style={{ textDecoration: 'none' }}
                onClick={() => {
                  setShowDrawer(false);
                }}
              >
                <Box display='flex' width='100%' sx={{ padding: 2 }}>
                  <Box width={45}>
                    <img
                      src={
                        isInDevicesManagementPage
                          ? assets.menuDeviceManagementActive
                          : assets.menuDeviceManagement
                      }
                      alt=''
                      style={{ width: 24, height: 24, marginLeft: 8, marginRight: 16 }}
                    />
                  </Box>
                  <Body1
                    mt={0.2}
                    color={isInDevicesManagementPage ? colors.lightBlue : colors.blue}
                  >
                    {ls.deviceManagement}
                  </Body1>
                </Box>
              </Link>
              <Box className='separator' mx={2} mt={-0.8} />
              <Link
                to={PageName.users}
                style={{ textDecoration: 'none' }}
                onClick={() => {
                  setShowDrawer(false);
                }}
              >
                <Box display='flex' width='100%' sx={{ padding: 2, mt: 0.1 }}>
                  <Box width={45}>
                    <img
                      src={isInUserManagementPage ? assets.menuUserActive : assets.menuUser}
                      alt=''
                      style={{ width: 19, height: 19, marginLeft: 10, marginRight: 16 }}
                    />
                  </Box>
                  <Body1 mt={-0.2} color={isInUserManagementPage ? colors.lightBlue : colors.blue}>
                    {ls.usersManagement}
                  </Body1>
                </Box>
              </Link>
            </>
          ) : null}
          {isMobile ? (
            <>
              <Box className='separator' mx={2} mt={-0.8} />
              <Box
                style={{ textDecoration: 'none', cursor: 'pointer' }}
                onClick={() => {
                  setShowDrawer(false);
                  setShowDisconnectDialog(true);
                }}
              >
                <Box display='flex' width='100%' sx={{ padding: 2, mt: -0.5 }}>
                  <Box width={45}>
                    <CustomSvgIcon
                      src={assets.logout}
                      style={{ width: 22, height: 22, marginLeft: 12, marginRight: 16 }}
                      onClick={() => {}}
                    />
                  </Box>
                  <Body1 mt={0.5} color={colors.blue}>
                    {capitalize(ls.logout)}
                  </Body1>
                </Box>
              </Box>
            </>
          ) : null}
          <Box mt={2.5}>
            <Flex justifyContent='flex-start'>
              <Body2 style={{ fontSize: '0.8rem', paddingLeft: 16 }}>
                {`${capitalize(ls.loggedInAs)}:`}
              </Body2>
              <Link to={PageName.profile} style={{ textDecoration: 'none' }}>
                <Body2 ml={0.5} style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                  {(auth.user?.email || '-').length >= 29
                    ? (auth.user?.email || '-').slice(0, 26) + '...'
                    : (auth.user?.email || '-').slice(0, 28)}
                </Body2>
              </Link>
            </Flex>
          </Box>
          <Box>
            <Flex justifyContent='flex-start'>
              <Body2 style={{ fontSize: '0.8rem', paddingLeft: 16 }}>
                {`${capitalize(ls.company)}:`}
              </Body2>
              <Body2 ml={0.5} style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                {auth.user?.Company?.name || '-'}
              </Body2>
            </Flex>
          </Box>
          <Box>
            <Flex justifyContent='flex-start'>
              <Body2 style={{ fontSize: '0.8rem', paddingLeft: 16 }}>
                {`${capitalize(ls.role)}:`}
              </Body2>
              <Body2 ml={0.5} style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                {capitalize(
                  // @ts-ignore
                  ls[((auth.user?.roles || [])[0] || '').toLowerCase()],
                ) || '-'}
              </Body2>
            </Flex>
          </Box>
          <OnlyOnMobileAndTablet>
            <Box>
              <LightDarkModeSwitch />
            </Box>
          </OnlyOnMobileAndTablet>
          <Box mt={isMobile || isTablet ? 2 : 4}>
            <Body2
              style={{ fontSize: '0.8rem', paddingLeft: 16, fontStyle: 'italic' }}
            >{`${ls.version} app: ${DASHBOARD_VERSION}`}</Body2>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    // @ts-ignore
    <div className={classes.root}>
      <SteielDialog
        open={showDisconnectDialog}
        content={ls.disconnectQuestion}
        title={ls.confirm}
        onOkActionClick={async () => {
          setShowDisconnectDialog(false);
          try {
            await auth.signOut();
            navigate(PageName.root, { replace: true });
          } catch (error) {
            logErr('error on signing out', error);
          }
        }}
        onClose={() => {
          setShowDisconnectDialog(false);
        }}
        onKoActionClick={() => {
          setShowDisconnectDialog(false);
        }}
        okLabel={capitalize(ls.yes)}
        koLabel={capitalize(ls.no)}
      />
      {/* @ts-ignore */}
      <AppBar position='fixed' elevation={0} className={classes.appBar}>
        {/* @ts-ignore */}
        <Toolbar
          sx={{ boxShadow: isMobile ? 0 : 1 }}
          // @ts-ignore
          className={classes.toolbar}
          style={{
            backgroundColor: isMobile
              ? isDarkMode
                ? colors.darkBackground
                : colors.background
              : isDarkMode
              ? colors.darkMenuBg
              : '#fff',
          }}
        >
          <NavItem
            onClick={() => setShowDrawer(true)}
            Icon={
              isDarkMode ? (
                <CustomSvgIcon
                  src={assets.menuDark}
                  style={{ width: 32, height: 32 }}
                  onClick={() => {}}
                />
              ) : (
                <CustomSvgIcon
                  src={assets.menu}
                  style={{ width: 32, height: 32 }}
                  onClick={() => {}}
                />
              )
            }
            // @ts-ignore
            className={classes.menuButton}
            style={{ marginLeft: -12 }}
          />
          <Hidden xlUp>
            {!isMobile ? (
              <Box
                height={25}
                border='0.5px solid'
                ml={-1}
                mr={1.5}
                borderColor={colors.grey}
                bgcolor={colors.grey}
                borderRadius={2}
                style={{ opacity: 0.15 }}
              />
            ) : null}
            <Center
              ml={isUpSm ? 2 : -3}
              width='100%'
              justifyContent={isMobile ? 'center' : 'flex-start'}
            >
              <Link to={PageName.home}>
                <Logo />
              </Link>
            </Center>
          </Hidden>
          <Box display='flex' flexGrow={1} />
          <Box display='flex' flexDirection='row' alignItems='center'>
            {/* <NavItem
              onClick={() => setShowDrawer(true)}
              Icon={
                <CustomSvgIcon
                  src={assets.menu}
                  style={{ width: 32, height: 32 }}
                  onClick={() => {}}
                />
              }
              // @ts-ignore
              className={classes.menuButton}
            /> */}
            {/* dark/light mode */}
            <OnlyOnTabletAndDesktop>
              <LightDarkModeSwitch />
            </OnlyOnTabletAndDesktop>
            <NavItem
              onClick={() => {
                navigate(PageName.profile);
              }}
              Icon={
                isDarkMode ? (
                  <CustomSvgIcon
                    src={assets.profileDark}
                    style={{ width: 32, height: 32 }}
                    onClick={() => {}}
                  />
                ) : (
                  <CustomSvgIcon
                    src={assets.profile}
                    style={{ width: 32, height: 32 }}
                    onClick={() => {}}
                  />
                )
              }
              // @ts-ignore
              className={classes.settingsButton}
              textIcon={ls.profile.toUpperCase()}
              style={{ marginRight: -6 }}
            />
            {!isMobile ? (
              <NavItem
                onClick={() => {
                  setShowDisconnectDialog(true);
                }}
                Icon={
                  isDarkMode ? (
                    <CustomSvgIcon
                      src={assets.logoutDark}
                      style={{ width: 32, height: 32 }}
                      onClick={() => {}}
                    />
                  ) : (
                    <CustomSvgIcon
                      src={assets.logout}
                      style={{ width: 32, height: 32 }}
                      onClick={() => {}}
                    />
                  )
                }
                textIcon={ls.logout.toUpperCase()}
              />
            ) : null}
          </Box>
        </Toolbar>
      </AppBar>
      {/* @ts-ignore */}
      <nav className={classes.drawer} aria-label='menu'>
        <Hidden xlUp>
          <SwipeableDrawer
            anchor='left'
            open={showDrawer}
            onClose={() => {
              setShowDrawer(false);
            }}
            onOpen={() => {
              setShowDrawer(true);
            }}
            disableBackdropTransition
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </SwipeableDrawer>
        </Hidden>
        <Hidden xlDown>
          <Drawer variant='permanent' open>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      {isUpXl ? (
        <Box ml={5} pt={1.4} position='fixed' top={0} left={0} zIndex={9999}>
          <Link to={PageName.home}>
            <Logo />
          </Link>
        </Box>
      ) : null}
      <Box
        style={{
          marginTop: navbarHeight + 16,
          marginBottom: 64,
          marginLeft: isMobile ? 0 : 16,
          marginRight: isMobile ? 0 : 16,
        }}
        width='100%'
        height='100%'
      >
        {children}
      </Box>
    </div>
  );
};

export default DashboardLayout;
