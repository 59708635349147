import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { CssBaseline, PaletteMode, ThemeProvider } from '@mui/material';
import { createCustomTheme } from './theme';
import { apolloClient } from './graphql';
import '@fontsource/roboto-condensed';
// fix bigint type
import 'json-bigint-patch';
import './css/global.css';
import AppContent from './AppContent';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { enableReactUse } from '@legendapp/state/config/enableReactUse';

enableReactUse();

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

// use this on app children to get current colorMode
// const colorMode = React.useContext(ColorModeContext);

const App: React.FC = () => {
  const [mode, setMode] = React.useState<PaletteMode>(
    localStorage.getItem('theme') === 'dark' ? 'dark' : 'light',
  );

  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('theme', newMode);
          return newMode;
        });
      },
    }),
    [],
  );
  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createCustomTheme(mode), [mode]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ApolloProvider client={apolloClient}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppContent />
          </ThemeProvider>
        </ColorModeContext.Provider>
      </ApolloProvider>
    </LocalizationProvider>
  );
};

export default App;
