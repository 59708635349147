import React from 'react';
import { Box, MenuItem, Select } from '@mui/material';
import { useSetState } from 'react-use';

// @ts-ignore
const allowedValues: number[] = Array(61)
  .fill(0)
  .map((el, i) => {
    if (i > 0 && i % 5 === 0) {
      return i;
    } else {
      return null;
    }
  })
  .filter((el) => el !== null);

interface SamplingTimeSelectProps {
  onChange: (min: number) => void;
  initialVal?: number;
}

const SamplingTimeSelect: React.FC<SamplingTimeSelectProps> = ({ initialVal, onChange }) => {
  if (initialVal === undefined || initialVal === null) {
    initialVal = 30;
  }
  const [state, setState] = useSetState({
    minutes: initialVal,
  });

  const menuItems: React.ReactNode[] = [];
  menuItems.push(
    <MenuItem key={'firstval'} value={''}>
      {''}
    </MenuItem>,
  );
  for (let i = 0; i < allowedValues.length; i++) {
    const allowedValue = allowedValues[i];
    menuItems.push(
      <MenuItem key={allowedValue} value={allowedValue}>
        {`${allowedValue} min`}
      </MenuItem>,
    );
  }

  return (
    <Box>
      <Select
        labelId='sampling time select'
        id='sampling-time-select'
        value={state.minutes}
        size='small'
        fullWidth
        style={{ fontSize: '1.1rem', height: 40 }}
        onChange={(e) => {
          setState({
            minutes: Number(e.target.value),
          });
          onChange(Number(e.target.value));
        }}
      >
        {menuItems}
      </Select>
    </Box>
  );
};

export default SamplingTimeSelect;
