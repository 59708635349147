import React from 'react';
import DashboardLayout from '../../components/ui/DashboardLayout';
import { PageName } from '../../routes';
import Flex from '../../components/ui/Flex';
import { Body1 } from '../../components/ui/Typography';
import { ls } from '../../i18n/translations';
import { Box } from '@mui/material';
import Breadcrumbs from '../../components/ui/Breadcrumbs';
import UserProfileForm from '../../components/forms/UserProfileForm';
import { useMQ } from '../../hook/useMQ';
import { useMount } from 'react-use';
import config from '../../config';
import { useDarkMode } from '../../hook/useDarkMode';
import { colors } from '../../theme';

const Profile: React.FC = () => {
  const { isMobileOrTablet } = useMQ();
  const { isDarkMode } = useDarkMode();

  useMount(() => {
    if (!config.env.isDev) {
      window.scroll(0, 0);
    }
  });

  return (
    <DashboardLayout>
      <Box p={2} pt={isMobileOrTablet ? 0 : 2}>
        <Breadcrumbs
          // comeBackLink={PageName.home}
          backPageName={ls.dashboard}
        />
      </Box>
      <Box mx={isMobileOrTablet ? 2 : 0}>
        <Box
          borderRadius={1}
          sx={{ boxShadow: 1, maxWidth: 1000 }}
          bgcolor={isDarkMode ? colors.darkBackground2 : 'white'}
          pb={2}
        >
          <Flex p={2}>
            <Box>
              <Body1 weight={600} size='1.1rem'>
                {ls.userProfile}
              </Body1>
            </Box>
          </Flex>
          <Box className='separator' width='100%' />
          <Box m={2}>
            <UserProfileForm />
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Profile;
