import React from 'react';
import { assets } from '../../helpers/assets';
import { useGS } from '../../hook/useGS';

const Logo: React.FC<
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = (props) => {
  const { snap: gsSnap } = useGS();
  return (
    <img
      alt='Logo'
      src={props.src || gsSnap.companyLogoUrl || assets.logo}
      {...{ props, mini: undefined }}
      style={{ height: 44, ...props.style }}
    />
  );
};

export default Logo;
