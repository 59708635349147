import React from 'react';
import { parseCookies } from 'nookies';
import { useMount } from 'react-use';
import Splash from './components/Splash';
import { useAuth } from './hook/useAuth';
import Navigator from './routes';
import { Box } from '@mui/material';
import { allowedLang, defaultLang, ls } from './i18n/translations';
import { useGS } from './hook/useGS';
import { isHTHCompany, updateThemeMetaAndManifest } from './helpers/utils';

const AppContent: React.FC = () => {
  const auth = useAuth();
  const { state: gsState } = useGS();
  const [loading, setLoading] = React.useState(true);

  useMount(() => {
    let timeout: number | undefined;
    let timeoutTime = 1000;
    if (parseCookies().token) {
      timeoutTime = 30000;
      auth.me.load();
    } else {
      updateThemeMetaAndManifest();
      // set lang
      const userLang = isHTHCompany() ? 'fr' : defaultLang;
      if (allowedLang.includes(userLang)) {
        ls.setLanguage(userLang);
      }
    }
    timeout = setTimeout(() => {
      setLoading(false);
    }, timeoutTime) as any as number;

    return () => {
      clearTimeout(timeout);
    };
  });

  React.useEffect(() => {
    if ((!auth.me.meError && !auth.me.meLoading && auth.me.meData) || auth.me.meError) {
      updateThemeMetaAndManifest({
        companyLogoUrl: auth.me.meData?.me?.user?.Company?.logoUrl || undefined,
        documentTitle: auth.me.meData?.me?.user?.Company?.name || undefined,
      });
      // set lang
      const userLang =
        auth.me.meData?.me?.user?.languageCode?.toLowerCase() ||
        (isHTHCompany() ? 'fr' : defaultLang);
      if (allowedLang.includes(userLang)) {
        ls.setLanguage(userLang);
      }
      setLoading(false);
    }
  }, [auth.isLogged, auth.me.meData, auth.me.meError, auth.me.meLoading, gsState]);

  return (
    <Box width='100%' height='100%'>
      {loading ? <Splash /> : <Navigator />}
    </Box>
  );
};

export default AppContent;
