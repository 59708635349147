import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import config from '../config';
import { ls } from '../i18n/translations';
import {
  Device,
  LanguageCode,
  MachineType,
  MachineVersion,
  Module,
  UnitOfMeasure,
} from '../graphql/codegen/graphql-react';
import { AlarmsType, ModuleSettingsType, ParametersType } from '../graphql/dataTypes';
import sdkClient from '../graphql/sdkClient';
import { globalState } from '../store/globalStore';
import { getGlobalModuleSetting } from './globalModuleSettings';
import { colors } from '../theme';
import { assets } from './assets';
// import { Device } from '../graphql/codegen/graphql-react';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
export { dayjs };

/**
 * async wait for custom time
 *
 * @param msTime milliseconds to wait
 */
export const wait = (msTime: number = 1000): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), msTime);
  });
};

export const placeholders = ['{0}', '{1}', '{2}', '{3}'];

export const timeoutPromise = <T>(timeout: number, err: Error, promise: Promise<T>): Promise<T> => {
  return new Promise(function (resolve, reject) {
    promise.then(resolve, reject);
    setTimeout(reject.bind(null, err), timeout);
  });
};

export const getErrorMessage = (error: any): string => {
  let errorMsg: string = '';
  switch (typeof error) {
    case 'string':
      return error;
    case 'object':
      if (!error || !error.message) {
        return '';
      }
      if (
        error.networkError &&
        error.networkError.result &&
        error.networkError.result.errors.length
      ) {
        errorMsg = error.networkError.result.errors[0].message;
      }
      if (!errorMsg) {
        errorMsg = error.message;
      }
      errorMsg = errorMsg
        .replace('GraphQL error: ', '')
        .replace('Network error: ', '')
        .replace('Unexpected token T in JSON at position 0', ls.tooReqError);
      // eslint-disable-next-line no-case-declarations
      const errorParsed = errorMsg.match(/^(.*): {".*$/);
      if (errorParsed) {
        errorMsg = errorParsed[1];
      }
      let errTranslated = ls.getString(replaceAll(errorMsg, ' ', '_'));
      if (!errTranslated) {
        const notFoundUserMatch = errorMsg.match(/user.*not found/);
        const invalidCredentialsMatch = errorMsg.match(/invalid credentials/);
        const badOldPasswordMatch = errorMsg.match(/bad old password/);
        if (notFoundUserMatch && notFoundUserMatch.length > 0) {
          errTranslated = ls.userNotFound;
        } else if (invalidCredentialsMatch && invalidCredentialsMatch.length > 0) {
          errTranslated = ls.invalidCredentials;
        } else if (badOldPasswordMatch && badOldPasswordMatch.length > 0) {
          errTranslated = ls.badOldPassword;
        }
      }
      return errTranslated || errorMsg;
    case 'undefined':
      return '';
    default:
      return ls.somethingWentWrong;
  }
};

export const catchErrors = (error: any, displayError: (e: string) => void) => {
  displayError(getErrorMessage(error));
};

export const replaceAll = (str: string, find: string, replace: string) => {
  return str.replace(new RegExp(find, 'g'), replace);
};

export const capitalize = (str: string, onlyFirstChar: boolean = true): string => {
  if (!str) {
    return '';
  }
  if (onlyFirstChar) {
    return str[0].toUpperCase() + str.slice(1);
  }
  return str.toLowerCase().replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
};

export const isValidDate = (d: any): boolean => {
  // @ts-ignore
  return d instanceof Date && !isNaN(d);
};

export const log = (...dataToLog: any[]) => {
  if (config.env.isDev) {
    console.log(...dataToLog);
  }
};

export const logErr = (...dataToLog: any[]) => {
  // if (config.env.isDev) {
  console.error(...dataToLog);
  // }
};

export const isBrowser = typeof window !== 'undefined';

export const isObjEmpty = (o: object) => {
  if (!o) {
    return true;
  }
  return Object.keys(o).length === 0;
};

// get the IANA timezone of the browser
// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
export const getBrowserTimezone = (): string => {
  if (!isBrowser) {
    return '';
  }
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timezone;
};

export const isNotNullAndNotUndefined = (obj: any): boolean => obj !== undefined && obj !== null;
export const isNullOrUndefined = (obj: any): boolean => obj === undefined || obj === null;

export const refetchQuery = async (refetchFunc: any, times = 10, msThrottle = 3000) => {
  for (let i = 0; i < times; i++) {
    log('refetching query', i + 1);
    try {
      await refetchFunc();
    } catch (error) {
      logErr('error on refetchQuery', error);
    } finally {
      await wait(msThrottle);
    }
  }
};

export const randomWait = (
  maxMsToWait: number = 1000,
  minMsToWait: number = 500,
): Promise<void> => {
  let msToWait = Math.floor(Math.random() * (maxMsToWait + 1));
  if (msToWait < 0) {
    msToWait = 1000;
  }
  if (msToWait < minMsToWait) {
    msToWait = minMsToWait;
  }
  return new Promise((resolve) => {
    setTimeout(() => resolve(), msToWait);
  });
};

export const minMaxCap = (min: number, max: number, value: number) => {
  return Math.min(max, Math.max(min, value));
};

export const randomFloat = (min: number, max: number, precision: number = 1) =>
  Number((Math.random() * (max - min) + min).toFixed(precision));

export const getCompanyDomaninFromUrl = (): string => {
  const hostname = document.location.hostname;
  let output = hostname?.split('.')?.[0] || '';
  if (output === 'www') {
    output = hostname?.split('.')?.[1] || '';
  }
  return output;
};

export const getSupportedLanguageCode = (languageCode: string | null | undefined): LanguageCode => {
  if (!languageCode) {
    return LanguageCode.En;
  }
  switch (languageCode.toLowerCase()) {
    case LanguageCode.It:
      return LanguageCode.It;
    case LanguageCode.En:
      return LanguageCode.En;
    case LanguageCode.Fr:
      return LanguageCode.Fr;
    default:
      return LanguageCode.En;
  }
};

export const getLanguageFromCode = (languageCode: string | null | undefined): string => {
  if (!languageCode) {
    return capitalize(ls.english);
  }
  switch (languageCode.toLowerCase()) {
    case LanguageCode.It:
      return capitalize(ls.italian);
    case LanguageCode.En:
      return capitalize(ls.english);
    case LanguageCode.Fr:
      return capitalize(ls.french);
    case LanguageCode.Es:
      return capitalize(ls.spanish);
    default:
      return capitalize(ls.english);
  }
};
export const getLanguageCode = (language: string | null | undefined): LanguageCode => {
  if (!language) {
    return LanguageCode.En;
  }
  switch (language.toLowerCase()) {
    case ls.italian.toLowerCase():
      return LanguageCode.It;
    case ls.english.toLowerCase():
      return LanguageCode.En;
    case ls.french.toLowerCase():
      return LanguageCode.Fr;
    case ls.spanish.toLowerCase():
      return LanguageCode.Es;
    default:
      return LanguageCode.En;
  }
};

export const getInterfaceName = (module?: Module | null): string => {
  if (!module) {
    return '-';
  }
  let output: string = '-';
  switch (module.type) {
    case MachineType.Cycleau: {
      if (module.modelVersion === MachineVersion.Pro) {
        output = ls.hthProInterfaceName;
      } else if (module.modelVersion === MachineVersion.First) {
        output = ls.hthInterfaceName;
      } else {
        output = capitalize(ls.unknown);
      }
      break;
    }
    case MachineType.Mc14Evo: {
      if (module.modelVersion === MachineVersion.Mc14evoPHRxCle12Tc) {
        output = ls.mc14evoPHRxCle12TcInterfaceName;
      } else if (module.modelVersion === MachineVersion.Mc14evoPHRxCacTc) {
        output = ls.mc14evoPHRxCacTcInterfaceName;
      } else if (module.modelVersion === MachineVersion.Mc14evo_4CanaliTc) {
        output = ls.mc14evo_4CanaliTcInterfaceName;
      } else {
        output = capitalize(ls.unknown);
      }
      break;
    }
    case MachineType.Mco14Evo: {
      if (module.modelVersion === MachineVersion.Mco14_3SezCl2) {
        output = ls.mco14_3SezCl2InterfaceName;
      } else if (module.modelVersion === MachineVersion.Mco14_5SezCl2ClTClC) {
        output = ls.mco14_5SezCl2ClTClCInterfaceName;
      } else {
        output = capitalize(ls.unknown);
      }
      break;
    }
    case MachineType.NovaNsp: {
      if (module.modelVersion === MachineVersion.Nsp161CMin) {
        output = ls.nsp161CMinInterfaceName;
      } else if (module.modelVersion === MachineVersion.Nsp161Ma) {
        output = ls.nsp161MAInterfaceName;
      } else if (module.modelVersion === MachineVersion.Nsp162PHRx) {
        output = ls.nsp162PHRxInterfaceName;
      } else if (module.modelVersion === MachineVersion.Nsp163ACle11_12) {
        output = ls.nsp163ACle11_12InterfaceName;
      } else if (module.modelVersion === MachineVersion.Nsp163BCle16) {
        output = ls.nsp163BCle16InterfaceName;
      } else {
        output = capitalize(ls.unknown);
      }
      break;
    }
    case MachineType.Ef315: {
      if (module.modelVersion === MachineVersion.PHRxTc) {
        output = ls.pHRxTcInterfaceName;
      } else if (module.modelVersion === MachineVersion.PHClTc) {
        output = ls.pHClTcInterfaceName;
      } else if (module.modelVersion === MachineVersion.PHCacTc) {
        output = ls.pHCacTcInterfaceName;
      } else {
        output = capitalize(ls.unknown);
      }
      break;
    }
    case MachineType.Evolution: {
      if (module.modelVersion === MachineVersion.Ew2Pool) {
        output = ls.ew2PoolInterfaceName;
      } else {
        output = capitalize(ls.unknown);
      }
      break;
    }
    case MachineType.Ew2Pool: {
      if (module.modelVersion === MachineVersion.PHOrpTc) {
        output = ls.ew2Pool_pHOrpTcInterfaceName;
      } else if (module.modelVersion === MachineVersion.PHCacTc) {
        output = ls.ew2Pool_pHCacTcInterfaceName;
      } else if (module.modelVersion === MachineVersion.PHCleTc) {
        output = ls.ew2Pool_pHCleTcInterfaceName;
      } else {
        output = capitalize(ls.unknown);
      }
      break;
    }
    case MachineType.Nova2Pool: {
      if (module.modelVersion === MachineVersion.PHOrpTc) {
        output = ls.nova2Pool_pHOrpTcInterfaceName;
      } else if (module.modelVersion === MachineVersion.PHCacTc) {
        output = ls.nova2Pool_pHCacTcInterfaceName;
      } else if (module.modelVersion === MachineVersion.PHCleTc) {
        output = ls.nova2Pool_pHCleTcInterfaceName;
      } else {
        output = capitalize(ls.unknown);
      }
      break;
    }
    default: {
      output = '-';
    }
  }
  if (output !== '-' && module.name) {
    output += ` (${module.name})`;
  }
  return output;
};

export const parseUnitOfMeasure = (uom: string): string => {
  switch (uom) {
    case UnitOfMeasure.C: {
      return '°C';
    }
    case UnitOfMeasure.F: {
      return '°F';
    }
    case UnitOfMeasure.Ph: {
      return 'pH';
    }
    case UnitOfMeasure.Ppm: {
      return 'ppm';
    }
    case UnitOfMeasure.PpmBr2: {
      return 'ppm (Br2)';
    }
    case UnitOfMeasure.PpmCl2: {
      return 'ppm (Cl2)';
    }
    case UnitOfMeasure.PpmClc: {
      return 'ppm (Clc)';
    }
    case UnitOfMeasure.PpmClt: {
      return 'ppm (Clt)';
    }
    case UnitOfMeasure.CMim: {
      return 'c/min';
    }
    case UnitOfMeasure.Cc: {
      return 'cc';
    }
    case UnitOfMeasure.Imp: {
      return 'imp';
    }
    case UnitOfMeasure.M3h: {
      return 'm3h';
    }
    case UnitOfMeasure.MA: {
      return 'mA';
    }
    case UnitOfMeasure.MS: {
      return 'mS';
    }
    case UnitOfMeasure.MV: {
      return 'mV';
    }
    case UnitOfMeasure.MicronS: {
      return 'µS';
    }
    case UnitOfMeasure.Min: {
      return 'min';
    }
    case UnitOfMeasure.Ml: {
      return 'ml';
    }
    case UnitOfMeasure.Perc: {
      return '%';
    }
    case UnitOfMeasure.Sec: {
      return 'sec';
    }
    default:
      return uom;
  }
};

export const fromParsedToUom = (uomParsed: string): UnitOfMeasure => {
  switch (uomParsed) {
    case '°C': {
      return UnitOfMeasure.C;
    }
    case '°F': {
      return UnitOfMeasure.F;
    }
    case 'pH': {
      return UnitOfMeasure.Ph;
    }
    case 'ppm': {
      return UnitOfMeasure.Ppm;
    }
    case 'ppm (Br2)': {
      return UnitOfMeasure.PpmBr2;
    }
    case 'ppm (Cl2)': {
      return UnitOfMeasure.PpmCl2;
    }
    case 'ppm (Clc)': {
      return UnitOfMeasure.PpmClc;
    }
    case 'ppm (Clt)': {
      return UnitOfMeasure.PpmClt;
    }
    case 'c/min': {
      return UnitOfMeasure.CMim;
    }
    case 'cc': {
      return UnitOfMeasure.Cc;
    }
    case 'imp': {
      return UnitOfMeasure.Imp;
    }
    case 'm3h': {
      return UnitOfMeasure.M3h;
    }
    case 'mA': {
      return UnitOfMeasure.MA;
    }
    case 'mS': {
      return UnitOfMeasure.MS;
    }
    case 'mV': {
      return UnitOfMeasure.MV;
    }
    case 'µS': {
      return UnitOfMeasure.MicronS;
    }
    case 'min': {
      return UnitOfMeasure.Min;
    }
    case 'ml': {
      return UnitOfMeasure.Ml;
    }
    case '%': {
      return UnitOfMeasure.Perc;
    }
    case 'sec': {
      return UnitOfMeasure.Sec;
    }
    default:
      return uomParsed as UnitOfMeasure;
  }
};

export const parseMachineType = (mt: string): string => {
  switch (mt) {
    case MachineType.Cycleau: {
      return 'CYCL’EAU';
    }
    case MachineType.Mc14Evo: {
      return 'MC14 EVO';
    }
    case MachineType.Mco14Evo: {
      return 'MCO14 EVO';
    }
    case MachineType.NovaNsp: {
      return 'NovaNSP';
    }
    case MachineType.Ef315: {
      return 'EF315';
    }
    case MachineType.Evolution: {
      return 'Evolution';
    }
    case MachineType.Ew2Pool: {
      return 'EW² Pool';
    }
    case MachineType.Nova2Pool: {
      return 'Nova² Pool';
    }
    case MachineType.Unknown: {
      return capitalize(ls.unknown);
    }
    default:
      return mt;
  }
};

export const fromParsedToMachineType = (mtParsed: string): MachineType => {
  switch (mtParsed) {
    case 'CYCL’EAU': {
      return MachineType.Cycleau;
    }
    case 'MC14 EVO': {
      return MachineType.Mc14Evo;
    }
    case 'MCO14 EVO': {
      return MachineType.Mco14Evo;
    }
    case 'NovaNSP': {
      return MachineType.NovaNsp;
    }
    case 'EF315': {
      return MachineType.Ef315;
    }
    case 'Evolution': {
      return MachineType.Evolution;
    }
    case 'EW² Pool': {
      return MachineType.Ew2Pool;
    }
    case 'Nova² Pool': {
      return MachineType.Nova2Pool;
    }
    case capitalize(ls.unknown): {
      return MachineType.Unknown;
    }
    default:
      return mtParsed as MachineType;
  }
};

export const parseMachineVersion = (mv: string): string => {
  switch (mv) {
    case MachineVersion.First: {
      return 'First';
    }
    case MachineVersion.Pro: {
      return 'Pro';
    }
    case MachineVersion.Mc14evoPHRxCle12Tc: {
      return 'pH/RX/CLE12 + T°C';
    }
    case MachineVersion.Mc14evoPHRxCacTc: {
      return 'pH/RX/CAC + T°C';
    }
    case MachineVersion.Mc14evo_4CanaliTc: {
      return `4 ${ls.channels} + T°C`;
    }
    case MachineVersion.Mco14_3SezCl2: {
      return `3 ${ls.sectionMin} (Cl2)`;
    }
    case MachineVersion.Mco14_5SezCl2ClTClC: {
      return `5 ${ls.sectionMin} (Cl2 + ClT + ClC)`;
    }
    case MachineVersion.Nsp161CMin: {
      return 'NSP161 c/min';
    }
    case MachineVersion.Nsp161Ma: {
      return 'NSP161 mA';
    }
    case MachineVersion.Nsp162PHRx: {
      return 'NSP162 pH/RX';
    }
    case MachineVersion.Nsp163ACle11_12: {
      return 'NSP163A CLE11/12';
    }
    case MachineVersion.Nsp163BCle16: {
      return 'NSP163B CLE16';
    }
    case MachineVersion.PHRxTc: {
      return 'pH/RX/T°C';
    }
    case MachineVersion.PHClTc: {
      return 'pH/CL/T°C';
    }
    case MachineVersion.PHCacTc: {
      return 'pH/CAC/T°C';
    }
    case MachineVersion.Ew2Pool: {
      return 'EW² Pool';
    }
    case MachineVersion.PHOrpTc: {
      return 'pH/ORP/T°C';
    }
    case MachineVersion.PHCleTc: {
      return 'pH/CLE/T°C';
    }
    case MachineVersion.Unknown: {
      return capitalize(ls.unknown);
    }
    default:
      return mv;
  }
};

export const fromParsedToMachineVersion = (mvParsed: string): MachineVersion => {
  switch (mvParsed) {
    case 'First': {
      return MachineVersion.First;
    }
    case 'Pro': {
      return MachineVersion.Pro;
    }
    case 'pH/RX/CLE12 + T°C': {
      return MachineVersion.Mc14evoPHRxCle12Tc;
    }
    case 'pH/RX/CAC + T°C': {
      return MachineVersion.Mc14evoPHRxCacTc;
    }
    case `4 ${ls.channels} + T°C`: {
      return MachineVersion.Mc14evo_4CanaliTc;
    }
    case `3 ${ls.sectionMin} (Cl2)`: {
      return MachineVersion.Mco14_3SezCl2;
    }
    case `5 ${ls.sectionMin} (Cl2 + ClT + ClC)`: {
      return MachineVersion.Mco14_5SezCl2ClTClC;
    }
    case 'NSP161 c/min': {
      return MachineVersion.Nsp161CMin;
    }
    case 'NSP161 mA': {
      return MachineVersion.Nsp161Ma;
    }
    case 'NSP162 pH/RX': {
      return MachineVersion.Nsp162PHRx;
    }
    case 'NSP163A CLE11/12': {
      return MachineVersion.Nsp163ACle11_12;
    }
    case 'NSP163B CLE16': {
      return MachineVersion.Nsp163BCle16;
    }
    case 'pH/RX/T°C': {
      return MachineVersion.PHRxTc;
    }
    case 'pH/CL/T°C': {
      return MachineVersion.PHClTc;
    }
    case 'pH/CAC/T°C': {
      return MachineVersion.PHCacTc;
    }
    case 'EW² Pool': {
      return MachineVersion.Ew2Pool;
    }
    case 'pH/ORP/T°C': {
      return MachineVersion.PHOrpTc;
    }
    case 'pH/CLE/T°C': {
      return MachineVersion.PHCleTc;
    }
    case capitalize(ls.unknown): {
      return MachineVersion.Unknown;
    }
    default:
      return mvParsed as MachineVersion;
  }
};

export const isDeviceInAlarm = (device: Device) => {
  try {
    if (!device || !device.modules || device.modules.length === 0) {
      return false;
    }
    for (let i = 0; i < device.modules.length; i++) {
      const deviceModule = device.modules[i];
      const deviceAlarms = deviceModule?.Alarm?.alarms as AlarmsType;
      if (!deviceAlarms) {
        continue;
      }
      const deviceAlarmsKeys = Object.keys(deviceAlarms);
      for (let j = 0; j < deviceAlarmsKeys.length; j++) {
        const deviceAlarmsKey = deviceAlarmsKeys[j];
        const skip = skipAlarmKey(deviceAlarmsKey, deviceModule);
        if (deviceModule) {
          if (
            deviceModule.type === MachineType.Ew2Pool ||
            deviceModule.type === MachineType.Nova2Pool
          ) {
            if (deviceAlarmsKey === 'onOffKey') {
              continue;
            }
          }
        }
        if (
          !skip &&
          // @ts-ignore
          deviceAlarms[deviceAlarmsKey]
        ) {
          return true;
        }
      }
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const isModuleInAlarm = (module: Module) => {
  try {
    if (!module) {
      return false;
    }
    const deviceAlarms = module.Alarm?.alarms as AlarmsType;
    if (!deviceAlarms) {
      return false;
    }
    const deviceAlarmsKeys = Object.keys(deviceAlarms);
    for (let j = 0; j < deviceAlarmsKeys.length; j++) {
      const deviceAlarmsKey = deviceAlarmsKeys[j];
      const skip = skipAlarmKey(deviceAlarmsKey, module);
      if (module.type === MachineType.Ew2Pool || module.type === MachineType.Nova2Pool) {
        if (deviceAlarmsKey === 'onOffKey') {
          continue;
        }
      }
      if (
        !skip &&
        // @ts-ignore
        deviceAlarms[deviceAlarmsKey]
      ) {
        return true;
      }
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const uniqueArr = <T>(arr: T[]): T[] =>
  arr.filter((el, i, array) => array.indexOf(el) === i);

export const uploadLogo = (
  companyId: string,
  file: File,
): Promise<{ result: boolean; err?: string }> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { getSignedStorageLogoUrl } = await sdkClient().getSignedStorageLogoUrl({
        input: { companyId },
      });
      if (getSignedStorageLogoUrl) {
        let fileContent: string | ArrayBuffer | undefined | null = null;
        let reader = new FileReader();
        reader.onload = (e) => {
          if (!(e?.target?.result as string)?.includes('data:image/png')) {
            return resolve({ result: false, err: capitalize(ls.invalidFileType) });
          }
          if (
            // @ts-ignore
            e?.target?.result?.length >
            1024 * 1024 * 5
          ) {
            return resolve({ result: false, err: capitalize(ls.invalidFileSize) });
          }
          fileContent = e?.target?.result;
        };
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
          try {
            let binary = atob((fileContent as any as string).split(',')[1]);
            let array = [];
            for (var i = 0; i < binary.length; i++) {
              array.push(binary.charCodeAt(i));
            }
            let blobData = new Blob([new Uint8Array(array)], { type: 'image/png' });
            const result = await fetch(getSignedStorageLogoUrl, {
              method: 'PUT',
              body: blobData,
              headers: {
                'Content-Type': 'image/png',
              },
            });
            if (!result.ok) {
              logErr('error on uploadLogo', getSignedStorageLogoUrl, result);
              return resolve({ result: false, err: 'upload logo failed' });
            }
            return resolve({ result: true });
          } catch (error: any) {
            logErr('error on uploadLogo', error);
            return resolve({ result: false, err: error?.message || 'upload logo failed' });
          }
        };
      } else {
        return resolve({ result: false, err: 'signed storage url link generation failed' });
      }
    } catch (error: any) {
      logErr('error on uploadLogo', error);
      return resolve({ result: false, err: error?.message || 'upload logo failed' });
    }
  });
};

export const calculateGaugeArcsLength = (
  params: Partial<{
    min: number;
    minAcc: number;
    max: number;
    maxAcc: number;
    withWarn: boolean;
    minWarn: number;
    maxWarn: number;
  }>,
): number[] => {
  let output = [0.3, 0.3, 0.3];
  if (params.withWarn) {
    output = [0.2, 0.2, 0.2, 0.2, 0.2];
    if (
      !params ||
      isNullOrUndefined(params.max) ||
      isNullOrUndefined(params.min) ||
      isNullOrUndefined(params.maxAcc) ||
      isNullOrUndefined(params.minAcc) ||
      isNullOrUndefined(params.minWarn) ||
      isNullOrUndefined(params.maxWarn)
    ) {
      return output;
    }
    const min = params.min!;
    const max = params.max!;
    const minAcc = params.minAcc!;
    const maxAcc = params.maxAcc!;
    const minWarn = params.minWarn!;
    const maxWarn = params.maxWarn!;
    const offset = 100;
    const getParsedValue = (val: number): number => {
      if (val < min) {
        return 0;
      }
      if (val > max) {
        return offset;
      }
      const res = Number(((val - min) / ((max - min) / offset)).toFixed(0));
      return res;
    };
    const parsedMin = getParsedValue(min);
    const parsedMax = getParsedValue(max);
    const parsedMinAcc = getParsedValue(minAcc);
    const parsedMaxAcc = getParsedValue(maxAcc);
    const parsedMinWarn = getParsedValue(minWarn);
    const parsedMaxWarn = getParsedValue(maxWarn);
    // output = [
    //   parsedMinWarn / 100,
    //   (parsedMinAcc - parsedMinWarn) / 100,
    //   (parsedMaxAcc - parsedMinAcc) / 100,
    //   (parsedMaxWarn - parsedMaxAcc) / 100,
    //   (parsedMax - parsedMaxWarn) / 100,
    // ];
    output = [
      parsedMinAcc / 100,
      (parsedMinWarn - parsedMinAcc) / 100,
      (parsedMaxWarn - parsedMinWarn) / 100,
      (parsedMaxAcc - parsedMaxWarn) / 100,
      (parsedMax - parsedMaxAcc) / 100,
    ];
  } else {
    if (
      !params ||
      isNullOrUndefined(params.max) ||
      isNullOrUndefined(params.min) ||
      isNullOrUndefined(params.maxAcc) ||
      isNullOrUndefined(params.minAcc)
    ) {
      return output;
    }
    const min = params.min!;
    const max = params.max!;
    const minAcc = params.minAcc!;
    const maxAcc = params.maxAcc!;
    const offset = 100;
    const getParsedValue = (val: number): number => {
      if (val < min) {
        return 0;
      }
      if (val > max) {
        return offset;
      }
      const res = Number(((val - min) / ((max - min) / offset)).toFixed(0));
      return res;
    };
    const parsedMin = getParsedValue(min);
    const parsedMax = getParsedValue(max);
    const parsedMinAcc = getParsedValue(minAcc);
    const parsedMaxAcc = getParsedValue(maxAcc);
    output = [
      parsedMinAcc / 100,
      (parsedMaxAcc - parsedMinAcc) / 100,
      (parsedMax - parsedMaxAcc) / 100,
    ];
  }
  return output;
};

export const updateThemeMetaAndManifest = (
  params?: Partial<{ companyLogoUrl: string; documentTitle: string }>,
) => {
  let themeName =
    getCompanyDomaninFromUrl().toLowerCase() || (config.env.isProd ? 'steiel' : 'steiel-staging');
  if (isHTHCompany()) {
    themeName = 'hth';
  }
  globalState.companyLogoUrl = params?.companyLogoUrl
    ? params.companyLogoUrl + `?dt=${Date.now()}`
    : undefined;
  document.title = params?.documentTitle || (config.env.isProd ? 'Steiel' : 'Steiel staging');
  if (themeName === 'steiel' || themeName === 'steiel-staging') {
    // it is the default
    return;
  }
  switch (themeName) {
    case 'hth': {
      globalState.companyLogoUrl = params?.companyLogoUrl || `/images/logo${themeName}.png`;
      document.title = params?.documentTitle || (config.env.isProd ? 'hth' : 'hth staging');
      let link = document.createElement('link');
      link.rel = 'apple-touch-icon';
      link.href = document.location.origin + `/logo${themeName}192.png`;
      document.head.appendChild(link);
      link = document.createElement('link');
      link.rel = 'manifest';
      link.href = document.location.origin + `/manifest${themeName}.json`;
      document.head.appendChild(link);
      const faviconUrl = document.location.origin + '/favicon' + themeName + '.ico';
      updateFavicon(faviconUrl);
      return;
    }
    default: {
      return;
    }
  }
};

export const updateFavicon = (faviconUrl: string) => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    // @ts-ignore
    link.rel = 'icon';
    document.head.appendChild(link);
  }
  // @ts-ignore
  link.href = faviconUrl;
};

type GetChartParamsValuesOutputType = {
  setpoint: number;
  min: number;
  max: number;
  hideSetpoint: boolean;
  maxAcceptableLimit: number;
  minAcceptableLimit: number;
  maxWarningLimit: number;
  minWarningLimit: number;
};
export const getChartParamsValues = (module: Module): GetChartParamsValuesOutputType[] => {
  const moduleSettings = module.ModuleSettings?.settings as ModuleSettingsType;
  const moduleParameters = module.Parameter?.parameters as ParametersType;
  if (!module || !module.Parameter || !moduleParameters || !moduleSettings) {
    return [];
  }
  const output: GetChartParamsValuesOutputType[] = [];
  const globalModuleSettings = getGlobalModuleSetting(module);
  for (let i = 0; i < moduleSettings.length; i++) {
    const moduleSetting = moduleSettings[i];
    if (!moduleSetting?.enabled) {
      continue;
    }
    const dataItem: GetChartParamsValuesOutputType = {
      setpoint: moduleSetting.setpoint ?? 0,
      min: moduleSetting.scaleStart ?? globalModuleSettings?.defaultValues?.[i].scaleStart ?? 0,
      max: moduleSetting.scaleEnd ?? globalModuleSettings?.defaultValues?.[i].scaleEnd ?? 0,
      hideSetpoint: true,
      minAcceptableLimit:
        moduleSetting.minAcceptableLimit ??
        globalModuleSettings?.defaultValues?.[i].scaleStart ??
        0,
      maxAcceptableLimit:
        moduleSetting.maxAcceptableLimit ?? globalModuleSettings?.defaultValues?.[i].scaleEnd ?? 0,
      minWarningLimit:
        moduleSetting.minWarningLimit ?? globalModuleSettings?.defaultValues?.[i].scaleStart ?? 0,
      maxWarningLimit:
        moduleSetting.maxWarningLimit ?? globalModuleSettings?.defaultValues?.[i].scaleEnd ?? 0,
    };
    switch (module.type) {
      case MachineType.Cycleau: {
        if (i === 0) {
          dataItem.setpoint = moduleParameters.setpointPH ?? dataItem.setpoint;
          dataItem.minAcceptableLimit = moduleParameters.minPHAlarm ?? dataItem.minAcceptableLimit;
          dataItem.maxAcceptableLimit = moduleParameters.maxPHAlarm ?? dataItem.maxAcceptableLimit;
          dataItem.hideSetpoint = false;
        }
        if (i === 1) {
          dataItem.setpoint = moduleParameters.setpointCl2 ?? dataItem.setpoint;
          dataItem.minAcceptableLimit =
            moduleParameters.minFreeChlorine ?? dataItem.minAcceptableLimit;
          dataItem.maxAcceptableLimit =
            moduleParameters.maxFreeChlorine ?? dataItem.maxAcceptableLimit;
          dataItem.hideSetpoint = false;
        }
        if (module.modelVersion === MachineVersion.Pro) {
          if (i === 4) {
            // dataItem.max = moduleParameters.maxCombChlorine ?? dataItem.max;
          }
        }
        break;
      }
      case MachineType.Mc14Evo:
      case MachineType.Mco14Evo: {
        if (i === 0) {
          dataItem.setpoint = moduleParameters.P03 ?? dataItem.setpoint;
          dataItem.hideSetpoint = false;
        }
        if (i === 1) {
          dataItem.setpoint = moduleParameters.P11 ?? dataItem.setpoint;
          dataItem.hideSetpoint = false;
        }
        if (i === 2) {
          dataItem.setpoint = moduleParameters.P27 ?? dataItem.setpoint;
          dataItem.hideSetpoint = false;
        }
        if (i === 3) {
          dataItem.setpoint = moduleParameters.P35 ?? dataItem.setpoint;
          dataItem.hideSetpoint = false;
        }
        break;
      }
      case MachineType.NovaNsp: {
        if (
          module.modelVersion !== MachineVersion.Nsp161CMin &&
          module.modelVersion !== MachineVersion.Nsp161Ma &&
          i === 0
        ) {
          dataItem.setpoint = moduleParameters.P11 ?? dataItem.setpoint;
          dataItem.hideSetpoint = false;
        }
        break;
      }
      case MachineType.Ef315: {
        if (i === 0) {
          dataItem.setpoint = moduleParameters.P03 ?? dataItem.setpoint;
          dataItem.hideSetpoint = false;
        }
        if (i === 1) {
          dataItem.setpoint = moduleParameters.P10 ?? dataItem.setpoint;
          dataItem.hideSetpoint = false;
        }
        break;
      }
      case MachineType.Evolution: {
        if (i === 0) {
          dataItem.setpoint = moduleParameters.setpointPH ?? dataItem.setpoint;
          dataItem.hideSetpoint = false;
        }
        if (i === 1) {
          dataItem.setpoint = moduleParameters.setpointCl2 ?? dataItem.setpoint;
          dataItem.hideSetpoint = false;
        }
        break;
      }
      case MachineType.Ew2Pool:
      case MachineType.Nova2Pool: {
        if (i === 0) {
          if (moduleParameters.checkSetpointM1) {
            if (moduleParameters.checkSetpointM1 === 1) {
              dataItem.setpoint = moduleParameters.setpointM1OnOff ?? dataItem.setpoint;
              dataItem.hideSetpoint = false;
            }
            if (moduleParameters.checkSetpointM1 === 2) {
              dataItem.setpoint = moduleParameters.setpointM1Pwm ?? dataItem.setpoint;
              dataItem.hideSetpoint = false;
            }
            if (moduleParameters.checkSetpointM1 === 3) {
              dataItem.setpoint = moduleParameters.setpointM1Pfm ?? dataItem.setpoint;
              dataItem.hideSetpoint = false;
            }
          }
          dataItem.minAcceptableLimit = moduleParameters.minLimitM1 ?? dataItem.minAcceptableLimit;
          dataItem.maxAcceptableLimit = moduleParameters.maxLimitM1 ?? dataItem.maxAcceptableLimit;
        }
        if (i === 1) {
          if (moduleParameters.checkSetpointM2) {
            if (moduleParameters.checkSetpointM2 === 1) {
              dataItem.setpoint = moduleParameters.setpointM2OnOff ?? dataItem.setpoint;
              dataItem.hideSetpoint = false;
            }
            if (moduleParameters.checkSetpointM2 === 2) {
              dataItem.setpoint = moduleParameters.setpointM2Pwm ?? dataItem.setpoint;
              dataItem.hideSetpoint = false;
            }
            if (moduleParameters.checkSetpointM2 === 3) {
              dataItem.setpoint = moduleParameters.setpointM2Pfm ?? dataItem.setpoint;
              dataItem.hideSetpoint = false;
            }
          }
          dataItem.minAcceptableLimit = moduleParameters.minLimitM2 ?? dataItem.minAcceptableLimit;
          dataItem.maxAcceptableLimit = moduleParameters.maxLimitM2 ?? dataItem.maxAcceptableLimit;
        }
        break;
      }
      default: {
        break;
      }
    }
    output.push(dataItem);
  }
  return output;
};

export const isModuleInCommErr = (module?: Module | null) => {
  if (config.env.isDev) {
    return false;
  }
  // STEIEL-104
  if (module?.type === MachineType.NovaNsp) {
    return false;
  }
  return dayjs().diff(module?.lastConnection || dayjs(), 'seconds') > 60 * 15;
};

export const getEventType = (eventName: string): 'alarm' | 'event' | 'warn' => {
  switch (eventName) {
    case 'stop':
    case 'onOffKey':
    case 'startDelay':
    case 'ignitionDelay':
    case 'inputCadOff':
    case 'pHStabilizer':
    case 'clock':
    case 'off':
    case 'richAssist':
    case 'superchlorination':
    case 'maintenance':
    case 'timer':
    case 'sampleWater':
    case 'maintenancePump1':
    case 'maintenancePump2':
    case 'recalibrateChlorineProbeAfter24h':
    case 'halvedSetpointForBadCalib':
    case 'externalConsent':
    case 'ecoMode': {
      return 'event';
    }
    case 'phMax':
    case 'cl2Min':
    case 'cl2Max':
    case 'pHMin': {
      return 'warn';
    }
    default: {
      return 'alarm';
    }
  }
};

type AlarmMetadata = {
  translatedName: string;
  disabledColor: string;
  activeColor: string;
  eventColor: string;
  disabledTranslatedLabel: string;
  activeTranslatedLabel: string;
  orderNum: number;
};

export const getAlarmMetadata = (
  alarmKey: string,
  machineType: MachineType,
  machineVersion?: MachineVersion,
): AlarmMetadata | null => {
  const defaultVal: AlarmMetadata = {
    disabledColor: colors.grey,
    disabledTranslatedLabel: '-',
    activeTranslatedLabel: ls.active.toUpperCase(),
    activeColor: colors.red,
    eventColor: colors.red,
    orderNum: 99,
    translatedName: capitalize(ls.getString('alarm_' + alarmKey)),
  };
  switch (machineType) {
    case MachineType.Cycleau: {
      switch (alarmKey) {
        case 'onOffKey': {
          return {
            ...defaultVal,
            orderNum: 1,
            translatedName: capitalize(ls.alarm_new_stop),
            disabledTranslatedLabel: ls.off.toUpperCase(),
            eventColor: colors.blue,
            activeColor: colors.green,
            activeTranslatedLabel: ls.on.toUpperCase(),
          };
        }
        case 'startDelay': {
          return {
            ...defaultVal,
            orderNum: 2,
            translatedName: capitalize(ls.alarm_ignitionDelay),
            disabledTranslatedLabel: ls.deactivated.toUpperCase(),
            activeColor: colors.green,
            eventColor: colors.blue,
          };
        }
        case 'inputCadOff': {
          return {
            ...defaultVal,
            orderNum: 3,
            translatedName: capitalize(ls.alarm_new_inputCadOff),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.off.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.on.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'flow': {
          return {
            ...defaultVal,
            orderNum: 4,
            translatedName: capitalize(ls.alarm_new_flow),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'urOr': {
          return {
            ...defaultVal,
            orderNum: 5,
            translatedName: capitalize(ls.alarm_new_urOr),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'lowPHLvl': {
          return {
            ...defaultVal,
            orderNum: 6,
            translatedName: capitalize(ls.alarm_new_lowPHLvl),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'lowCl2Lvl': {
          return {
            ...defaultVal,
            orderNum: 7,
            translatedName: capitalize(ls.alarm_new_lowCl2Lvl),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'maxPHDosage': {
          return {
            ...defaultVal,
            orderNum: 8,
            translatedName: capitalize(ls.alarm_new_maxPHDosage),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'maxCl2Dosage': {
          return {
            ...defaultVal,
            orderNum: 9,
            translatedName: capitalize(ls.alarm_new_maxCl2Dosage),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'pHMin': {
          return {
            ...defaultVal,
            orderNum: 10,
            translatedName: capitalize(ls.alarm_new_pHMin),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.orange,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.orange,
          };
        }
        case 'phMax': {
          return {
            ...defaultVal,
            orderNum: 11,
            translatedName: capitalize(ls.alarm_new_phMax),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.orange,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.orange,
          };
        }
        case 'cl2Min': {
          return {
            ...defaultVal,
            orderNum: 12,
            translatedName: capitalize(ls.alarm_new_cl2Min),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.orange,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.orange,
          };
        }
        case 'cl2Max': {
          return {
            ...defaultVal,
            orderNum: 13,
            translatedName: capitalize(ls.alarm_new_cl2Max),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.orange,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.orange,
          };
        }
        default: {
          return null;
        }
      }
    }
    case MachineType.Mc14Evo:
    case MachineType.Mco14Evo: {
      switch (alarmKey) {
        case 'ignitionDelay': {
          return {
            ...defaultVal,
            orderNum: 1,
            // translatedName: capitalize(ls.alarm_new_ignitionDelay),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.deactivated.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'pHStabilizer': {
          return {
            ...defaultVal,
            orderNum: 2,
            // translatedName: capitalize(ls.alarm_new_pHStabilizer),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.deactivated.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'clock': {
          return {
            ...defaultVal,
            orderNum: 3,
            translatedName: capitalize(ls.alarm_new_clock),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.deactivated.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'off': {
          return {
            ...defaultVal,
            orderNum: 4,
            translatedName:
              machineType === MachineType.Mco14Evo
                ? capitalize(ls.alarm_extConsensus)
                : capitalize(ls.alarm_off),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.off.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.on.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'richAssist': {
          return {
            ...defaultVal,
            orderNum: 5,
            // translatedName: capitalize(ls.alarm_new_richAssist),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.off.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.on.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'superchlorination': {
          return {
            ...defaultVal,
            orderNum: 6,
            // translatedName: capitalize(ls.alarm_new_superchlorination),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.off.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.on.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'maxTimeK1': {
          return {
            ...defaultVal,
            orderNum: 7,
            translatedName: capitalize(ls.alarm_new_maxTimeK1),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'maxTimeK2': {
          return {
            ...defaultVal,
            orderNum: 8,
            translatedName: capitalize(ls.alarm_new_maxTimeK2),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'maxTimeK4': {
          return {
            ...defaultVal,
            orderNum: 9,
            translatedName: capitalize(ls.alarm_new_maxTimeK4),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'maxTimeK5': {
          return {
            ...defaultVal,
            orderNum: 10,
            translatedName: capitalize(ls.alarm_new_maxTimeK5),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'redox': {
          return {
            ...defaultVal,
            orderNum: 11,
            translatedName: capitalize(ls.alarm_new_redox),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'zeroChlorine': {
          return {
            ...defaultVal,
            orderNum: 12,
            translatedName: capitalize(ls.alarm_new_zeroChlorine),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'lvl1MinAlarm': {
          return {
            ...defaultVal,
            orderNum: 13,
            translatedName: capitalize(ls.alarm_lvl1MinAlarm),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'lvl2MinAlarm': {
          return {
            ...defaultVal,
            orderNum: 14,
            translatedName: capitalize(ls.alarm_lvl2MinAlarm),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        // STEIEL-115 no lvl3 alarm on mc14
        // case 'lvl3MinAlarm': {
        //   return {
        //     ...defaultVal,
        //     orderNum: 15,
        //     translatedName: capitalize(ls.alarm_lvl3MinAlarm),
        //     disabledColor: colors.grey,
        //     disabledTranslatedLabel: '-',
        //     activeColor: colors.red,
        //     activeTranslatedLabel: ls.active.toUpperCase(),
        //     eventColor: colors.red,
        //   };
        // }
        case 'flow': {
          return {
            ...defaultVal,
            orderNum: 16,
            translatedName: capitalize(ls.alarm_new_flow),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'toolBlock': {
          return {
            ...defaultVal,
            orderNum: 17,
            // translatedName: capitalize(ls.alarm_new_toolBlock),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'urOr': {
          return {
            ...defaultVal,
            orderNum: 18,
            translatedName: capitalize(ls.alarm_new_urOr),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        default: {
          return null;
        }
      }
    }
    case MachineType.NovaNsp: {
      switch (alarmKey) {
        case 'ignitionDelay': {
          return {
            ...defaultVal,
            orderNum: 1,
            // translatedName: capitalize(ls.alarm_new_ignitionDelay),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.deactivated.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'stop': {
          return {
            ...defaultVal,
            orderNum: 2,
            translatedName: capitalize(ls.alarm_new_stop),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.off.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.on.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'maintenance': {
          return {
            ...defaultVal,
            orderNum: 3,
            translatedName: capitalize(ls.alarm_new_maintenance),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.deactivated.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'timer': {
          return {
            ...defaultVal,
            orderNum: 4,
            translatedName: capitalize(ls.alarm_new_timer),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.deactivated.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'lvl': {
          return {
            ...defaultVal,
            orderNum:
              machineVersion === MachineVersion.Nsp161CMin ||
              machineVersion === MachineVersion.Nsp161Ma
                ? 5
                : 6,
            translatedName: capitalize(ls.alarm_new_lvl),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'extcons': {
          return {
            ...defaultVal,
            orderNum:
              machineVersion === MachineVersion.Nsp161CMin ||
              machineVersion === MachineVersion.Nsp161Ma
                ? 6
                : 7,
            translatedName: capitalize(ls.alarm_new_extcons),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'flow': {
          return {
            ...defaultVal,
            orderNum:
              machineVersion === MachineVersion.Nsp161CMin ||
              machineVersion === MachineVersion.Nsp161Ma
                ? 7
                : 8,
            translatedName: capitalize(ls.alarm_new_flow),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'urOr': {
          return {
            ...defaultVal,
            orderNum:
              machineVersion === MachineVersion.Nsp161CMin ||
              machineVersion === MachineVersion.Nsp161Ma
                ? 8
                : 9,
            translatedName: capitalize(ls.alarm_new_urOr),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'internalTemp': {
          return {
            ...defaultVal,
            orderNum:
              machineVersion === MachineVersion.Nsp161CMin ||
              machineVersion === MachineVersion.Nsp161Ma
                ? 9
                : 10,
            translatedName: capitalize(ls.alarm_new_internalTemp),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'clockKO': {
          return {
            ...defaultVal,
            orderNum:
              machineVersion === MachineVersion.Nsp161CMin ||
              machineVersion === MachineVersion.Nsp161Ma
                ? 10
                : 11,
            translatedName: capitalize(ls.alarm_new_clockKO),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'sampleWater': {
          return {
            ...defaultVal,
            orderNum:
              machineVersion === MachineVersion.Nsp161CMin ||
              machineVersion === MachineVersion.Nsp161Ma
                ? 12
                : 5,
            translatedName: capitalize(ls.alarm_new_sampleWater),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.deactivated.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'maxDoseTime': {
          return {
            ...defaultVal,
            orderNum: 13,
            translatedName: capitalize(ls.alarm_new_maxDoseTime),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        default: {
          return null;
        }
      }
    }
    case MachineType.Ef315: {
      switch (alarmKey) {
        case 'ignitionDelay': {
          return {
            ...defaultVal,
            orderNum: 1,
            // translatedName: capitalize(ls.alarm_new_ignitionDelay),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.deactivated.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'stop': {
          return {
            ...defaultVal,
            orderNum: 2,
            translatedName: capitalize(ls.alarm_new_stop),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.off.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.on.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'maintenancePump1': {
          return {
            ...defaultVal,
            orderNum: 3,
            translatedName: capitalize(ls.alarm_new_maintenancePump1),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.deactivated.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'maintenancePump2': {
          return {
            ...defaultVal,
            orderNum: 4,
            translatedName: capitalize(ls.alarm_new_maintenancePump2),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.deactivated.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'recalibrateChlorineProbeAfter24h': {
          return {
            ...defaultVal,
            orderNum: 5,
            // translatedName: capitalize(ls.alarm_new_recalibrateChlorineProbeAfter24h),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.deactivated.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'halvedSetpointForBadCalib': {
          return {
            ...defaultVal,
            orderNum: 6,
            // translatedName: capitalize(ls.alarm_new_halvedSetpointForBadCalib),
            disabledColor: colors.grey,
            disabledTranslatedLabel: ls.deactivated.toUpperCase(),
            activeColor: colors.green,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.blue,
          };
        }
        case 'extcons': {
          return {
            ...defaultVal,
            orderNum: 7,
            translatedName: capitalize(ls.alarm_new_extcons),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'flow': {
          return {
            ...defaultVal,
            orderNum: 8,
            translatedName: capitalize(ls.alarm_new_flow),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'urOrMeasure1': {
          return {
            ...defaultVal,
            orderNum: 9,
            translatedName: capitalize(ls.alarm_new_urOrMeasure1),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'urOrMeasure2': {
          return {
            ...defaultVal,
            orderNum: 10,
            translatedName: capitalize(ls.alarm_new_urOrMeasure2),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'lvl1': {
          return {
            ...defaultVal,
            orderNum: 11,
            translatedName: capitalize(ls.alarm_new_lvl1),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'lvl2': {
          return {
            ...defaultVal,
            orderNum: 12,
            translatedName: capitalize(ls.alarm_new_lvl2),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'maxDosageTimeOut1': {
          return {
            ...defaultVal,
            orderNum: 13,
            translatedName: capitalize(ls.alarm_new_maxDosageTimeOut1),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'maxDosageTimeOut2': {
          return {
            ...defaultVal,
            orderNum: 14,
            translatedName: capitalize(ls.alarm_new_maxDosageTimeOut2),
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        default: {
          return null;
        }
      }
    }
    case MachineType.Ew2Pool:
    case MachineType.Nova2Pool: {
      switch (alarmKey) {
        case 'onOffKey': {
          return {
            ...defaultVal,
            orderNum: 1,
            translatedName: capitalize(ls.toolStatus),
            disabledTranslatedLabel: ls.off.toUpperCase(),
            eventColor: colors.blue,
            activeColor: colors.green,
            activeTranslatedLabel: ls.on.toUpperCase(),
          };
        }
        case 'externalConsent': {
          return {
            ...defaultVal,
            orderNum: 2,
            translatedName: capitalize(ls.externalConsent),
            disabledTranslatedLabel: ls.off.toUpperCase(),
            eventColor: colors.blue,
            activeColor: colors.green,
            activeTranslatedLabel: ls.on.toUpperCase(),
          };
        }
        case 'ecoMode': {
          return {
            ...defaultVal,
            orderNum: 3,
            translatedName: capitalize(ls.ecoMode),
            disabledTranslatedLabel: ls.off.toUpperCase(),
            eventColor: colors.blue,
            activeColor: colors.green,
            activeTranslatedLabel: ls.on.toUpperCase(),
          };
        }
        case 'minThresholdM1': {
          return {
            ...defaultVal,
            orderNum: 4,
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'maxThresholdM1': {
          return {
            ...defaultVal,
            orderNum: 5,
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'lvl1m1': {
          return {
            ...defaultVal,
            orderNum: 6,
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'lvl2m1': {
          return {
            ...defaultVal,
            orderNum: 7,
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'underRangeM1': {
          return {
            ...defaultVal,
            orderNum: 8,
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'overRangeM1': {
          return {
            ...defaultVal,
            orderNum: 9,
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'maxDosageM1': {
          return {
            ...defaultVal,
            orderNum: 10,
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'minThresholdM2': {
          return {
            ...defaultVal,
            orderNum: 11,
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'maxThresholdM2': {
          return {
            ...defaultVal,
            orderNum: 12,
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'lvl1m2': {
          return {
            ...defaultVal,
            orderNum: 13,
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'lvl2m2': {
          return {
            ...defaultVal,
            orderNum: 14,
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'underRangeM2': {
          return {
            ...defaultVal,
            orderNum: 15,
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'overRangeM2': {
          return {
            ...defaultVal,
            orderNum: 16,
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'maxDosageM2': {
          return {
            ...defaultVal,
            orderNum: 17,
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'sampleFlow': {
          return {
            ...defaultVal,
            orderNum: 18,
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        case 'pHPriority': {
          return {
            ...defaultVal,
            orderNum: 19,
            disabledColor: colors.grey,
            disabledTranslatedLabel: '-',
            activeColor: colors.red,
            activeTranslatedLabel: ls.active.toUpperCase(),
            eventColor: colors.red,
          };
        }
        default: {
          return null;
        }
      }
    }
    default: {
      return null;
    }
  }
};

export const skipAlarmKey = (alarmKey: string, module?: Module | null): boolean => {
  try {
    if (!module) {
      return false;
    }
    if (
      (module!.type === MachineType.Cycleau &&
        (alarmKey === 'maxDoseTime' ||
          alarmKey === 'extcons' ||
          alarmKey === 'maxAccPulses' ||
          alarmKey === 'autoadesco' ||
          alarmKey === 'internalTemp' ||
          alarmKey === 'lvl' ||
          alarmKey === 'clockKO' ||
          alarmKey === 'timer' ||
          alarmKey === 'maintenance' ||
          alarmKey === 'sampleWater')) ||
      (module!.type === MachineType.NovaNsp &&
        (module!.modelVersion === MachineVersion.Nsp161CMin ||
          module!.modelVersion === MachineVersion.Nsp161Ma) &&
        (alarmKey === 'maxDoseTime' || alarmKey === 'sampleWater')) ||
      (module!.type === MachineType.Nova2Pool &&
        (alarmKey === 'lvl2m1' || alarmKey === 'lvl2m2')) ||
      alarmKey.endsWith('Value')
    ) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const isHTHCompany = () => {
  const companyDomaninFromUrl = getCompanyDomaninFromUrl();
  const isHTHCompany =
    companyDomaninFromUrl.toLowerCase().startsWith('hth') ||
    companyDomaninFromUrl.toLowerCase().startsWith('mycycleaulink');
  return isHTHCompany;
};

export const setHTHBgBody = (mobile?: boolean) => {
  if (mobile) {
    document.body.style.backgroundImage = `url('${assets.hthMainBgMobile}')`;
  } else {
    document.body.style.backgroundImage = `url('${assets.hthMainBg}')`;
  }
  document.body.style.backgroundSize = 'cover';
  document.body.style.backgroundAttachment = 'fixed';
  document.body.style.backgroundRepeat = 'no-repeat';
};

export const removeHTHBgBody = () => {
  document.body.style.backgroundImage = '';
  document.body.style.backgroundSize = '';
  document.body.style.backgroundAttachment = '';
  document.body.style.backgroundRepeat = '';
};
