import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ls } from '../../i18n/translations';
import { Body2 } from '../ui/Typography';
import { colors } from '../../theme';
import { assets, CustomSvgIcon } from '../../helpers/assets';
import { Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { PageName } from '../../routes';
import Center from '../ui/Center';
import { defaultMUIDataTableOptions } from '../../helpers/datatableUtils';
import { User, UserRole } from '../../graphql/codegen/graphql-react';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { useMQ } from '../../hook/useMQ';
import { capitalize, dayjs } from '../../helpers/utils';
import SteielButton from '../ui/SteielButton';
import { useDarkMode } from '../../hook/useDarkMode';
import { useAuth } from '../../hook/useAuth';

const options: MUIDataTableOptions = {
  ...defaultMUIDataTableOptions,
};
interface UsersTableProps {
  users: User[];
}

const UsersTable: React.FC<UsersTableProps> = ({ users }) => {
  const { isMobile } = useMQ();
  const navigate = useNavigate();
  const { isDarkMode } = useDarkMode();
  const dateTimeFile = dayjs().format('YYYY-MM-DD_HH_mm');
  const auth = useAuth();

  const tableUsers = ([...(users || [])]
    .filter((el) => el.id !== auth.user?.id)
    .map((u, i) => {
      return {
        id: u?.id || `${i}`,
        name: u?.name || '',
        surname: u?.surname || '',
        email: u?.email || '',
        company: u?.Company?.name || '',
        role: capitalize(
          // @ts-ignore
          ls[
            // @ts-ignore
            (u?.roles?.[0] || UserRole.Viewer).toLowerCase()
          ],
        ),
      };
    }) || []) as {
    id: string;
    name: string;
    surname: string;
    email: string;
    company: string;
    role: string;
  }[];

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'surname',
      label: ls.companyName.toUpperCase(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: ls.nameSurname.toUpperCase(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email',
      label: ls.email.toUpperCase(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'role',
      label: ls.role.toUpperCase(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'company',
      label: ls.company.toUpperCase(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'id',
      label: ls.settings.toUpperCase(),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        download: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Center justifyContent={isMobile ? 'flex-start' : 'center'}>
              <Link style={{ textDecoration: 'none' }} to={PageName.editUser.replace('{0}', value)}>
                <SteielButton
                  text={ls.open.toUpperCase()}
                  // size='small'
                  style={{
                    backgroundColor: 'transparent',
                    borderColor: isDarkMode ? colors.lightGrey : colors.blue,
                    height: 28,
                  }}
                  typographyProps={{
                    style: { color: isDarkMode ? colors.lightGrey : colors.blue },
                    size: '0.9rem',
                    mt: 0.2,
                  }}
                />
                {/* <CustomSvgIcon
                  src={assets.settingsBlue}
                  style={{
                    width: 24,
                    height: 24,
                    marginBottom: -8,
                  }}
                  onClick={() => {}}
                /> */}
              </Link>
            </Center>
          );
        },
      },
    },
  ];

  return (
    <MUIDataTable
      title=''
      data={tableUsers}
      columns={columns}
      options={{
        ...options,
        downloadOptions: {
          filename: `Users_${dateTimeFile}.csv`,
        },
        rowsPerPage: localStorage.getItem('users_rowsPerPage')
          ? Number(localStorage.getItem('users_rowsPerPage'))
          : 10,
        onChangeRowsPerPage: (numberOfRows) => {
          localStorage.setItem('users_rowsPerPage', `${numberOfRows}`);
        },
      }}
    />
  );

  // return (
  //   <TableContainer style={{ height: 600 }}>
  //     <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
  //       <TableHead>
  //         <TableRow>
  //           <TableCell>
  //             <Body2 weight={600} color={colors.grey}>
  //               {ls.name.toUpperCase()}
  //             </Body2>
  //           </TableCell>
  //           <TableCell>
  //             <Body2 weight={600} color={colors.grey}>
  //               {ls.surname.toUpperCase()}
  //             </Body2>
  //           </TableCell>
  //           <TableCell>
  //             <Body2 weight={600} color={colors.grey}>
  //               {ls.username.toUpperCase()}
  //             </Body2>
  //           </TableCell>
  //           <TableCell>
  //             <Body2 weight={600} color={colors.grey}>
  //               {ls.email.toUpperCase()}
  //             </Body2>
  //           </TableCell>
  //           <TableCell>
  //             <Body2 weight={600} color={colors.grey}>
  //               {ls.type.toUpperCase()}
  //             </Body2>
  //           </TableCell>
  //           <TableCell align='center'>
  //             <Body2 weight={600} color={colors.grey}>
  //               {ls.settings.toUpperCase()}
  //             </Body2>
  //           </TableCell>
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {rows.map((row, i) => (
  //           <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
  //             <TableCell component='th' scope='row'>
  //               <Body2 color={colors.stroke}>{row.name}</Body2>
  //             </TableCell>
  //             <TableCell>
  //               <Body2 color={colors.stroke}>{row.surname}</Body2>
  //             </TableCell>
  //             <TableCell>
  //               <Body2 color={colors.stroke}>{row.username}</Body2>
  //             </TableCell>
  //             <TableCell>
  //               <Body2 color={colors.stroke}>{row.email}</Body2>
  //             </TableCell>
  //             <TableCell>
  //               <Body2 color={colors.stroke}>{row.role}</Body2>
  //             </TableCell>
  //             <TableCell align='center'>
  //               <Link to={PageName.editUser}>
  //                 <Center>
  //                   <Box mb={-2} width={35}>
  //                     <CustomSvgIcon
  //                       src={assets.settingsBlue}
  //                       style={{ width: 24, height: 24 }}
  //                       onClick={() => {}}
  //                     />
  //                   </Box>
  //                 </Center>
  //               </Link>
  //             </TableCell>
  //           </TableRow>
  //         ))}
  //       </TableBody>
  //     </Table>
  //   </TableContainer>
  // );
};

export default UsersTable;
