import React, { PropsWithChildren } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Box,
  ButtonProps,
  BoxProps,
} from '@mui/material';
import { ls } from '../../i18n/translations';
import { capitalize } from '../../helpers/utils';
import SteielButton, { SteielButtonProps } from './SteielButton';
import { H2 } from './Typography';
import { colors } from '../../theme';
import { useMQ } from '../../hook/useMQ';
import { useDarkMode } from '../../hook/useDarkMode';

interface SteielDialogProps {
  open: boolean;
  onClose: () => void;
  onOkActionClick: () => void;
  onKoActionClick?: () => void;
  title: string;
  content: string;
  okLabel?: string;
  koLabel?: string;
  DialogChildren?: React.FC;
  width?: number;
  okDisabled?: boolean;
  okActionStyle?: SteielButtonProps & ButtonProps & PropsWithChildren;
  koActionStyle?: SteielButtonProps & ButtonProps & PropsWithChildren;
  dialogContentStyle?: BoxProps;
}

const SteielDialog: React.FC<SteielDialogProps> = ({
  open,
  onClose,
  onOkActionClick,
  content,
  title,
  onKoActionClick,
  koLabel,
  okLabel,
  DialogChildren,
  width,
  okDisabled,
  okActionStyle,
  koActionStyle,
  dialogContentStyle,
}) => {
  const { isMobileOrTablet } = useMQ();
  const { isDarkMode } = useDarkMode();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      style={{ zIndex: 10000 }} // fix logo appearance (it is with zIndex 9999)
    >
      <Box px={1} py={1} minWidth={250} width={width} {...dialogContentStyle}>
        <DialogTitle id='alert-dialog-title'>
          <H2 variant='inherit' size='1.4rem' textAlign='center'>
            {title}
          </H2>
        </DialogTitle>
        <DialogContent
          style={{ paddingLeft: isMobileOrTablet ? 0 : 8, paddingRight: isMobileOrTablet ? 0 : 8 }}
        >
          <DialogContentText
            id='alert-dialog-description'
            style={{
              fontSize: '1.1rem',
              color: isDarkMode ? colors.lightGrey : colors.stroke,
              textAlign: 'center',
            }}
          >
            {content}
          </DialogContentText>

          {DialogChildren ? <DialogChildren /> : null}
        </DialogContent>
        <Box
          display='flex'
          justifyContent={onKoActionClick ? 'space-between' : 'flex-end'}
          alignItems='center'
        >
          {onKoActionClick ? (
            <DialogActions>
              <SteielButton
                text={koLabel || capitalize(ls.no)}
                onClick={onKoActionClick}
                typographyProps={{ style: { color: 'white' } }}
                {...koActionStyle}
                style={{
                  backgroundColor: colors.lightGrey,
                  borderColor: colors.lightGrey,
                  ...koActionStyle?.style,
                }}
              />
            </DialogActions>
          ) : null}
          <DialogActions>
            <SteielButton
              text={okLabel || capitalize(ls.yes)}
              size='small'
              disabled={okDisabled}
              onClick={onOkActionClick}
              {...okActionStyle}
            />
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SteielDialog;
