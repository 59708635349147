import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  LinearProgress,
  TextareaAutosize,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { ls } from '../../i18n/translations';
import SteielButton from '../ui/SteielButton';
import ErrorMessage from '../ErrorMessage';
import { capitalize, catchErrors, log, logErr, minMaxCap } from '../../helpers/utils';
import { Body1, Body2, H2 } from '../ui/Typography';
import { useMQ } from '../../hook/useMQ';
import { colors } from '../../theme';
import { useSetState, useWindowSize } from 'react-use';
import Center from '../ui/Center';
import Flex from '../ui/Flex';
import { assets, CustomSvgIcon } from '../../helpers/assets';
import SteielDialog from '../ui/SteielDialog';
import { Module } from '../../graphql/codegen/graphql-react';
import { ModuleSettingType } from '../../graphql/dataTypes';
import SteielCheckbox from '../ui/SteielCheckBox';
import SteielNumericInput from '../ui/SteielNumericInput';
import sdkClient from '../../graphql/sdkClient';
import validator from 'validator';
import clone from 'just-clone';
import { measurementsColors, measurementsColorsDarkMode } from '../DeviceInterface';
import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import { useDarkMode } from '../../hook/useDarkMode';

interface NotificationsFormProps {
  title: string;
  moduleSetting: ModuleSettingType;
  index: number;
  module: Module | null;
  onSettingsSaved?: () => void;
}

const NotificationsForm: React.FC<NotificationsFormProps> = ({
  title,
  moduleSetting,
  index,
  module,
  onSettingsSaved,
}) => {
  const { isDarkMode } = useDarkMode();
  const [errorFromServer, setErrorFromServer] = React.useState('');
  const { isMobile, isMobileOrTablet } = useMQ();
  const [state, setState] = useSetState<{
    open: boolean;
    showRecipientListDialog: boolean;
    notification: boolean;
    notificationDelay: string;
    recipientList: Partial<{
      email: string;
      enabled: boolean;
    }>[];
    customNotificationNotes: string;
    saving: boolean;
    showSuccessSaveDialog: boolean;
    showSaveConfirmDialog: boolean;
  }>({
    open: false,
    showRecipientListDialog: false,
    notification: Boolean(moduleSetting?.notification || false),
    notificationDelay: String(moduleSetting?.notificationDelay || 0),
    recipientList: moduleSetting?.recipientList || [],
    customNotificationNotes: moduleSetting?.customNotificationNotes || '',
    saving: false,
    showSuccessSaveDialog: false,
    showSaveConfirmDialog: false,
  });
  const { width: windowWidth } = useWindowSize();
  const [prevRecipientList, setPrevRecipientList] = React.useState(clone(state.recipientList));
  const emailRecipientEmailRef = React.useRef('');
  const [emailRecipientEmail, setEmailRecipientEmail] = React.useState(
    emailRecipientEmailRef.current,
  );

  if (!module) {
    return null;
  }

  const Separator: React.ReactNode = isMobileOrTablet ? null : (
    <Box className='separator' width='100%' />
  );

  const handleSaveSettings = async () => {
    try {
      log('saving module settings', state);
      setErrorFromServer('');
      setState({ saving: true });
      if (isNaN(parseFloat(state.notificationDelay))) {
        throw new Error(ls.badInputData);
      }
      const res = await sdkClient().updateModuleSettings({
        input: {
          moduleId: module.id || 'n/a',
          settingsIndex: index,
          settings: {
            customNotificationNotes: state.customNotificationNotes,
            notification: state.notification,
            notificationDelay: parseInt(state.notificationDelay),
            recipientList: state.recipientList,
          } as ModuleSettingType,
        },
      });
      if (res.updateModuleSettings?.id !== module.id) {
        throw new Error(ls.sww);
      }
      setState({ showSuccessSaveDialog: true });
      onSettingsSaved?.();
    } catch (error) {
      logErr('error on saving module settings', error);
      catchErrors(error, setErrorFromServer);
    } finally {
      setState({ saving: false });
    }
  };

  return (
    <Box>
      <Box mx={isMobile ? 2 : 0}>
        <Box
          bgcolor={isDarkMode ? colors.darkBackground : 'white'}
          borderRadius={1}
          pt={2}
          sx={{ boxShadow: 1 }}
        >
          <Flex
            style={{ cursor: 'pointer' }}
            pb={1.5}
            onClick={() => {
              setState({ open: !state.open });
            }}
          >
            <Flex>
              <Box ml={4}>
                <Body1 size='1.1rem' weight={600}>
                  {title}
                </Body1>
              </Box>
              {moduleSetting?.unitOfMeasure ? (
                <Box ml={2}>
                  <Center
                    bgcolor={
                      isDarkMode ? measurementsColorsDarkMode[index] : measurementsColors[index]
                    }
                    py={0.7}
                    px={2}
                    mx={0.5}
                    my={0.5}
                    borderRadius={4}
                  >
                    <Body2
                      color='white'
                      size={'0.8rem'}
                      weight={600}
                      mt={0.2}
                      minWidth={30}
                      textAlign='center'
                    >
                      {moduleSetting.unitOfMeasure}
                    </Body2>
                  </Center>
                </Box>
              ) : null}
            </Flex>
            <Box mr={4}>
              <IconButton
                onClick={() => {
                  setState({ open: !state.open });
                }}
                style={{
                  rotate: state.open ? '180deg' : '0deg',
                }}
              >
                <CustomSvgIcon src={assets.interfaceDetail} onClick={() => {}} />
              </IconButton>
            </Box>
          </Flex>
          {state.open ? (
            <Box pb={3}>
              <Flex px={3} py={1}>
                <Box mt={0.5}>
                  <Body2 color={isDarkMode ? colors.lightGrey : colors.blue} weight={600}>
                    {`${capitalize(ls.notifications)}:`}
                  </Body2>
                </Box>
                <Center>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.notification}
                          onChange={(e) => {
                            setState({ notification: e.target.checked });
                          }}
                          sx={{
                            mt: -0.3,
                            color: isDarkMode ? colors.lightGrey : colors.stroke,
                            '&.Mui-checked': {
                              color: colors.green,
                            },
                          }}
                        />
                      }
                      label={
                        <Body2
                          color={isDarkMode ? colors.lightGrey : colors.stroke}
                          weight={600}
                          size='0.9rem'
                        >
                          {ls.enable.toUpperCase()}
                        </Body2>
                      }
                    />
                  </FormGroup>
                </Center>
              </Flex>
              {Separator}
              <Flex
                px={3}
                py={1}
                flexDirection={isMobileOrTablet ? 'column' : 'row'}
                alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
              >
                <Box mt={0.5}>
                  <Body2 color={isDarkMode ? colors.lightGrey : colors.blue} weight={600}>
                    {`${capitalize(ls.notificationsDelay)}:`}
                  </Body2>
                </Box>
                <Box mt={isMobileOrTablet ? 0.5 : 0} width={190}>
                  <SteielNumericInput
                    min={0}
                    max={30}
                    onMinusClick={() => {
                      if (!isNaN(parseFloat(state.notificationDelay))) {
                        setState({
                          notificationDelay: String(
                            Number(
                              minMaxCap(0, 30, parseFloat(state.notificationDelay) - 1).toFixed(0),
                            ),
                          ),
                        });
                      }
                    }}
                    onPlusClick={() => {
                      if (!isNaN(parseFloat(state.notificationDelay))) {
                        setState({
                          notificationDelay: String(
                            Number(
                              minMaxCap(0, 30, parseFloat(state.notificationDelay) + 1).toFixed(0),
                            ),
                          ),
                        });
                      }
                    }}
                    onValueChange={(v) => {
                      setState({ notificationDelay: v });
                    }}
                    value={String(state.notificationDelay)}
                  />
                </Box>
              </Flex>
              {Separator}
              <Flex
                px={3}
                py={1}
                flexDirection={isMobileOrTablet ? 'column' : 'row'}
                alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
              >
                <Box>
                  <Body2 color={isDarkMode ? colors.lightGrey : colors.blue} weight={600}>
                    {`${capitalize(ls.recipientList)}:`}
                  </Body2>
                </Box>
                <Box width={190} py={0.5}>
                  <SteielButton
                    text={ls.view}
                    fullWidth
                    onClick={() => {
                      setState({ showRecipientListDialog: true });
                    }}
                  />
                </Box>
              </Flex>
              {Separator}
              <Flex
                px={3}
                py={1}
                flexDirection={isMobileOrTablet ? 'column' : 'row'}
                alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
              >
                <Box alignSelf='flex-start' mt={0.5}>
                  <Body2 color={isDarkMode ? colors.lightGrey : colors.blue} weight={600}>
                    {`${capitalize(ls.customizeNotification)}:`}
                  </Body2>
                </Box>
                <Box mt={isMobileOrTablet ? 0.5 : 0} width={isMobileOrTablet ? '100%' : 350}>
                  <TextareaAutosize
                    minRows={3}
                    maxRows={10}
                    style={{
                      width: '100%',
                      fontFamily: 'Roboto Condensed',
                      fontWeight: 300,
                      fontSize: '1.1rem',
                      padding: 12,
                      color: isDarkMode ? colors.lightGrey : colors.blue,
                      backgroundColor: isDarkMode ? colors.darkBackground : undefined,
                      borderColor: isDarkMode ? '#5a5c6f' : '#bdbdbd',
                    }}
                    value={state.customNotificationNotes}
                    onChange={(e) => {
                      setState({ customNotificationNotes: e.target.value });
                    }}
                  />
                </Box>
              </Flex>
              {state.saving && (
                <Box my={2}>
                  <LinearProgress />
                </Box>
              )}
              <ErrorMessage
                containerStyle={{
                  my: 2,
                }}
                message={errorFromServer}
              />
              <Flex
                justifyContent={isMobileOrTablet ? 'center' : 'flex-end'}
                mt={2}
                mr={isMobileOrTablet ? undefined : 3}
              >
                <SteielButton
                  text={capitalize(ls.apply)}
                  disabled={state.saving}
                  style={{ backgroundColor: colors.green, borderColor: colors.green, width: 150 }}
                  // onClick={() => {
                  //   setState({ showSaveConfirmDialog: true });
                  // }}
                  onClick={handleSaveSettings}
                />
              </Flex>
            </Box>
          ) : null}
        </Box>
      </Box>
      <SteielDialog
        title={capitalize(ls.attention)}
        content={capitalize(ls.saveChangesQuestion)}
        onClose={() => {
          setState({ showSaveConfirmDialog: false });
        }}
        onOkActionClick={async () => {
          setState({ showSaveConfirmDialog: false });
          await handleSaveSettings();
        }}
        open={state.showSaveConfirmDialog}
      />
      <SteielDialog
        title={capitalize(ls.changesCompleted, false)}
        content={capitalize(ls.settingsSavedSuccessfully)}
        onClose={() => {
          setState({ showSuccessSaveDialog: false });
        }}
        onOkActionClick={() => {
          setState({ showSuccessSaveDialog: false });
        }}
        open={state.showSuccessSaveDialog}
        okLabel={capitalize(ls.ok)}
      />
      {/* <SteielDialog
        content={''}
        onClose={() => {
          setState({
            showRecipientListDialog: false,
            recipientList: clone(prevRecipientList),
          });
        }}
        onOkActionClick={() => {
          setPrevRecipientList(clone(state.recipientList));
          setState({ showRecipientListDialog: false });
        }}
        open={state.showRecipientListDialog}
        title={ls.recipientList}
        onKoActionClick={() => {
          setState({
            showRecipientListDialog: false,
            recipientList: clone(prevRecipientList),
          });
        }}
        okLabel={ls.apply}
        koLabel={ls.cancel}
        width={isMobile ? windowWidth * 0.8 : 500}
        okActionStyle={{ style: { backgroundColor: colors.green, borderColor: colors.green } }}
        DialogChildren={() => <RecipientList />}
      /> */}
      <Dialog
        open={state.showRecipientListDialog}
        onClose={() => {
          setState({
            showRecipientListDialog: false,
            recipientList: clone(prevRecipientList),
          });
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        style={{ zIndex: 10000 }} // fix logo appearance (it is with zIndex 9999)
      >
        <Box px={1} py={1} minWidth={250} width={isMobile ? windowWidth * 0.8 : 500}>
          <DialogTitle id='alert-dialog-title'>
            <H2 variant='inherit' size='1.4rem' textAlign='center'>
              {ls.recipientList}
            </H2>
          </DialogTitle>
          <DialogContent
            style={{
              paddingLeft: isMobileOrTablet ? 0 : 8,
              paddingRight: isMobileOrTablet ? 0 : 8,
            }}
          >
            <Box>
              <Box className='separator' width='100%' />
              <Flex
                mt={2}
                flexDirection={isMobileOrTablet ? 'column' : 'row'}
                alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
              >
                <Box flex={1} width={isMobileOrTablet ? '100%' : undefined}>
                  <TextField
                    placeholder={ls.email}
                    size='small'
                    fullWidth
                    value={emailRecipientEmail}
                    onChange={(e) => {
                      setEmailRecipientEmail(e.target.value);
                      emailRecipientEmailRef.current = e.target.value;
                    }}
                  />
                </Box>
                <Box ml={isMobileOrTablet ? 0 : 2} mt={isMobileOrTablet ? 1 : 0}>
                  <SteielButton
                    text={ls.add}
                    disabled={
                      !validator.isEmail(emailRecipientEmail) ||
                      state.recipientList.some(
                        (el) =>
                          el.email && el.email.toLowerCase() === emailRecipientEmail.toLowerCase(),
                      )
                    }
                    onClick={() => {
                      setState((prevState) => {
                        const newRecipientList = [
                          ...clone(prevState.recipientList),
                          { email: emailRecipientEmail, enabled: true },
                        ];
                        return {
                          ...prevState,
                          recipientList: newRecipientList,
                        };
                      });
                      setEmailRecipientEmail('');
                      emailRecipientEmailRef.current = '';
                    }}
                  />
                </Box>
              </Flex>
              <Box
                mt={1}
                px={isMobileOrTablet ? 1 : 2}
                border='1px solid'
                borderRadius={2}
                borderColor={colors.lightGrey}
                maxHeight={250}
                flex={1}
                sx={{ overflowY: 'auto' }}
                width='100%'
                height='100%'
              >
                {state.recipientList.filter((el) => el && el.email).length === 0 ? (
                  <Center my={3}>
                    <Body2>{capitalize(ls.noDataFound)}</Body2>
                  </Center>
                ) : null}
                {state.recipientList.map((el, i) => {
                  if (!el || !el.email) {
                    return null;
                  }
                  return (
                    <Box key={i}>
                      <Flex py={0.5}>
                        <Box flex={1}>
                          <Body2 color={isDarkMode ? colors.lightGrey : colors.stroke}>
                            {el.email}
                          </Body2>
                        </Box>
                        <Box ml={2}>
                          <SteielCheckbox
                            checked={el.enabled || false}
                            label=''
                            onChangeValue={(v) => {
                              setState((prevState) => {
                                const newRecipientList = clone(prevState.recipientList);
                                newRecipientList[i].enabled = v;
                                return {
                                  ...prevState,
                                  recipientList: newRecipientList,
                                };
                              });
                            }}
                          />
                        </Box>
                        <Box mb={0.2}>
                          <IconButton
                            style={{
                              width: 42,
                              height: 42,
                            }}
                            onClick={() => {
                              setState((prevState) => {
                                const newRecipientList = prevState.recipientList.filter(
                                  (r) => r.email !== el.email,
                                );
                                return {
                                  ...prevState,
                                  recipientList: newRecipientList,
                                };
                              });
                            }}
                            size='small'
                          >
                            <DeleteOutlineIcon fontSize='small' />
                          </IconButton>
                        </Box>
                      </Flex>
                      <Box className='separator' width='100%' />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </DialogContent>
          <Box display='flex' justifyContent={'space-between'} alignItems='center'>
            <DialogActions>
              <SteielButton
                text={ls.cancel}
                onClick={() => {
                  setState({
                    showRecipientListDialog: false,
                    recipientList: clone(prevRecipientList),
                  });
                }}
                typographyProps={{ style: { color: 'white' } }}
                style={{
                  backgroundColor: colors.lightGrey,
                  borderColor: colors.lightGrey,
                }}
              />
            </DialogActions>
            <DialogActions>
              <SteielButton
                text={ls.apply}
                size='small'
                onClick={() => {
                  setPrevRecipientList(clone(state.recipientList));
                  setState({ showRecipientListDialog: false });
                }}
                style={{ backgroundColor: colors.green, borderColor: colors.green }}
              />
            </DialogActions>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default NotificationsForm;
