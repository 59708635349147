import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageName } from '../routes';
import Flex from '../components/ui/Flex';
import { Body1, Body2 } from '../components/ui/Typography';
import { assets, CustomSvgIcon } from '../helpers/assets';
import { ls } from '../i18n/translations';
import { Box, LinearProgress } from '@mui/material';
import { capitalize, dayjs, isDeviceInAlarm, logErr } from '../helpers/utils';
import SteielButton from '../components/ui/SteielButton';
import Center from '../components/ui/Center';
import { useSetState } from 'react-use';
import { useMQ } from '../hook/useMQ';
import Breadcrumbs from '../components/ui/Breadcrumbs';
import { useSearchParams } from '../hook/useSearchParams';
import { useAuth } from '../hook/useAuth';
import { Device } from '../graphql/codegen/graphql-react';
import SteielDialog from './ui/SteielDialog';
import sdkClient from '../graphql/sdkClient';
import { colors } from '../theme';
import DeviceIconStatus from './DeviceIconStatus';
import { OnlyOnTabletAndDesktop } from './ui/Responsive';
import { useDarkMode } from '../hook/useDarkMode';

interface DeviceDetailInfoProps {
  backPageName: string;
  device: Device;
  onResetDone?: () => void;
}

const DeviceDetailInfo: React.FC<DeviceDetailInfoProps> = ({
  backPageName,
  device,
  onResetDone,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useMQ();
  const { isDarkMode } = useDarkMode();
  const [state, setState] = useSetState<{
    showDefaultWarn: boolean;
    errorFromServer: any;
    resettingSettings: boolean;
  }>({
    showDefaultWarn: false,
    errorFromServer: '',
    resettingSettings: false,
  });
  const auth = useAuth();
  const { deviceIdParam } = useSearchParams();

  const isSomeOffline = device.modules?.some((el) => !el?.online);
  const inAlarm = isDeviceInAlarm(device) || isSomeOffline;
  const isOnline = device.modules?.some((el) => el?.online);
  const deviceStatus = isOnline ? (inAlarm ? ls.onlineWithAlarm : ls.online) : ls.offline;
  const handleDefaultDeviceSettings = async () => {
    try {
      setState({ errorFromServer: '', resettingSettings: true });
      const { resetSettingsDevice } = await sdkClient().resetSettingsDevice({
        input: { deviceId: device.id || 'n/a' },
      });
      if (!resetSettingsDevice) {
        throw new Error(capitalize(ls.somethingWentWrong));
      }
      onResetDone?.();
    } catch (error) {
      logErr('error on handleDefaultDeviceSettings', error);
      setState({ errorFromServer: error });
    } finally {
      setState({ resettingSettings: false });
    }
  };

  return (
    <>
      {state.resettingSettings ? (
        <Box my={2}>
          <LinearProgress />
        </Box>
      ) : null}
      {isMobile ? (
        <Box ml={2}>
          <Breadcrumbs
            backPageName={backPageName}
            // comeBackLink={PageName.home}
          />
        </Box>
      ) : null}
      <Box
        mt={isMobile ? 2 : 0}
        bgcolor={isDarkMode ? colors.darkBackground2 : 'white'}
        p={isMobile ? 0 : 2}
        py={isMobile ? 0.5 : undefined}
        borderRadius={1}
        sx={{ boxShadow: isMobile ? 0 : 1 }}
      >
        {!isMobile ? (
          <Breadcrumbs
            backPageName={backPageName}
            // comeBackLink={PageName.home}
          />
        ) : null}
        <Flex
          mt={2}
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'flex-start' : undefined}
        >
          <Flex width={isMobile ? '100%' : '55%'}>
            <Box ml={isMobile ? 2 : 0}>
              <Body1 size='1.1rem' weight={600} minWidth={isMobile ? 50 : undefined}>
                {capitalize(ls.name)}
                {':'}
              </Body1>
            </Box>
            <Box ml={isMobile ? 1 : 2} mr={isMobile ? 2 : 0} width='100%'>
              <Body2 size='1.1rem'>{device.name || '-'}</Body2>
            </Box>
          </Flex>
          <Flex mt={isMobile ? 1 : 0} width={'100%'}>
            <Box ml={isMobile ? 2 : 4}>
              <Body1 size='1.1rem' weight={600} minWidth={isMobile ? 50 : undefined}>
                {capitalize(ls.place)}
                {':'}
              </Body1>
            </Box>
            <Box ml={isMobile ? 1 : 2} mr={2} width='100%'>
              <Body2 size='1.1rem'>{device.place || '-'}</Body2>
            </Box>
          </Flex>
          {!isMobile && !auth.isViewer ? (
            <Box flex={1} ml={2}>
              <Flex justifyContent={'flex-end'}>
                {/* <Box mt={2}>
                  <CustomSvgIcon
                    src={assets.infoOutline}
                    style={{ width: 24, height: 24 }}
                    onClick={() => {}}
                  />
                </Box> */}
                <Box ml={1}>
                  <SteielButton
                    sx={{ minWidth: 150 }}
                    onClick={() => {
                      // navigate(PageName.deviceSetup.replace('{0}', deviceIdParam));
                      navigate(PageName.editDevice.replace('{0}', deviceIdParam));
                    }}
                  >
                    <Center>
                      <Box mb={-1.9}>
                        <CustomSvgIcon
                          src={assets.setting}
                          style={{ width: 24, height: 24 }}
                          onClick={() => {}}
                        />
                      </Box>
                      <Box ml={1}>
                        <Body1 color='white'>{ls.setup}</Body1>
                      </Box>
                    </Center>
                  </SteielButton>
                </Box>
              </Flex>
            </Box>
          ) : null}
        </Flex>
        <Box mt={1}>
          <Flex mt={isMobile ? 2 : 0} ml={isMobile ? 2 : 0}>
            <Box>
              <Body1 size='1.1rem' weight={600}>
                {ls.id.toUpperCase()}
                {':'}
              </Body1>
            </Box>
            <Box flex={1} ml={1}>
              <Body1 size='1.1rem' weight={400}>
                {deviceIdParam}
              </Body1>
            </Box>
            <OnlyOnTabletAndDesktop>
              <Box flex={1} ml={1}>
                <DeviceIconStatus deviceStatus={deviceStatus} withDesc alwaysShowDesc />
              </Box>
            </OnlyOnTabletAndDesktop>
            {!isMobile && !auth.isViewer ? (
              <Box flex={1}>
                <Flex justifyContent={'flex-end'}>
                  {/* <Box mt={2}>
                    <CustomSvgIcon
                      src={assets.infoOutline}
                      style={{ width: 24, height: 24 }}
                      onClick={() => {}}
                    />
                  </Box> */}
                  <Box ml={1}>
                    <SteielButton
                      sx={{ minWidth: 150 }}
                      onClick={() => {
                        setState({ showDefaultWarn: true });
                      }}
                    >
                      <Center>
                        <Box mb={-1.9}>
                          <CustomSvgIcon
                            src={assets.reload}
                            style={{ width: 24, height: 24 }}
                            onClick={() => {}}
                          />
                        </Box>
                        <Box ml={1}>
                          <Body1 color='white'>{ls.default}</Body1>
                        </Box>
                      </Center>
                    </SteielButton>
                  </Box>
                </Flex>
              </Box>
            ) : null}
          </Flex>
          {isMobile ? (
            <Box flex={1} mt={1} ml={2}>
              <DeviceIconStatus deviceStatus={deviceStatus} withDesc alwaysShowDesc />
            </Box>
          ) : null}
        </Box>
        {/* <Box mt={isMobile ? 0.5 : -2} ml={isMobile ? 2 : 0}>
          <Flex>
            <Box>
              <Body1 size='1.1rem' weight={600}>
                {ls.dateAndTime}
                {':'}
              </Body1>
            </Box>
            <Box flex={1} ml={1}>
              <Body1 size='1.1rem' weight={400}>
                {dayjs().format('YYYY/MM/DD HH:mm')}
              </Body1>
            </Box>
          </Flex>
        </Box> */}
        {isMobile && !auth.isViewer ? (
          <Flex mt={2} mx={2} mb={1.5}>
            <Box>
              <SteielButton
                sx={{ minWidth: 100 }}
                onClick={() => {
                  // navigate(PageName.deviceSetup.replace('{0}', deviceIdParam));
                  navigate(PageName.editDevice.replace('{0}', deviceIdParam));
                }}
              >
                <Center>
                  <Box mb={-1.9}>
                    <CustomSvgIcon
                      src={assets.setting}
                      style={{ width: 24, height: 24 }}
                      onClick={() => {}}
                    />
                  </Box>
                  <Box ml={1}>
                    <Body1 color='white'>{ls.setup}</Body1>
                  </Box>
                </Center>
              </SteielButton>
            </Box>
            <Box>
              <SteielButton
                sx={{ minWidth: 100 }}
                onClick={() => {
                  setState({ showDefaultWarn: true });
                }}
              >
                <Center>
                  <Box mb={-1.9}>
                    <CustomSvgIcon
                      src={assets.reload}
                      style={{ width: 24, height: 24 }}
                      onClick={() => {}}
                    />
                  </Box>
                  <Box ml={1}>
                    <Body1 color='white'>{ls.default}</Body1>
                  </Box>
                </Center>
              </SteielButton>
            </Box>
          </Flex>
        ) : null}
      </Box>
      <SteielDialog
        content={ls.deviceDefaultSettingsResetQuestion}
        onClose={() => {
          setState({ showDefaultWarn: false });
        }}
        onOkActionClick={() => {
          handleDefaultDeviceSettings();
          setState({ showDefaultWarn: false });
        }}
        open={state.showDefaultWarn}
        title={ls.default}
        onKoActionClick={() => {
          setState({ showDefaultWarn: false });
        }}
        okLabel={ls.confirm}
        koLabel={ls.cancel}
        width={isMobile ? 300 : 350}
      />
    </>
  );
};

export default DeviceDetailInfo;
