import { useApolloClient } from '@apollo/client';
import { User, useUserQuery } from '../graphql/codegen/graphql-react';

export const useUser = (userId: string) => {
  const apolloClient = useApolloClient();
  const {
    loading: userLoading,
    error: userError,
    data: userData,
    refetch: userRefetch,
    startPolling: userStartPolling,
    stopPolling: userStopPolling,
  } = useUserQuery({
    variables: { input: { userId } },
    // fetchPolicy: 'no-cache'
  });

  const user: User = { ...((userData?.user || {}) as User) };

  const resetApolloCache = async () => {
    try {
      await apolloClient.cache.reset();
    } catch (error) {}
  };

  const evictUserIdCache = () => {
    const res = apolloClient.cache.evict({ id: `User:${userId}` });
    return res;
  };

  return {
    user,
    userLoading,
    userError,
    userRefetch,
    userStartPolling,
    userStopPolling,
    resetApolloCache,
    evictUserIdCache,
  };
};
