import React from 'react';
import { Box, InputAdornment, TextField } from '@mui/material';
import { colors } from '../../theme';
import Center from './Center';
import { isNotNullAndNotUndefined, minMaxCap } from '../../helpers/utils';
import validator from 'validator';

interface SteielNumericInputProps {
  value: string;
  onValueChange: (value: string) => void;
  onMinusClick: () => void;
  onPlusClick: () => void;
  min?: number;
  max?: number;
  readOnly?: boolean;
}

const SteielNumericInput: React.FC<SteielNumericInputProps> = ({
  onValueChange,
  value,
  onMinusClick,
  onPlusClick,
  min,
  max,
  readOnly,
}) => {
  return (
    <Box>
      <TextField
        variant='outlined'
        fullWidth
        size='small'
        value={value}
        disabled={readOnly}
        onChange={(e) => {
          let val = e.target.value.replaceAll(',', '.');
          if (
            isNotNullAndNotUndefined(min) &&
            isNotNullAndNotUndefined(max) &&
            validator.isNumeric(val)
          ) {
            let valParsed = minMaxCap(min!, max!, parseFloat(val));
            onValueChange(String(valParsed));
          } else {
            onValueChange(val);
          }
        }}
        inputProps={{
          style: { textAlign: 'center', fontSize: '1.1rem' },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Box
                style={{
                  cursor: readOnly ? 'default' : 'pointer',
                  height: 42,
                  width: 42,
                  marginLeft: -14,
                  backgroundColor: readOnly ? colors.lightGrey : colors.blue,
                  borderTopLeftRadius: 3,
                  borderBottomLeftRadius: 3,
                }}
                onClick={() => {
                  if (readOnly) {
                    return;
                  }
                  onMinusClick();
                }}
              >
                <Center flex={1} height='100%'>
                  <Box
                    bgcolor='#fff'
                    width={14}
                    border='1px solid'
                    borderColor='#fff'
                    borderRadius={2}
                    height={2}
                  />
                </Center>
              </Box>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <Box
                position='relative'
                style={{
                  cursor: readOnly ? 'default' : 'pointer',
                  height: 42,
                  width: 42,
                  marginRight: -14,
                  backgroundColor: readOnly ? colors.lightGrey : colors.blue,
                  borderTopRightRadius: 3,
                  borderBottomRightRadius: 3,
                }}
                onClick={() => {
                  if (readOnly) {
                    return;
                  }
                  onPlusClick();
                }}
              >
                <Center flex={1} height='100%'>
                  <Box
                    bgcolor='#fff'
                    width={14}
                    border='1px solid'
                    borderColor='#fff'
                    borderRadius={2}
                    height={2}
                    position='relative'
                  >
                    <Box
                      position='absolute'
                      top={-7}
                      left={5}
                      bgcolor='#fff'
                      height={14}
                      border='1px solid'
                      borderColor='#fff'
                      borderRadius={2}
                      width={2}
                    />
                  </Box>
                </Center>
              </Box>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SteielNumericInput;
