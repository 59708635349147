import React from 'react';
import { CircularProgress } from '@mui/material';
import Center from './Center';
import DashboardLayout from './DashboardLayout';

const LoadingPage: React.FC = () => {
  return (
    <DashboardLayout>
      <Center mt={4}>
        <CircularProgress />
      </Center>
    </DashboardLayout>
  );
};

export default LoadingPage;
