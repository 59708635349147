import React from 'react';
import { CircularProgress } from '@mui/material';
import Center from './ui/Center';
import { useWindowSize } from 'react-use';

const Splash: React.FC = () => {
  const windowSize = useWindowSize();

  return (
    <Center height={windowSize.height}>
      <CircularProgress color='primary' />
    </Center>
  );
};

export default Splash;
