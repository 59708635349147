import { debounceSearchRender, MUIDataTableOptions } from 'mui-datatables';
import { ls } from '../i18n/translations';

export const defaultMUIDataTableOptions: MUIDataTableOptions = {
  print: false,
  selectableRows: 'none',
  rowsPerPage: 10,
  responsive: 'simple',
  serverSide: false,
  jumpToPage: true,
  rowsPerPageOptions: [10, 50, 100, 500, 1000],
  onDownload: (buildHead, buildBody, columns, data) => {
    return '\uFEFF' + buildHead(columns) + buildBody(data);
  },
  customSearchRender: debounceSearchRender(500),
  elevation: 0,
  textLabels: {
    body: {
      noMatch: ls.dtBodyNoMatch,
      toolTip: ls.dtBodyTooltip,
      columnHeaderTooltip: (column) => `${ls.dtBodyColumnHeaderTooltip} ${column.label}`,
    },
    pagination: {
      next: ls.dtNextPage,
      previous: ls.dtPreviousPage,
      rowsPerPage: ls.dtRowsPerPage,
      displayRows: ls.dtDisplayRows,
      jumpToPage: ls.jumpToPage,
    },
    toolbar: {
      search: ls.dtToolbarSearch,
      downloadCsv: ls.dtToolbarDownloadCsv,
      print: ls.dtToolbarPrint,
      viewColumns: ls.dtToolbarViewColumns,
      filterTable: ls.dtToolbarFilterTable,
    },
    filter: {
      all: ls.dtFilterAll,
      title: ls.dtFilterTitle,
      reset: ls.dtFilterReset,
    },
    viewColumns: {
      title: ls.dtViewColumnsTitle,
      titleAria: ls.dtViewColumnsTitleAria,
    },
    selectedRows: {
      text: ls.dtSelectedRowsText,
      delete: ls.dtSelectedRowsDelete,
      deleteAria: ls.dtSelectedRowsDeleteAria,
    },
  },
};
