import {
  createTheme,
  PaletteMode,
  PaletteOptions,
  responsiveFontSizes,
  Theme,
} from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { shadows } from './shadows';
import { typography } from './typography';

export const colors = {
  white: '#fff',
  black: '#000',
  lightBlue: '#5EA6D9',
  blue: '#2D5176',
  orange: '#EC6E1F',
  lightGrey: '#A5B6C8',
  green: '#14CF1C',
  red: '#EC1F1F',
  stroke: '#272A42',
  stroke2: '#444A73',
  stroke3: '#A5ABD7',
  stroke4: '#E9EAED',
  grey: '#9298A8',
  background: '#f9f9f9',
  darkBackground: '#080c25',
  darkBackground2: '#121631',
  darkMenuBg: '#191d36',
  darkNavBarBg: '#111631',
};

const getDesignTokens = (mode: PaletteMode): PaletteOptions => {
  return mode === 'light'
    ? {
        // palette values for light mode
        primary: {
          main: colors.blue,
        },
        secondary: {
          main: colors.lightGrey,
        },
        text: {
          primary: colors.blue,
          secondary: colors.lightBlue,
        },
        background: {
          default: colors.background,
        },
        error: {
          main: colors.red,
        },
      }
    : {
        // palette values for dark mode
        primary: {
          main: colors.blue,
        },
        secondary: {
          main: colors.lightGrey,
        },
        text: {
          primary: colors.lightGrey,
          secondary: colors.lightBlue,
        },
        background: {
          default: colors.darkBackground,
        },
        error: {
          main: colors.red,
        },
      };
};

export const createCustomTheme = (mode: PaletteMode = 'light'): Theme => {
  let theme = createTheme({
    palette: {
      mode: mode,
      ...getDesignTokens(mode),
    },
    typography,
    shadows,
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: '1.1rem',
            borderBottom:
              mode === 'dark'
                ? `1px solid rgba(254, 254, 254, 0.25)`
                : '1px solid rgba(224, 224, 224, 1)',
          },
        },
      },
      // @ts-ignore
      MuiButton: {
        styleOverrides: { root: { fontSize: '1.1rem' } },
      },
      MuiTableBody: {
        styleOverrides: {
          root: { backgroundColor: mode === 'dark' ? colors.darkBackground2 : colors.white },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: { backgroundColor: mode === 'dark' ? colors.darkBackground2 : colors.white },
        },
      },
      MuiList: {
        styleOverrides: {
          root: { backgroundColor: mode === 'dark' ? colors.darkBackground : colors.white },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: { backgroundColor: mode === 'dark' ? colors.darkNavBarBg : colors.white },
        },
      },
      // @ts-ignore
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'dark' ? colors.darkBackground2 : colors.white,
            // borderBottom:
            //   mode === 'dark'
            //     ? `1px solid rgba(100, 100, 100, 0.8)`
            //     : '1px solid rgba(224, 224, 224, 1)',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: { backgroundColor: mode === 'dark' ? colors.darkBackground2 : colors.white },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  theme = responsiveFontSizes(theme);
  return theme;
};
