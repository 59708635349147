import * as React from 'react';
import { ls } from '../../i18n/translations';
import { capitalize, dayjs, parseUnitOfMeasure } from '../../helpers/utils';
import { defaultMUIDataTableOptions } from '../../helpers/datatableUtils';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { MeasurementHistoryType, ModuleSettingType } from '../../graphql/dataTypes';
import { MeasurementHistory, Module } from '../../graphql/codegen/graphql-react';
import { Box } from '@mui/material';
import Center from '../ui/Center';
import { Body2 } from '../ui/Typography';

interface DeviceDetailTableProps {
  data: MeasurementHistory[];
  moduleSettings: ModuleSettingType[];
}

const options: MUIDataTableOptions = {
  ...defaultMUIDataTableOptions,
};

const DeviceDetailTable: React.FC<DeviceDetailTableProps> = ({ data, moduleSettings }) => {
  const dateTimeFile = dayjs().format('YYYY-MM-DD_HH_mm');
  type TableData = MeasurementHistoryType & { dateAndTime: string | null };
  const tableDevices = ([...(data || [])].map((m, i) => {
    const measurements = (m.measurements || {}) as MeasurementHistoryType;
    const measurementsKeys = Object.keys(measurements!);
    const tableData: TableData = {
      dateAndTime: m.createdAt ? dayjs(m.createdAt).format('YYYY-MM-DD HH:mm') : null,
    };
    for (let i = 0; i < measurementsKeys.length; i++) {
      const mKey = measurementsKeys[i];
      // @ts-ignore
      tableData[mKey] = measurements[mKey] ?? null;
    }
    return tableData;
  }) || []) as TableData[];

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'dateAndTime',
      label: ls.dateAndTime.toUpperCase(),
      options: {
        filter: false,
        sort: true,
      },
    },
  ];
  for (let i = 0; i < moduleSettings.length; i++) {
    const moduleSetting = moduleSettings[i];
    if (!moduleSetting?.enabled) {
      continue;
    }
    columns.push({
      name: `measurement${i + 1}`,
      label: (
        ls.measure.toUpperCase() +
        ` ${i + 1} ${
          moduleSetting?.unitOfMeasure
            ? `(${parseUnitOfMeasure(moduleSetting!.unitOfMeasure as string)})`
            : ''
        }`
      ).trim(),
      options: {
        filter: true,
        sort: true,
      },
    });
  }

  if (moduleSettings.every((el) => !el?.enabled)) {
    return (
      <Center my={4}>
        <Body2 size='1.1rem' textAlign='center'>
          {capitalize(ls.noMeasurementsFoundOrEnabled)}
        </Body2>
      </Center>
    );
  }

  return (
    <MUIDataTable
      title=''
      data={tableDevices}
      columns={columns}
      options={{
        ...options,
        downloadOptions: {
          filename: `DeviceDetail_${dateTimeFile}.csv`,
        },
        rowsPerPage: localStorage.getItem('devicedetail_rowsPerPage')
          ? Number(localStorage.getItem('devicedetail_rowsPerPage'))
          : 10,
        onChangeRowsPerPage: (numberOfRows) => {
          localStorage.setItem('devicedetail_rowsPerPage', `${numberOfRows}`);
        },
      }}
    />
  );
};

export default DeviceDetailTable;
