import React from 'react';
import { Box } from '@mui/material';
import { Body2 } from '../ui/Typography';
import { colors } from '../../theme';
import Center from '../ui/Center';
import Flex from '../ui/Flex';
import { assets, CustomSvgIcon } from '../../helpers/assets';
import { minMaxCap } from '../../helpers/utils';
import { useDarkMode } from '../../hook/useDarkMode';

interface ProgressPercChartProps {
  min: number;
  minAcceptableValue: number;
  max: number;
  maxAcceptableValue: number;
  currentValue?: number;
  width?: number;
}

const ProgressPercChart: React.FC<ProgressPercChartProps> = ({
  max,
  maxAcceptableValue,
  min,
  minAcceptableValue,
  currentValue,
  width = 450,
}) => {
  const { isDarkMode } = useDarkMode();
  const nPin = 6;
  const chartPinSize = 12;
  const finalPinSize = 12;
  const intermediatePinSize = 8;
  const chartWidth = width;
  const pinDistance = (chartWidth + 8) / nPin;
  const offset = width * 0.2;
  currentValue = minMaxCap(min, max, currentValue ?? min) as number;
  minAcceptableValue = minMaxCap(min, max, minAcceptableValue);
  maxAcceptableValue = minMaxCap(min, max, maxAcceptableValue);
  const pinPosition = ((currentValue - min) / ((max - min) / chartWidth || 1)) * 0.825;
  const startAcceptablePos = (minAcceptableValue - min) / ((max - min) / chartWidth || 1);
  const endAcceptablePos = (maxAcceptableValue - min) / ((max - min) / chartWidth || 1);
  return (
    <Box
      borderRadius={2}
      border='1px solid'
      borderColor={colors.lightGrey}
      width={chartWidth - offset + finalPinSize * 2 + intermediatePinSize * 4 + 28}
      pl={3.5}
      pt={1}
      pb={1}
      pr={1}
    >
      <Box position='relative' pt={4} pb={5} width={chartWidth}>
        <Box position='absolute' top={-6} left={pinPosition}>
          <CustomSvgIcon
            src={assets.chartPin}
            style={{ width: chartPinSize, height: chartPinSize }}
          />
        </Box>
        <Flex justifyContent='flex-start' width={chartWidth} position='absolute' top={32} left={5}>
          {startAcceptablePos > 0 ? (
            <Box
              border='1px solid'
              width={startAcceptablePos}
              borderColor={colors.red}
              bgcolor={colors.red}
            />
          ) : null}
          <Box
            border='1px solid'
            width={endAcceptablePos - startAcceptablePos}
            borderColor={colors.green}
            bgcolor={colors.green}
          />
          {chartWidth - endAcceptablePos > 0 ? (
            <Box
              border='1px solid'
              width={chartWidth - endAcceptablePos}
              borderColor={colors.red}
              bgcolor={colors.red}
            />
          ) : null}
          <Box
            border='1px solid'
            width={offset}
            borderColor={'transparent'}
            bgcolor={'transparent'}
          />
        </Flex>
        {Array(nPin)
          .fill(0)
          .map((el, i) => {
            let top = 29;
            let pos = pinDistance * (i + 1) - pinDistance - (i / 2.6) * i;
            let size = intermediatePinSize;
            const val = Number((((max - min) / 5) * (i + 1) + min - (max - min) / 5).toFixed(1));
            if (i === 0 || i >= nPin - 1) {
              size = finalPinSize;
              top -= 2;
            }
            let textPos = pos;
            if (val >= 1000) {
              textPos -= 8;
            } else if (val >= 100) {
              textPos -= 6;
            } else if (val >= 10) {
              textPos -= 4;
            }
            return (
              <Box key={i}>
                <Box position='absolute' top={top} left={pos}>
                  <Center flexDirection='column'>
                    <Box
                      height={size}
                      width={size}
                      borderRadius={100}
                      bgcolor={isDarkMode ? colors.stroke2 : colors.stroke}
                    />
                  </Center>
                </Box>
                <Box position='absolute' top={45} left={textPos}>
                  <Box>
                    <Body2>{val}</Body2>
                  </Box>
                </Box>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default ProgressPercChart;
