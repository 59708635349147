import React from 'react';
import { Box, BoxProps, InputAdornment, LinearProgress } from '@mui/material';
import { ls } from '../../i18n/translations';
import SteielTextInput from '../ui/SteielTextInput';
import SteielButton from '../ui/SteielButton';
import ErrorMessage from '../ErrorMessage';
import { Form, Formik } from 'formik';
import { PASSWORD_MIN_LENGTH, validateEmail, validatePassword } from '../../helpers/validators';
import {
  catchErrors,
  getCompanyDomaninFromUrl,
  isHTHCompany,
  isObjEmpty,
  logErr,
} from '../../helpers/utils';
import { useAuth } from '../../hook/useAuth';
import { Link, useNavigate } from 'react-router-dom';
import { PageName } from '../../routes';
import { Body1, H2 } from '../ui/Typography';
import { useMQ } from '../../hook/useMQ';
import { colors } from '../../theme';
import { useWindowSize } from 'react-use';
import Center from '../ui/Center';
import Flex, { Stack } from '../ui/Flex';
import { assets, CustomSvgIcon } from '../../helpers/assets';
import LightDarkModeSwitch from '../ui/LightDarkModeSwitch';
import { OnlyOnMobile } from '../ui/Responsive';
import { useDarkMode } from '../../hook/useDarkMode';

interface FormData {
  email: string;
  password: string;
}

const initialFormData: FormData = {
  email: '',
  password: '',
};

const LoginForm: React.FC = () => {
  const [errorFromServer, setErrorFromServer] = React.useState('');
  const auth = useAuth();
  const navigate = useNavigate();
  const { isMobile, isTablet, isDesktop } = useMQ();
  const windowSize = useWindowSize();
  const { isDarkMode } = useDarkMode();
  const isHTH = isHTHCompany();

  let boxProps: BoxProps = {
    width: '100%',
    bgcolor: isHTH && isMobile ? 'transparent' : isDarkMode ? colors.darkBackground2 : '#fff',
    borderRadius: 1,
    sx: { boxShadow: 3 },
  };
  if (!isMobile) {
    boxProps = {
      ...boxProps,
      width: Math.min(windowSize.width * 0.8, 500),
    };
  } else {
    boxProps = {
      ...boxProps,
      width: '95%',
      bgcolor:
        isHTH && isMobile ? 'transparent' : isDarkMode ? colors.darkBackground : colors.background,
      sx: { boxShadow: 0 },
    };
  }

  return (
    <Box {...boxProps} mb={4}>
      <Box mt={isHTH && isMobile ? 8 : 4} mb={2} textAlign='center'>
        {isHTH && isMobile ? <Box height={20} /> : <H2 style={{ fontWeight: 600 }}>{ls.login}</H2>}
      </Box>
      <Box>
        <Formik
          initialValues={initialFormData}
          validate={(values) => {
            const errors: Partial<FormData> = {};
            const email = validateEmail(values.email);
            const password = validatePassword(values.password);
            if (!email.isValid) {
              errors.email = ls.getString(email.errorTranslationKey);
            }
            if (!password.isValid) {
              errors.password = ls
                .getString(password.errorTranslationKey)
                .replace('{0}', `${PASSWORD_MIN_LENGTH}`);
            }
            // reset errorFromServer
            if (!isObjEmpty(errors)) {
              setErrorFromServer('');
            }
            return errors;
          }}
          onSubmit={async (values, actions) => {
            try {
              // log('submitting', values);
              setErrorFromServer('');
              const companyDomain = getCompanyDomaninFromUrl();
              const { signIn } = await auth.signIn({
                email: values.email,
                password: values.password,
                companyDomain,
              });
              if (!signIn?.jwtToken || !signIn.user?.email) {
                throw new Error(ls.somethingWentWrong);
              } else {
                navigate(PageName.home);
              }
            } catch (error) {
              logErr('error on submit login form', error);
              catchErrors(error, setErrorFromServer);
            } finally {
              actions.setSubmitting(false);
            }
          }}
        >
          {(props) => {
            const emailError = (props.dirty && props.touched.email && props.errors.email) || '';
            const passwordError =
              (props.dirty && props.touched.password && props.errors.password) || '';

            const firstError = emailError || passwordError || errorFromServer || '';

            return (
              <Form>
                <Box mx={isMobile ? 0 : 4}>
                  <Flex mt={4} justifyContent='flex-start'>
                    {/* <Box
                      mr={-1}
                      zIndex={10}
                      bgcolor={colors.lightGrey}
                      style={{
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5,
                        height: 40,
                        width: 50,
                      }}
                    >
                      <Center>
                        <CustomSvgIcon src={assets.menuUserWhite} />
                      </Center>
                    </Box> */}
                    <SteielTextInput
                      fieldName='email'
                      formProps={props}
                      textFieldProps={{
                        placeholder: ls.email,
                        required: true,
                        type: 'email',
                        error: Boolean(emailError),
                        size: 'small',
                        color: 'secondary',
                        // focused: true,
                        style: { backgroundColor: isDarkMode ? colors.darkBackground : '#fff' },
                        InputProps: {
                          sx: {
                            // '& .MuiInputBase-input': {
                            //   color: 'secondary.main',
                            // },
                          },
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Box
                                ml={-1.7}
                                mt={0}
                                bgcolor={
                                  isHTH
                                    ? '#3463A2'
                                    : isDarkMode
                                    ? colors.darkMenuBg
                                    : colors.lightGrey
                                }
                                width={50}
                                height={41.5}
                                style={{
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0,
                                }}
                              >
                                <CustomSvgIcon
                                  src={assets.email}
                                  style={{ marginLeft: 0.5, marginTop: 7, width: 28, height: 28 }}
                                />
                              </Box>
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </Flex>
                  <Box mt={1.5}>
                    <SteielTextInput
                      fieldName='password'
                      formProps={props}
                      eyeIconColor={colors.lightGrey}
                      textFieldProps={{
                        placeholder: ls.password,
                        required: true,
                        error: Boolean(passwordError),
                        size: 'small',
                        color: 'secondary',
                        // focused: true,
                        style: { backgroundColor: isDarkMode ? colors.darkBackground : '#fff' },
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Box
                                ml={-1.7}
                                mt={0}
                                bgcolor={
                                  isHTH
                                    ? '#3463A2'
                                    : isDarkMode
                                    ? colors.darkMenuBg
                                    : colors.lightGrey
                                }
                                width={50}
                                height={41.5}
                                style={{
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0,
                                }}
                              >
                                <CustomSvgIcon
                                  src={assets.lock}
                                  style={{ marginLeft: 1.5, marginTop: 8, width: 24, height: 24 }}
                                />
                              </Box>
                            </InputAdornment>
                          ),
                        },
                      }}
                      isPassword
                    />
                  </Box>
                </Box>
                {firstError ? (
                  <ErrorMessage
                    containerStyle={{
                      my: 2,
                    }}
                    message={firstError}
                  />
                ) : (
                  <Box />
                )}
                {props.isSubmitting && (
                  <Box my={2}>
                    <LinearProgress />
                  </Box>
                )}
                <Center mt={2}>
                  <Link to={PageName.passwordrecovery} style={{ textDecoration: 'none' }}>
                    <Body1
                      color={isHTH && isMobile ? colors.blue : colors.lightGrey}
                      style={{
                        textAlign: 'center',
                        textDecorationLine: 'underline',
                      }}
                    >
                      {ls.forgetPasswordQuestion}
                    </Body1>
                  </Link>
                </Center>
                <Center mt={3} mb={2} width='100%'>
                  <Box width={150}>
                    <SteielButton
                      text={ls.signIn}
                      type='submit'
                      disabled={props.isSubmitting}
                      fullWidth
                      style={
                        isHTH ? { backgroundColor: '#E2211C', borderColor: '#E2211C' } : undefined
                      }
                    />
                  </Box>
                </Center>
              </Form>
            );
          }}
        </Formik>
      </Box>
      <OnlyOnMobile>
        {/* <Center mt={8}>
          <LightDarkModeSwitch />
        </Center> */}
        {isHTH ? (
          <Stack mt={12} justifyContent='center'>
            <a href='https://www.hth-pro.com' target='_blank' rel='noopener noreferrer'>
              <img alt='hth pro link' src={assets.hthProUrl} style={{ height: 18 }} />
            </a>
            <Center mt={1}>
              <img
                alt='powered by STEIEL'
                src={assets.poweredSteiel}
                style={{ height: 30, width: 145 }}
              />
            </Center>
          </Stack>
        ) : null}
      </OnlyOnMobile>
    </Box>
  );
};

export default LoginForm;
