import { useApolloClient } from '@apollo/client';
import { User, useUsersQuery } from '../graphql/codegen/graphql-react';

export const useUsers = () => {
  const apolloClient = useApolloClient();
  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
    refetch: usersRefetch,
    startPolling: usersStartPolling,
    stopPolling: usersStopPolling,
  } = useUsersQuery();

  const users: User[] = [...((usersData?.users || []) as User[])];

  const resetApolloCache = async () => {
    try {
      await apolloClient.cache.reset();
    } catch (error) {}
  };

  return {
    users,
    usersLoading,
    usersError,
    usersRefetch,
    usersStartPolling,
    usersStopPolling,
    resetApolloCache,
  };
};
