import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: ['Roboto Condensed', 'sans-serif'].join(','),
  button: {
    textTransform: 'none',
  },
  h1: {
    fontWeight: 700,
    fontSize: 35,
    // letterSpacing: '-0.24px',
  },
  h2: {
    fontWeight: 700,
    fontSize: 29,
    // letterSpacing: '-0.24px',
  },
  h3: {
    fontWeight: 700,
    fontSize: 24,
    // letterSpacing: '-0.06px',
  },
  h4: {
    fontWeight: 700,
    fontSize: 20,
    // letterSpacing: '-0.06px',
  },
  h5: {
    fontWeight: 700,
    fontSize: 16,
    // letterSpacing: '-0.05px',
  },
  h6: {
    fontWeight: 700,
    fontSize: 14,
    // letterSpacing: '-0.05px',
  },
  overline: {
    fontWeight: 700,
  },
};
