import LocalizedStrings from 'localized-strings';
import { en } from './en';
import { fr } from './fr';
import { it } from './it';
import { es } from './es';

const defaultLang = 'en';
const allowedLang = ['it', 'en', 'fr', 'es'];

const appString = new LocalizedStrings({
  it,
  en,
  fr,
  es,
});

let userLang: string =
  typeof window !== 'undefined' // func isBrowser
    ? localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : navigator.language || (navigator as any).userLanguage || defaultLang
    : defaultLang;

// check lang
if (!allowedLang.includes(userLang)) {
  if (userLang.startsWith('it')) {
    userLang = 'it';
  } else if (userLang.startsWith('en')) {
    userLang = 'en';
  } else if (userLang.startsWith('fr')) {
    userLang = 'fr';
  } else if (userLang.startsWith('es')) {
    userLang = 'es';
  } else {
    userLang = defaultLang;
  }
}

// console.log('language', userLang);

appString.setLanguage(userLang);

export const ls = appString;
export { userLang };
export { allowedLang };
export { defaultLang };
