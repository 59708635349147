import React from 'react';
import { Box } from '@mui/material';
import Flex from './Flex';
import { assets, CustomSvgIcon } from '../../helpers/assets';
import { colors } from '../../theme/';
import { ls } from '../../i18n/translations';
import { Body2 } from './Typography';
import Center from './Center';
import { useDarkMode } from '../../hook/useDarkMode';

const DeviceStatusIconLegend: React.FC = () => {
  const { isDarkMode } = useDarkMode();
  return (
    <Flex style={{ border: `1px solid ${colors.lightGrey}`, borderRadius: 6 }} p={1}>
      <Flex mt={0.1}>
        <Center>
          <CustomSvgIcon src={assets.online} />
        </Center>
        <Box ml={0.5} mb={-0.1}>
          <Body2 color={isDarkMode ? colors.lightGrey : colors.grey}>
            {ls.online.toUpperCase()}
          </Body2>
        </Box>
      </Flex>
      <Flex ml={2} mt={0.1}>
        <Center>
          <CustomSvgIcon src={assets.offline} />
        </Center>
        <Box ml={0.5} mb={-0.1}>
          <Body2 color={isDarkMode ? colors.lightGrey : colors.grey}>
            {ls.offline.toUpperCase()}
          </Body2>
        </Box>
      </Flex>
      <Flex ml={2} mt={0.1}>
        <Center>
          <CustomSvgIcon src={assets.warning} />
        </Center>
        <Box ml={0.5} mb={-0.1}>
          <Body2 color={isDarkMode ? colors.lightGrey : colors.grey}>
            {ls.alarm.toUpperCase()}
          </Body2>
        </Box>
      </Flex>
    </Flex>
  );
};

export default DeviceStatusIconLegend;
