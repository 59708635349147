import React from 'react';
import { Box, CircularProgress, IconButton, LinearProgress } from '@mui/material';
import { ls } from '../../i18n/translations';
import SteielTextInput from '../ui/SteielTextInput';
import SteielButton from '../ui/SteielButton';
import ErrorMessage from '../ErrorMessage';
import { Form, Formik } from 'formik';
import { validateRequiredField } from '../../helpers/validators';
import {
  capitalize,
  catchErrors,
  fromParsedToMachineType,
  fromParsedToMachineVersion,
  getBrowserTimezone,
  getErrorMessage,
  isNotNullAndNotUndefined,
  isObjEmpty,
  log,
  logErr,
  parseMachineType,
  uniqueArr,
} from '../../helpers/utils';
import { Body1, Body2 } from '../ui/Typography';
import { useMQ } from '../../hook/useMQ';
import { colors } from '../../theme';
import Center from '../ui/Center';
import Flex from '../ui/Flex';
import SamplingTimeSelect from '../selects/SamplingTimeSelect';
import TimezoneSelect from 'react-timezone-select';
import SteielAutocomplete from '../ui/SteielAutocomplete';
import { useSearchParams } from '../../hook/useSearchParams';
import { useDevice } from '../../hook/useDevice';
import { useUsers } from '../../hook/useUsers';
import { useMount, useSetState } from 'react-use';
import sdkClient from '../../graphql/sdkClient';
import { useAuth } from '../../hook/useAuth';
import ModulesTable from '../tables/ModulesTable';
import {
  Action,
  Company,
  MachineType,
  MachineVersion,
  Module,
  UserRole,
} from '../../graphql/codegen/graphql-react';
import SteielDialog from '../ui/SteielDialog';
import { useDevices } from '../../hook/useDevices';
import { useNavigate } from 'react-router-dom';
import { PageName } from '../../routes';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { useDarkMode } from '../../hook/useDarkMode';

interface FormData {
  id: string;
  name: string;
  place: string;
  company: string;
  installer: string;
  customer: string;
  samplingTime: number | null;
  timezone: string;
  modules: Partial<Module>[];
}

interface EditDeviceFormProps {
  isNewDevice?: boolean;
}

const EditDeviceForm: React.FC<EditDeviceFormProps> = ({ isNewDevice }) => {
  const [errorFromServer, setErrorFromServer] = React.useState('');
  const { isDarkMode } = useDarkMode();
  const [state, setState] = useSetState<{
    loadingData: boolean;
    companiesOptions: string[];
    modulesOptions: string[];
    showSuccessDialog: boolean;
    showDeleteDeviceDialog: boolean;
    deletingDevice: boolean;
    tableKey: number;
    loadingCustomers: boolean;
    allowedCustomers: string[];
  }>({
    loadingData: true,
    companiesOptions: [],
    modulesOptions: [],
    showSuccessDialog: false,
    showDeleteDeviceDialog: false,
    deletingDevice: false,
    tableKey: Date.now(),
    allowedCustomers: [],
    loadingCustomers: true,
  });
  const auth = useAuth();
  const { isMobileOrTablet } = useMQ();
  const { deviceIdParam } = useSearchParams();
  const { devicesRefetch } = useDevices();
  const { device, deviceError, deviceLoading, deviceRefetch, evictDeviceIdCache } =
    useDevice(deviceIdParam);
  const { users, usersError, usersLoading, usersRefetch } = useUsers();
  const navigate = useNavigate();
  const modulesRef = React.useRef<Partial<Module>[]>([]);

  useMount(async () => {
    try {
      setState({ loadingData: true, loadingCustomers: true });
      let companies: Company[] = [];
      if (auth.isAdmin) {
        const { companies: companiesRes } = await sdkClient().companies();
        companies = companiesRes as Company[];
      }
      const { associableModules } = await sdkClient().associableModules();
      setState({
        companiesOptions: uniqueArr(
          companies
            ?.map((c) => {
              return c?.name || '';
            })
            .filter((el) => el) || [],
        ),
        modulesOptions: uniqueArr(
          associableModules
            ?.map((m) => {
              return m?.mac || '';
            })
            .filter((el) => el) || [],
        ),
      });
      const { getManageableUsersIdsByEmail } = await sdkClient().getManageableUsersIdsByEmail({
        input: { email: isNewDevice ? auth.user?.email || '' : device.TechnicianUser?.email || '' }, // as installer default value
      });
      if (getManageableUsersIdsByEmail) {
        const getManageableUsersIdsByEmailParsed = getManageableUsersIdsByEmail.filter(
          (el) =>
            el !== (isNewDevice ? auth.user?.email || '' : device.TechnicianUser?.email || ''), // as installer default value
        );
        // @ts-ignore
        setState({ allowedCustomers: getManageableUsersIdsByEmailParsed });
      }
    } catch (error) {
      logErr('error loading data', error);
      catchErrors(error, setErrorFromServer);
    } finally {
      setState({ loadingData: false, loadingCustomers: false });
    }
  });

  const deleteDevice = async () => {
    try {
      setErrorFromServer('');
      setState({ deletingDevice: true });
      await sdkClient().updateDevice({ input: { action: Action.Delete, deviceId: device.id } });
      await devicesRefetch();
      navigate(PageName.devicesManagement);
    } catch (error) {
      logErr('error deleting device', device.id, error);
      catchErrors(error, setErrorFromServer);
    } finally {
      setState({ deletingDevice: false });
    }
  };

  if (
    state.loadingData ||
    state.deletingDevice ||
    (!isNewDevice && deviceLoading) ||
    usersLoading
  ) {
    return (
      <Center mt={1}>
        <CircularProgress />
      </Center>
    );
  }

  const autoCompleteUsersOptions: string[] = uniqueArr(
    users
      .map((u) => {
        if (!u || !u.email || u?.roles?.includes(UserRole.Viewer)) {
          return '';
        }
        return u.email;
      })
      .filter((el) => el) || [],
  );

  const initialFormData: FormData = {
    id: device.id || '',
    name: device.name || '',
    company: isNewDevice ? auth.user?.Company?.name || '' : device.Company?.name || '',
    customer: isNewDevice ? '' : device.User?.email || '',
    installer: isNewDevice ? auth.user?.email || '' : device.TechnicianUser?.email || '',
    place: device.place || '',
    samplingTime: isNotNullAndNotUndefined(device.samplingTime) ? device.samplingTime! : null,
    timezone: isNewDevice ? getBrowserTimezone() : device.timezone || '',
    modules:
      device.modules?.map((m) => {
        return {
          mac: m?.mac || '',
          name: m?.name || '',
          type: m?.type || MachineType.Unknown,
          tool: m?.tool || '',
          modelVersion: m?.modelVersion || MachineVersion.Unknown,
        };
      }) || [],
  };

  modulesRef.current = (device.modules || []) as Partial<Module>[];

  return (
    <Box>
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={!isNewDevice ? getErrorMessage(deviceError) : ''}
      />
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={getErrorMessage(usersError)}
      />
      <Formik
        initialValues={{ ...initialFormData }}
        validate={(values) => {
          const errors: Partial<FormData> = {};
          const name = validateRequiredField(values.name);
          // const company = validateRequiredField(values.company);
          // const installer = validateRequiredField(values.installer);
          // const customer = validateRequiredField(values.customer);
          if (!name.isValid) {
            errors.name = ls.getString(name.errorTranslationKey);
          }
          // if (!company.isValid) {
          //   errors.company = ls.getString(company.errorTranslationKey);
          // }
          // if (!installer.isValid) {
          //   errors.installer = ls.getString(installer.errorTranslationKey);
          // }
          // if (!customer.isValid) {
          //   errors.customer = ls.getString(customer.errorTranslationKey);
          // }
          // reset errorFromServer
          if (!isObjEmpty(errors)) {
            setErrorFromServer('');
          }
          return errors;
        }}
        onSubmit={async (values, actions) => {
          try {
            log('submitting', values);
            setErrorFromServer('');
            // check input
            if (!values.company) {
              throw new Error(capitalize(ls.invalidCompanyValue));
            }
            if (
              !values.modules ||
              values.modules.length === 0 ||
              values.modules.some((m) => !m.mac)
            ) {
              throw new Error(capitalize(ls.macAddressNotFound));
            }
            let company: Company | null = null;
            const { existCompany } = await sdkClient().existCompany({
              input: { companyName: values.company },
            });
            company = existCompany as Company;
            let userId: string | null = null;
            let technicianUserId: string | null = null;
            if (values.customer) {
              const { existUser } = await sdkClient().existUser({
                input: { companyId: company?.id || 'n/a', email: values.customer },
              });
              userId = existUser?.id || null;
            }
            if (values.installer) {
              const { existUser } = await sdkClient().existUser({
                input: { companyId: company?.id || 'n/a', email: values.installer },
              });
              technicianUserId = existUser?.id || null;
            }
            const promises = [];
            for (let i = 0; i < values.modules.length; i++) {
              const module = values.modules[i];
              const existModulePromise = sdkClient().existModule({
                input: { mac: module.mac || 'n/a' },
              });
              promises.push(existModulePromise);
            }
            const modulesPromiseRes = await Promise.all(promises);
            await sdkClient().updateDevice({
              input: {
                action: isNewDevice ? Action.Create : Action.Update,
                companyId: company?.id,
                deviceId: device.id,
                name: values.name,
                place: values.place,
                samplingTime: values.samplingTime,
                technicianUserId,
                userId,
                timezone: values.timezone,
                modules: values.modules.map((m, i) => {
                  return {
                    moduleId: modulesPromiseRes[i].existModule?.id || 'n/a',
                    modelVersion: fromParsedToMachineVersion(m.modelVersion as string),
                    name: m.name,
                    tool: m.tool,
                    type: fromParsedToMachineType(
                      (m.type as MachineType) || parseMachineType(MachineType.Unknown),
                    ),
                  };
                }),
              },
            });
            if (!isNewDevice) {
              evictDeviceIdCache();
              await deviceRefetch();
              modulesRef.current = (device.modules || []) as Partial<Module>[];
            }
            await usersRefetch();
            await devicesRefetch();
            setState({ showSuccessDialog: true });
          } catch (error) {
            logErr('error on submit edit device form', error);
            catchErrors(error, setErrorFromServer);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {(props) => {
          const nameError = (props.dirty && props.touched.name && props.errors.name) || '';
          // const companyError = (props.dirty && props.touched.company && props.errors.company) || '';
          // const installerError =
          //   (props.dirty && props.touched.installer && props.errors.installer) || '';
          // const customerError =
          //   (props.dirty && props.touched.customer && props.errors.customer) || '';

          const firstError = nameError || errorFromServer || '';

          const labelWidth = isMobileOrTablet ? undefined : 180;
          const textFieldWidth = isMobileOrTablet ? '100%' : 500;
          const rootBoxWidth = isMobileOrTablet
            ? '100%'
            : // @ts-ignore
              labelWidth + textFieldWidth;

          return (
            <Form>
              <Box
                borderRadius={1}
                sx={{ boxShadow: 1, maxWidth: 1000 }}
                bgcolor={isDarkMode ? colors.darkBackground2 : 'white'}
                pb={2}
                width={rootBoxWidth}
              >
                <Flex p={2}>
                  <Box>
                    <Body1 weight={600} size='1.1rem'>
                      {isNewDevice ? ls.newDevice : ls.modifyDevice}
                    </Body1>
                  </Box>
                  {(auth.isAdmin || auth.isAdmin2 || auth.isSuperTechnician || auth.isTechnician) &&
                  !isNewDevice ? (
                    <Box>
                      <SteielButton
                        sx={{ height: 35 }}
                        text={capitalize(ls.deleteDevice)}
                        style={{
                          backgroundColor: 'transparent',
                          borderColor: 'transparent',
                        }}
                        typographyProps={{
                          style: { color: colors.red, textDecoration: 'underline' },
                          weight: 600,
                        }}
                        onClick={() => {
                          setState({ showDeleteDeviceDialog: true });
                        }}
                      />
                    </Box>
                  ) : null}
                </Flex>
                <Box className='separator' width='100%' />
                <Box m={2}>
                  <Box>
                    {!isNewDevice ? (
                      <>
                        {/* <Flex justifyContent='flex-start'>
                          <SteielTextInput
                            fieldName='id'
                            formProps={props}
                            label={ls.id.toUpperCase()}
                            labelStyle={{
                              weight: 600,
                              width: labelWidth,
                            }}
                            disabled={true}
                            textFieldProps={{
                              placeholder: ls.id.toUpperCase(),
                              required: true,
                              error: false,
                              size: 'small',
                              style: {
                                backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                                width: textFieldWidth,
                              },
                            }}
                          />
                        </Flex> */}
                      </>
                    ) : null}
                    <Flex mt={isNewDevice ? 0 : 1} justifyContent='flex-start'>
                      <SteielTextInput
                        fieldName='name'
                        formProps={props}
                        label={capitalize(ls.name)}
                        labelStyle={{
                          weight: 600,
                          width: labelWidth,
                        }}
                        textFieldProps={{
                          placeholder: capitalize(ls.name),
                          required: true,
                          error: Boolean(nameError),
                          size: 'small',
                          style: {
                            backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                            width: textFieldWidth,
                          },
                        }}
                      />
                    </Flex>
                    <Flex mt={1} justifyContent='flex-start'>
                      <SteielTextInput
                        fieldName='place'
                        formProps={props}
                        label={capitalize(ls.place)}
                        labelStyle={{
                          weight: 600,
                          width: labelWidth,
                        }}
                        textFieldProps={{
                          placeholder: capitalize(ls.place),
                          error: false,
                          size: 'small',
                          style: {
                            backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                            width: textFieldWidth,
                          },
                        }}
                      />
                    </Flex>
                    {auth.isAdmin ? (
                      <Flex
                        mt={1}
                        justifyContent={isMobileOrTablet ? 'center' : 'flex-start'}
                        alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                        flexDirection={isMobileOrTablet ? 'column' : 'row'}
                      >
                        <Body1
                          weight={600}
                          width={
                            isMobileOrTablet
                              ? labelWidth
                              : // @ts-ignore
                                labelWidth + 9
                          }
                          style={{ marginLeft: 2, marginBottom: 2 }}
                        >
                          {`${capitalize(ls.company)}:`}
                        </Body1>
                        <SteielAutocomplete
                          options={state.companiesOptions}
                          value={props.values.company}
                          onChange={(v) => {
                            props.setFieldValue('company', v);
                          }}
                          disabled={!auth.isAdmin || !auth.user?.isSuperAdmin}
                          textFieldProps={{ placeholder: `${capitalize(ls.company)} *` }}
                        />
                        {/* <SteielTextInput
                        fieldName='company'
                        formProps={props}
                        label={capitalize(ls.company)}
                        labelStyle={{
                          weight: 600,
                          width: labelWidth,
                        }}
                        textFieldProps={{
                          placeholder: capitalize(ls.company),
                          required: true,
                          error: Boolean(companyError),
                          size: 'small',
                          style: { backgroundColor: '#fff', width: textFieldWidth },
                        }}
                      /> */}
                      </Flex>
                    ) : null}
                    {auth.isAdmin || auth.isAdmin2 || auth.isSuperTechnician ? (
                      <Flex
                        mt={1}
                        justifyContent={isMobileOrTablet ? 'center' : 'flex-start'}
                        alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                        flexDirection={isMobileOrTablet ? 'column' : 'row'}
                      >
                        <Body1
                          weight={600}
                          width={
                            isMobileOrTablet
                              ? labelWidth
                              : // @ts-ignore
                                labelWidth + 9
                          }
                          style={{ marginLeft: 2, marginBottom: 2 }}
                        >
                          {`${capitalize(ls.referent)}:`}
                        </Body1>
                        <SteielAutocomplete
                          options={autoCompleteUsersOptions}
                          value={props.values.installer}
                          onChange={(v) => {
                            props.setFieldValue('installer', v);
                          }}
                          textFieldProps={{
                            placeholder: `${capitalize(ls.referent)}`,
                            onBlur: async (e) => {
                              try {
                                setState({ loadingCustomers: true });
                                const { getManageableUsersIdsByEmail } =
                                  await sdkClient().getManageableUsersIdsByEmail({
                                    input: { email: props.values.installer || '' },
                                  });
                                if (
                                  props.values.customer &&
                                  !(getManageableUsersIdsByEmail || []).includes(
                                    props.values.customer,
                                  )
                                ) {
                                  props.setFieldValue('customer', '');
                                }
                                const getManageableUsersIdsByEmailParsed = (
                                  getManageableUsersIdsByEmail || []
                                ).filter((el) => el !== props.values.installer);
                                // @ts-ignore
                                setState({ allowedCustomers: getManageableUsersIdsByEmailParsed });
                              } catch (error) {
                                logErr(error);
                                setState({ allowedCustomers: [] });
                              } finally {
                                setState({ loadingCustomers: false });
                              }
                            },
                          }}
                        />
                        {/* <SteielTextInput
                        fieldName='installer'
                        formProps={props}
                        label={capitalize(ls.installer)}
                        labelStyle={{
                          weight: 600,
                          width: labelWidth,
                        }}
                        textFieldProps={{
                          placeholder: capitalize(ls.installer),
                          required: true,
                          error: Boolean(installerError),
                          size: 'small',
                          style: { backgroundColor: '#fff', width: textFieldWidth },
                        }}
                      /> */}
                      </Flex>
                    ) : null}
                    {auth.isAdmin ||
                    auth.isAdmin2 ||
                    auth.isSuperTechnician ||
                    auth.isTechnician ? (
                      <Flex
                        mt={1}
                        justifyContent={isMobileOrTablet ? 'center' : 'flex-start'}
                        alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                        flexDirection={isMobileOrTablet ? 'column' : 'row'}
                      >
                        <Body1
                          weight={600}
                          width={
                            isMobileOrTablet
                              ? labelWidth
                              : // @ts-ignore
                                labelWidth + 9
                          }
                          style={{ marginLeft: 2, marginBottom: 2 }}
                        >
                          {`${capitalize(ls.customer)}:`}
                        </Body1>
                        <SteielAutocomplete
                          options={state.allowedCustomers}
                          value={props.values.customer}
                          onChange={(v) => {
                            props.setFieldValue('customer', v);
                          }}
                          disabled={state.loadingCustomers}
                          textFieldProps={{
                            placeholder: state.loadingCustomers
                              ? '...'
                              : `${capitalize(ls.customer)}`,
                          }}
                        />
                        {/* <SteielTextInput
                        fieldName='customer'
                        formProps={props}
                        label={capitalize(ls.customer)}
                        labelStyle={{
                          weight: 600,
                          width: labelWidth,
                        }}
                        textFieldProps={{
                          placeholder: capitalize(ls.customer),
                          required: true,
                          error: Boolean(customerError),
                          size: 'small',
                          style: { backgroundColor: '#fff', width: textFieldWidth },
                        }}
                      /> */}
                      </Flex>
                    ) : null}
                    <Flex mt={1} justifyContent='flex-start'>
                      {/* <SteielTextInput
                        fieldName='timezone'
                        formProps={props}
                        label={capitalize(ls.timeZone)}
                        labelStyle={{
                          weight: 600,
                          width: labelWidth,
                        }}
                        textFieldProps={{
                          placeholder: capitalize(ls.timeZone),
                          error: false,
                          size: 'small',
                          style: { backgroundColor: '#fff', width: textFieldWidth },
                        }}
                      /> */}
                      <Flex
                        justifyContent={isMobileOrTablet ? 'center' : 'flex-start'}
                        width={isMobileOrTablet ? '100%' : undefined}
                        flexDirection={isMobileOrTablet ? 'column' : 'row'}
                        alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                      >
                        <Body1
                          weight={600}
                          width={isMobileOrTablet ? undefined : 145.88}
                          style={{
                            marginLeft: 2,
                            marginBottom: 2,
                          }}
                        >
                          {capitalize(ls.timeZone) + ':'}
                        </Body1>
                        <Box width={textFieldWidth}>
                          <TimezoneSelect
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 42.3,
                                borderRadius: 4,
                                color: isDarkMode ? colors.lightGrey : colors.blue,
                                backgroundColor: isDarkMode ? colors.darkBackground2 : undefined,
                                borderColor: isDarkMode ? 'rgba(254, 254, 254, 0.2)' : '#c4c4c4',
                              }),
                              option: (baseStyles, state) => ({
                                ...baseStyles,
                                color: isDarkMode ? colors.lightGrey : colors.blue,
                              }),
                              menuList: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: isDarkMode ? colors.darkBackground : 'white',
                                height: 140,
                                color: isDarkMode ? colors.lightGrey : colors.blue,
                                fontFamily: ['Roboto Condensed'],
                                fontSize: '1.1rem',
                              }),
                              singleValue: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: ['Roboto Condensed'],
                                color: isDarkMode ? colors.lightGrey : colors.blue,
                                fontSize: '1.1rem',
                              }),
                              input: (baseStyles, state) => ({
                                ...baseStyles,
                                fontFamily: ['Roboto Condensed'],
                                color: isDarkMode ? colors.lightGrey : colors.blue,
                                fontSize: '1.1rem',
                              }),
                            }}
                            // theme={(theme) => ({
                            //   ...theme,
                            //   borderRadius: 0,
                            //   colors: {
                            //     ...theme.colors,
                            //     text: colors.blue,
                            //   },
                            // })}
                            value={props.values.timezone}
                            onChange={(val) => {
                              props.setFieldValue('timezone', val.value);
                            }}
                          />
                        </Box>
                      </Flex>
                    </Flex>
                    <Flex
                      mt={1}
                      justifyContent={isMobileOrTablet ? 'center' : 'flex-start'}
                      flexDirection={isMobileOrTablet ? 'column' : 'row'}
                      alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                    >
                      <Box>
                        <Body2 size='1rem' weight={600}>
                          {`${ls.samplingTime}:`}
                        </Body2>
                      </Box>
                      <Box mt={isMobileOrTablet ? 1 : 0} ml={isMobileOrTablet ? 0 : 1}>
                        <Box width={190}>
                          <SamplingTimeSelect
                            initialVal={props.values.samplingTime || undefined}
                            onChange={(min) => {
                              props.setFieldValue('samplingTime', min);
                            }}
                          />
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </Box>
              {/* <Flex
                justifyContent='flex-start'
                flexDirection={isMobileOrTablet ? 'column' : 'row'}
                alignItems={isMobileOrTablet ? 'flex-start' : undefined}
                mt={3}
              >
                <Flex
                  justifyContent='flex-start'
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                >
                  <Box>
                    <Body2 size='1.1rem' weight={600}>
                      {`${ls.samplingTime}:`}
                    </Body2>
                  </Box>
                  <Box ml={isMobileOrTablet ? 0 : 1} mt={isMobileOrTablet ? 1 : 0}>
                    <Box width={190}>
                      <SamplingTimeSelect
                        initialVal={props.values.samplingTime || undefined}
                        onChange={(min) => {
                          props.setFieldValue('samplingTime', min);
                        }}
                      />
                    </Box>
                  </Box>
                </Flex>
                <Flex
                  justifyContent='flex-start'
                  ml={isMobileOrTablet ? 0 : 4}
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                  mt={isMobileOrTablet ? 0.5 : 0}
                >
                  <Box>
                    <Body2 size='1.1rem' weight={600}>
                      {`${ls.timeZone}:`}
                    </Body2>
                  </Box>
                  <Box ml={isMobileOrTablet ? 0 : 1} mt={isMobileOrTablet ? -0.5 : -1} width={200}>
                    <SelectTimezoneMaterialUi
                      timezoneName={props.values.timezone}
                      onChange={(tz) => {
                        console.log(props.values.timezone, tz);
                        props.setFieldValue('timezone', tz);
                      }}
                    />
                  </Box>
                </Flex>
              </Flex> */}
              <Flex mt={2} justifyContent='flex-start'>
                <SteielButton
                  sx={{ height: 35 }}
                  onClick={() => {
                    props.setFieldValue('modules', [
                      ...props.values.modules,
                      {
                        mac: '',
                        name: '',
                        tool: '',
                        type: MachineType.Unknown,
                        modelVersion: '',
                      },
                    ]);
                  }}
                >
                  <Center>
                    <Center flex={1} height='100%'>
                      <Box
                        bgcolor='#fff'
                        width={14}
                        border='1px solid'
                        borderColor='#fff'
                        borderRadius={2}
                        height={2}
                        position='relative'
                      >
                        <Box
                          position='absolute'
                          top={-7}
                          left={5}
                          bgcolor='#fff'
                          height={14}
                          border='1px solid'
                          borderColor='#fff'
                          borderRadius={2}
                          width={2}
                        />
                      </Box>
                    </Center>
                    <Box ml={1}>
                      <Body1 color='white'>{ls.newModule}</Body1>
                    </Box>
                  </Center>
                </SteielButton>
                <IconButton
                  style={{ marginLeft: 2 }}
                  onClick={() => {
                    props.setFieldValue(
                      'modules',
                      modulesRef.current.map((m) => {
                        return {
                          mac: m?.mac || '',
                          name: m?.name || '',
                          type: m?.type || MachineType.Unknown,
                          tool: m?.tool || '',
                          modelVersion: m?.modelVersion || '',
                        };
                      }) || [],
                    );
                    setState({ tableKey: Date.now() });
                  }}
                >
                  <RefreshIcon
                    color='primary'
                    style={{ color: isDarkMode ? colors.lightBlue : undefined }}
                  />
                </IconButton>
              </Flex>
              <Box mt={2} zIndex={0}>
                <ModulesTable
                  key={state.tableKey}
                  macOptions={state.modulesOptions}
                  modules={props.values.modules}
                  setFieldValue={props.setFieldValue}
                  // onChange={(index, data) => {
                  //   setModulesState((prevState) => {
                  //     const newState = [...prevState];
                  //     newState[index] = { ...newState[index], ...data };
                  //     return newState;
                  //   });
                  // }}
                  // onDelete={(index) => {
                  //   setModulesState((prevState) => {
                  //     const newState = [...prevState];
                  //     newState.splice(index, 1);
                  //     return newState;
                  //   });
                  // }}
                />
              </Box>
              {firstError ? (
                <ErrorMessage
                  containerStyle={{
                    my: 2,
                  }}
                  message={firstError}
                />
              ) : (
                <Box />
              )}
              {props.isSubmitting && (
                <Box my={2}>
                  <LinearProgress />
                </Box>
              )}
              <Flex mt={3} mb={2}>
                <Box flex={1} />
                <Box width={150}>
                  <SteielButton
                    text={isNewDevice ? ls.create : ls.apply}
                    type='submit'
                    disabled={props.isSubmitting}
                    fullWidth
                    style={{
                      backgroundColor: colors.green,
                      borderColor: colors.green,
                    }}
                  />
                </Box>
              </Flex>
            </Form>
          );
        }}
      </Formik>
      <SteielDialog
        title={capitalize(ls.changesCompleted, false)}
        content={
          isNewDevice
            ? capitalize(ls.deviceCreatedSuccessfully)
            : capitalize(ls.dataSavedSuccessfully)
        }
        onClose={() => {
          setState({ showSuccessDialog: false });
          if (isNewDevice) {
            navigate(PageName.devicesManagement);
          }
        }}
        onOkActionClick={() => {
          setState({ showSuccessDialog: false });
          if (isNewDevice) {
            navigate(PageName.devicesManagement);
          }
        }}
        open={state.showSuccessDialog}
        okLabel={capitalize(ls.ok)}
      />
      <SteielDialog
        title={capitalize(ls.attention)}
        content={capitalize(ls.deleteDeviceQuestion)}
        onClose={() => {
          setState({ showDeleteDeviceDialog: false });
        }}
        onOkActionClick={() => {
          setState({ showDeleteDeviceDialog: false });
          deleteDevice();
        }}
        onKoActionClick={() => {
          setState({ showDeleteDeviceDialog: false });
        }}
        open={state.showDeleteDeviceDialog}
      />
    </Box>
  );
};

export default EditDeviceForm;
