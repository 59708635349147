import React from 'react';

export const useForceUpdate = (msInterval: number = 0) => {
  const [, setForceUpdate] = React.useState(false);

  React.useEffect(() => {
    let interval: any;
    if (msInterval > 0) {
      interval = setInterval(forceUpdate, msInterval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [msInterval]);

  const forceUpdate = () => {
    setForceUpdate((d) => !d);
  };

  return {
    forceUpdate,
  };
};
