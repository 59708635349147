import { useApolloClient } from '@apollo/client';
import React from 'react';
import { Device, useDeviceQuery } from '../graphql/codegen/graphql-react';
import { ModuleSettingsType } from '../graphql/dataTypes';

export const useDevice = (deviceId: string) => {
  const apolloClient = useApolloClient();
  const {
    loading: deviceLoading,
    error: deviceError,
    data: deviceData,
    refetch: deviceRefetch,
    startPolling: deviceStartPolling,
    stopPolling: deviceStopPolling,
  } = useDeviceQuery({
    variables: { input: { deviceId } },
    // fetchPolicy: 'no-cache',
  });

  const device: Device = { ...((deviceData?.device || {}) as Device) };

  const getModuleById = (moduleId: string) => {
    if (!moduleId) {
      return null;
    }
    const module = device?.modules?.find((m) => m?.id === moduleId) || null;
    return module;
  };

  const getModuleIdxById = (moduleId: string) => {
    if (!moduleId) {
      return 0;
    }
    let moduleIdx = device?.modules?.findIndex((m) => m?.id === moduleId) || 0;
    if (moduleIdx < 0) {
      moduleIdx = 0;
    }
    return moduleIdx;
  };

  const getModuleSettings = (moduleId: string): ModuleSettingsType => {
    const module = getModuleById(moduleId);
    if (!module || !module.ModuleSettings || !module.ModuleSettings.settings) {
      return null;
    }
    const moduleSettings = module.ModuleSettings.settings as ModuleSettingsType;
    return moduleSettings;
  };

  const resetApolloCache = async () => {
    try {
      await apolloClient.cache.reset();
    } catch (error) {}
  };

  const evictDeviceIdCache = () => {
    apolloClient.cache.evict({ id: `Device:${deviceId}` });
    for (let i = 0; i < (device.modules || []).length; i++) {
      const deviceModule = device.modules?.[i];
      apolloClient.cache.evict({ id: `Module:${deviceModule?.id}` });
    }
  };

  return {
    device,
    deviceLoading,
    deviceError,
    deviceRefetch,
    deviceStartPolling,
    deviceStopPolling,
    getModuleById,
    getModuleIdxById,
    getModuleSettings,
    resetApolloCache,
    evictDeviceIdCache,
  };
};
