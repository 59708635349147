import React from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Box, Toolbar, useTheme } from '@mui/material';
import Logo from './Logo';
import { PageName } from '../../routes';
import { ls } from '../../i18n/translations';
import { useAuth } from '../../hook/useAuth';
import { ExitToApp as ExitToAppIcon } from '@mui/icons-material';
import { NavItem } from './DashboardLayout';
import { isHTHCompany, logErr } from '../../helpers/utils';
import { useMQ } from '../../hook/useMQ';
import { assets } from '../../helpers/assets';
import Center from './Center';
import { useGS } from '../../hook/useGS';
import LightDarkModeSwitch from './LightDarkModeSwitch';
import { useDarkMode } from '../../hook/useDarkMode';
import { colors } from '../../theme';
import { Group } from './Flex';

export const navbarHeight = 80;

const MainNavbar: React.FC = () => {
  const auth = useAuth();
  const { snap: gsSnap } = useGS();
  const { isMobile } = useMQ();
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const { isDarkMode } = useDarkMode();
  const isHTH = isHTHCompany();

  let NavBarAction: React.ReactNode = (
    // <Link to={PageName.signup} style={{ textDecoration: 'none' }}>
    //   <SteielButton
    //     text={ls.signUp}
    //     borderColor={colors.blue}
    //     style={{ backgroundColor: '#fff', borderRadius: 2 }}
    //     typographyProps={{ style: { color: theme.palette.primary.main } }}
    //   />
    // </Link>
    // dark/light mode
    // <NavItem
    //   onClick={() => {
    //     colorMode.toggleColorMode();
    //   }}
    //   Icon={themeMode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
    // />
    <LightDarkModeSwitch />
  );
  if (auth.isLogged) {
    NavBarAction = (
      <NavItem
        Icon={<ExitToAppIcon fontSize='large' />}
        textIcon={ls.logout}
        onClick={async () => {
          try {
            await auth.signOut();
            navigate(PageName.root, { replace: true });
          } catch (error) {
            logErr('error on signing out', error);
          }
        }}
      />
    );
  } else if (pathname !== PageName.root && pathname !== PageName.signin) {
    // NavBarAction = (
    //   <Link to={PageName.signin} style={{ textDecoration: 'none' }}>
    //     <SteielButton
    //       text={ls.signIn}
    //       borderColor={colors.blue}
    //       style={{ backgroundColor: '#fff', borderRadius: 2 }}
    //       typographyProps={{ style: { color: theme.palette.primary.main } }}
    //     />
    //   </Link>
    // );
  }

  if (isMobile) {
    return (
      <Center mt={isHTH ? 4 : 8} mb={-8}>
        <img
          alt='logo'
          src={isHTH ? assets.logoCycleau : gsSnap.companyLogoUrl || assets.logo}
          height={isHTH ? 120 : 90}
        />
      </Center>
    );
  }

  return (
    <AppBar elevation={0} style={{ backgroundColor: isDarkMode ? colors.darkNavBarBg : '#fff' }}>
      <Toolbar sx={{ boxShadow: isMobile ? 0 : 1 }} style={{ height: navbarHeight }}>
        <Box display='flex' width='100%' justifyContent='space-between' alignItems='center'>
          <Box mt={1} mb={0.5} ml={2}>
            <Group>
              <RouterLink to={PageName.root}>
                <Logo style={isHTH ? { height: 60 } : undefined} />
              </RouterLink>
              {isHTH ? (
                <Box ml={2} alignSelf='center' mt={1}>
                  <RouterLink to={PageName.root}>
                    <img alt='Link Cycleau' src={assets.linkCycleau} style={{ height: 44 }} />
                  </RouterLink>
                </Box>
              ) : null}
            </Group>
          </Box>
          <Box>{NavBarAction}</Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default MainNavbar;
