import React from 'react';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import DashboardLayout from '../../components/ui/DashboardLayout';
import { ls } from '../../i18n/translations';
import { Body1 } from '../../components/ui/Typography';
import { getErrorMessage, logErr } from '../../helpers/utils';
import DevicesTable from '../../components/tables/DevicesTable';
import { useMount } from 'react-use';
import { colors } from '../../theme';
import Center from '../../components/ui/Center';
import { useMQ } from '../../hook/useMQ';
import config from '../../config';
import ErrorMessage from '../../components/ErrorMessage';
import LoadingPage from '../../components/ui/LoadingPage';
import { useDevices } from '../../hook/useDevices';
import DeviceStatusIconLegend from '../../components/ui/DeviceStatusIconLegend';
import Flex from '../../components/ui/Flex';
import { OnlyOnMobile, OnlyOnTabletAndDesktop } from '../../components/ui/Responsive';
import { useDarkMode } from '../../hook/useDarkMode';

const Home: React.FC = () => {
  const { isMobile } = useMQ();
  const { devices, devicesLoading, devicesError, devicesRefetch } = useDevices();
  const [isRefetching, setIsRefetching] = React.useState(false);
  const { isDarkMode } = useDarkMode();

  useMount(() => {
    if (!config.env.isDev) {
      window.scroll(0, 0);
    }
  });

  if (devicesLoading) {
    return <LoadingPage />;
  }

  return (
    <DashboardLayout>
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={getErrorMessage(devicesError)}
      />
      <Box
        mt={0}
        bgcolor={
          isMobile
            ? isDarkMode
              ? colors.darkBackground
              : 'transparent'
            : isDarkMode
            ? colors.darkBackground2
            : 'white'
        }
        pb={1}
        sx={{ boxShadow: isMobile ? 0 : 1 }}
      >
        <Box
          py={isMobile ? 0 : 2}
          px={isMobile ? 2 : 4}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box flex={1}>
            <Body1
              size='1.1rem'
              style={{ fontWeight: 600 }}
              color={isDarkMode ? colors.lightBlue : undefined}
            >
              {ls.deviceStatus}
            </Body1>
          </Box>
          <Flex>
            <OnlyOnTabletAndDesktop mr={2}>
              <DeviceStatusIconLegend />
            </OnlyOnTabletAndDesktop>
            <IconButton
              disabled={isRefetching}
              onClick={async () => {
                try {
                  setIsRefetching(true);
                  await devicesRefetch();
                } catch (error) {
                  logErr('error on refetch devices', error);
                } finally {
                  setIsRefetching(false);
                }
              }}
            >
              {isRefetching ? (
                <Center height={22} width={22}>
                  <CircularProgress size={20} color={isDarkMode ? 'secondary' : undefined} />
                </Center>
              ) : (
                <Center height={22} width={22}>
                  <RefreshIcon style={{ color: isDarkMode ? colors.lightBlue : colors.blue }} />
                </Center>
              )}
            </IconButton>
          </Flex>
        </Box>
        <OnlyOnMobile px={2} pt={1}>
          <Flex>
            <Center>
              <DeviceStatusIconLegend />
            </Center>
          </Flex>
        </OnlyOnMobile>
        {!isMobile ? <Box className='separator' /> : null}
        <Box mt={isMobile ? 3 : 1} mx={isMobile ? 2 : undefined}>
          <DevicesTable devices={devices} />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Home;
