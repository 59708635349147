import React from 'react';
import DashboardLayout from '../../components/ui/DashboardLayout';
import { PageName } from '../../routes';
import { ls } from '../../i18n/translations';
import { Box } from '@mui/material';
import Breadcrumbs from '../../components/ui/Breadcrumbs';
import EditDeviceForm from '../../components/forms/EditDeviceForm';
import { useMQ } from '../../hook/useMQ';
import { useMount } from 'react-use';
import config from '../../config';

const NewDevice: React.FC = () => {
  const { isMobileOrTablet } = useMQ();

  useMount(() => {
    if (!config.env.isDev) {
      window.scroll(0, 0);
    }
  });

  return (
    <DashboardLayout>
      <Box p={2} pt={isMobileOrTablet ? 0 : 2}>
        <Breadcrumbs
          //  comeBackLink={PageName.devicesManagement}
          backPageName={ls.deviceManagement}
        />
      </Box>
      <Box mx={isMobileOrTablet ? 2 : 0}>
        <EditDeviceForm isNewDevice />
      </Box>
    </DashboardLayout>
  );
};

export default NewDevice;
