import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ls } from '../../i18n/translations';
import { Body2 } from '../ui/Typography';
import { colors } from '../../theme';
import { useLocation, useNavigate } from 'react-router-dom';
import SteielCheckbox from '../ui/SteielCheckBox';

const rows: {
  name: string;
  sendEmail: boolean;
}[] = Array(3)
  .fill(0)
  .map((el, i) => {
    return {
      name: `s0${i + 1}_00${String.fromCharCode(97 + i)}`,
      sendEmail: Math.random() > 0.5,
    };
  });

const NotificationsTable: React.FC = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const deviceId = new URLSearchParams(search).get('id') || '-';

  return (
    <TableContainer style={{ height: 450 }}>
      <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell>
              <Body2 weight={600} color={colors.grey}>
                {ls.name.toUpperCase()}
              </Body2>
            </TableCell>
            <TableCell align='center'>
              <Body2 weight={600} color={colors.grey}>
                {ls.sendEmail.toUpperCase()}
              </Body2>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component='th' scope='row'>
                <Body2>{row.name}</Body2>
              </TableCell>
              <TableCell align='center'>
                <SteielCheckbox checked={Math.random() > 0.5} label='' onChangeValue={(v) => {}} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NotificationsTable;
