import React from 'react';
import { useTheme } from '@mui/material';
import { ColorModeContext } from '../App';

export const useDarkMode = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const themeMode = theme.palette.mode;

  const isDarkMode = themeMode === 'dark';

  const enableDarkMode = () => {
    if (isDarkMode) {
      return;
    }
    colorMode.toggleColorMode();
  };

  const disableDarkMode = () => {
    if (isDarkMode) {
      colorMode.toggleColorMode();
    }
  };

  return {
    toggleDarkMode: colorMode.toggleColorMode,
    isDarkMode,
    enableDarkMode,
    disableDarkMode,
  };
};
