import React from 'react';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { ls } from '../i18n/translations';
import SteielButton from './ui/SteielButton';
import { Body1, Body2 } from './ui/Typography';
import { colors } from '../theme';
import { useMount, useSetState } from 'react-use';
import Center from './ui/Center';
import Flex from './ui/Flex';
import { assets, CustomSvgIcon } from '../helpers/assets';
import InterfaceDetail from './InterfaceDetail';
import TableDetail from './TableDetail';
import ChartsDetail from './ChartsDetail';
import AlarmsDetail from './AlarmsDetail';
import { useNavigate } from 'react-router-dom';
import { PageName } from '../routes';
import { useGS } from '../hook/useGS';
import {
  capitalize,
  dayjs,
  getErrorMessage,
  getInterfaceName,
  isModuleInAlarm,
  minMaxCap,
  parseUnitOfMeasure,
  isModuleInCommErr,
  wait,
} from '../helpers/utils';
import { useMQ } from '../hook/useMQ';
import {
  OnlyOnDesktop,
  OnlyOnMobile,
  OnlyOnMobileAndTablet,
  OnlyOnTabletAndDesktop,
} from './ui/Responsive';
import { useSearchParams } from '../hook/useSearchParams';
import { useDevice } from '../hook/useDevice';
import ErrorMessage from './ErrorMessage';
import ParametersDetail from './ParametersDetail';
import DeviceIconStatus from './DeviceIconStatus';
import { useAuth } from '../hook/useAuth';
import NotificationsDetail from './NotificationsDetail';
import { useForceUpdate } from '../hook/useForceUpdate';
import { MachineType } from '../graphql/codegen/graphql-react';
import { useDarkMode } from '../hook/useDarkMode';

export const measurementsColors = [
  colors.lightBlue,
  colors.green,
  colors.blue,
  colors.orange,
  colors.stroke,
  colors.stroke3,
  colors.red,
  colors.stroke2,
];

export const measurementsColorsDarkMode = [
  colors.lightBlue,
  colors.green,
  colors.lightGrey,
  colors.orange,
  colors.stroke3,
  colors.red,
  colors.stroke4,
  colors.stroke2,
];

interface DeviceInterfaceProps {
  moduleId: string;
  showCollapseBtn?: boolean;
}

const DeviceInterface: React.FC<DeviceInterfaceProps> = ({ moduleId, showCollapseBtn = true }) => {
  const [state, setState] = useSetState<{
    activeTab: 'interface' | 'table' | 'charts' | 'alarms' | 'parameters' | 'notifications';
  }>({ activeTab: 'interface' });
  const navigate = useNavigate();
  const gs = useGS();
  const { isMobile, isMobileOrTablet } = useMQ();
  const { deviceIdParam } = useSearchParams();
  const { isDarkMode } = useDarkMode();
  const {
    device,
    deviceError,
    deviceLoading,
    getModuleById,
    getModuleIdxById,
    getModuleSettings,
    deviceRefetch,
  } = useDevice(deviceIdParam);
  const [parametersDetailKey, setParametersDetailKey] = React.useState(Date.now());
  const auth = useAuth();
  useForceUpdate(6000);

  useMount(async () => {
    await deviceRefetch();
  });

  if (deviceLoading) {
    return (
      <Center mt={1}>
        <CircularProgress />
      </Center>
    );
  }

  const deviceModule = getModuleById(moduleId);
  const moduleSettings = getModuleSettings(moduleId);
  const isModuleCommErr = isModuleInCommErr(deviceModule);
  const deviceStatus = deviceModule?.online
    ? isModuleInAlarm(deviceModule)
      ? ls.onlineWithAlarm
      : ls.online
    : ls.offline;

  let TabComponent: React.ReactNode = null;
  let actionBtnText = '';
  let actionBtnIcon = '';
  let actionBtn = () => {};
  switch (state.activeTab) {
    case 'interface':
      TabComponent = <InterfaceDetail module={deviceModule} />;
      actionBtnText = capitalize(ls.customizeMeasurements, false);
      actionBtnIcon = assets.pencil;
      actionBtn = () => {
        navigate(
          PageName.deviceMeasurements.replace('{0}', deviceIdParam).replace('{1}', `${moduleId}`),
        );
      };
      break;
    case 'table':
      TabComponent = <TableDetail module={deviceModule} />;
      break;
    case 'charts':
      TabComponent = <ChartsDetail module={deviceModule} />;
      break;
    case 'alarms':
      TabComponent = <AlarmsDetail module={deviceModule} />;
      break;
    case 'parameters':
      TabComponent = (
        <ParametersDetail
          key={parametersDetailKey}
          module={deviceModule}
          onParameterSavedSuccessfully={async () => {
            // deviceRefetch();
          }}
        />
      );
      break;
    case 'notifications':
      TabComponent = (
        <NotificationsDetail
          module={deviceModule}
          onNotifSavedSuccessfully={() => {
            deviceRefetch();
          }}
        />
      );
      break;

    default:
      break;
  }

  const isSomeSettingsEnabled = moduleSettings?.some((el) => el?.enabled);

  const LastConn: React.ReactNode = (
    <Body2 ml={isMobile ? 0 : 8} px={isMobile ? 2 : 0} size='1.1rem'>{`${capitalize(
      ls.lastConnection,
    )}: ${dayjs(deviceModule?.lastConnection).format('YYYY-MM-DD hh:mm')}`}</Body2>
  );

  return (
    <Box
      bgcolor={isDarkMode ? colors.darkBackground2 : 'white'}
      borderRadius={3}
      sx={{ boxShadow: 1 }}
      mx={isMobile ? 2 : 0}
    >
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={getErrorMessage(deviceError)}
      />
      <Flex
        p={2}
        onClick={() => {
          if (showCollapseBtn) {
            gs.state.deviceInterfaceOpen[getModuleIdxById(moduleId)] =
              !gs.snap.deviceInterfaceOpen[getModuleIdxById(moduleId)];
          }
        }}
        style={{ cursor: showCollapseBtn ? 'pointer' : 'default' }}
        flexDirection={isMobileOrTablet ? 'column' : 'row'}
        alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
        justifyContent='center'
      >
        <OnlyOnDesktop minWidth={250} width='70%'>
          <Flex justifyContent='flex-start' mt={isSomeSettingsEnabled ? 0.2 : 0.3}>
            <Body1 weight={600} size='1.1rem'>
              {ls.interface} {getInterfaceName(deviceModule)}
            </Body1>
            <Center ml={2} height={24}>
              <DeviceIconStatus deviceStatus={deviceStatus} />
            </Center>
            {deviceModule?.online ? (
              <>
                {isModuleCommErr ? (
                  <Center ml={2}>
                    <CustomSvgIcon
                      src={assets.commErr}
                      style={{ width: 75, height: 16, cursor: 'pointer' }}
                    />
                  </Center>
                ) : (
                  <Center ml={2}>
                    <CustomSvgIcon
                      src={assets.commOk}
                      style={{ width: 32, height: 16, cursor: 'pointer' }}
                    />
                  </Center>
                )}
              </>
            ) : null}
          </Flex>
        </OnlyOnDesktop>
        <Flex width='100%'>
          <Flex
            flex={1}
            width='100%'
            ml={isMobileOrTablet ? 0 : 2}
            // mt={-0.5}
            mt={isMobileOrTablet ? -0.5 : 0}
            flexWrap='wrap'
            justifyContent='flex-start'
          >
            {moduleSettings?.map((ms, i) => {
              if (!ms || !ms?.enabled) {
                return null;
              }
              const tagName = ms.name || ms.unitOfMeasure || '-';
              const bgColor =
                (isDarkMode ? measurementsColorsDarkMode[i] : measurementsColors[i]) ||
                colors.lightGrey;
              return (
                <Center
                  key={`${tagName}_${i}`}
                  bgcolor={bgColor}
                  py={isMobileOrTablet ? 0.2 : 0.7}
                  px={2}
                  mx={0.5}
                  my={0.5}
                  borderRadius={4}
                >
                  <Body2
                    color='white'
                    size={isMobileOrTablet ? '0.65rem' : '0.8rem'}
                    weight={600}
                    mt={0.2}
                    minWidth={30}
                    textAlign='center'
                  >
                    {tagName}
                  </Body2>
                </Center>
              );
            })}
            <Box flex={1} />
          </Flex>
          {showCollapseBtn ? (
            <Box
              mb={isMobileOrTablet ? -3 : 0}
              mt={
                (isMobileOrTablet ? 1 : 1.7) +
                (!isSomeSettingsEnabled ? -1.5 : 0) +
                (!isMobileOrTablet && isSomeSettingsEnabled ? -1.3 : 0)
              }
            >
              <IconButton
                onClick={() => {
                  gs.state.deviceInterfaceOpen[getModuleIdxById(moduleId)] =
                    !gs.snap.deviceInterfaceOpen[getModuleIdxById(moduleId)];
                }}
                style={{
                  rotate: gs.snap.deviceInterfaceOpen[getModuleIdxById(moduleId)]
                    ? '180deg'
                    : '0deg',
                }}
              >
                <CustomSvgIcon src={assets.interfaceDetail} onClick={() => {}} />
              </IconButton>
            </Box>
          ) : null}
        </Flex>
        <OnlyOnMobileAndTablet mt={!isSomeSettingsEnabled ? -1 : 0}>
          <Flex ml={1} justifyContent='flex-start'>
            <Body1 weight={600} size='1.1rem'>
              {ls.interface} {getInterfaceName(deviceModule)}
            </Body1>
            <Center ml={2} height={24} mr={4.5}>
              <DeviceIconStatus deviceStatus={deviceStatus} />
            </Center>
            {deviceModule?.online ? (
              <>
                {isModuleCommErr ? (
                  <Center>
                    <CustomSvgIcon
                      src={assets.commErr}
                      style={{ width: 75, height: 16, cursor: 'pointer' }}
                    />
                  </Center>
                ) : (
                  <Center>
                    <CustomSvgIcon
                      src={assets.commOk}
                      style={{ width: 32, height: 16, cursor: 'pointer' }}
                    />
                  </Center>
                )}
              </>
            ) : null}
          </Flex>
        </OnlyOnMobileAndTablet>
      </Flex>
      {gs.state.deviceInterfaceOpen[getModuleIdxById(moduleId)] && showCollapseBtn ? (
        <Flex
          flexDirection='column'
          sx={{
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            // height: minMaxCap(800, 2000, 400 + nOfSensors * 200),
          }}
        >
          <Flex
            bgcolor={isDarkMode ? '#272a42' : colors.stroke4}
            py={2}
            px={1}
            justifyContent={'flex-start'}
            width='100%'
            my={0.5}
            flexWrap='wrap'
          >
            <Box m={0.5}>
              <SteielButton
                text={ls.interface}
                style={{
                  minWidth: isMobile ? undefined : 120,
                  backgroundColor:
                    state.activeTab === 'interface'
                      ? colors.blue
                      : isDarkMode
                      ? '#080c25'
                      : 'white',
                  borderColor:
                    state.activeTab === 'interface'
                      ? colors.blue
                      : isDarkMode
                      ? '#080c25'
                      : 'white',
                  paddingLeft: 12,
                  paddingRight: 12,
                }}
                typographyProps={{
                  style: {
                    color: state.activeTab === 'interface' ? 'white' : colors.lightGrey,
                  },
                }}
                onClick={() => {
                  setState({ activeTab: 'interface' });
                }}
              />
            </Box>
            <Box m={0.5}>
              <SteielButton
                text={ls.table}
                style={{
                  minWidth: isMobile ? undefined : 120,
                  backgroundColor:
                    state.activeTab === 'table' ? colors.blue : isDarkMode ? '#080c25' : 'white',
                  borderColor:
                    state.activeTab === 'table' ? colors.blue : isDarkMode ? '#080c25' : 'white',
                  paddingLeft: 12,
                  paddingRight: 12,
                }}
                typographyProps={{
                  style: {
                    color: state.activeTab === 'table' ? 'white' : colors.lightGrey,
                  },
                }}
                onClick={() => {
                  setState({ activeTab: 'table' });
                }}
              />
            </Box>
            <Box m={0.5}>
              <SteielButton
                text={ls.charts}
                style={{
                  minWidth: isMobile ? undefined : 120,
                  backgroundColor:
                    state.activeTab === 'charts' ? colors.blue : isDarkMode ? '#080c25' : 'white',
                  borderColor:
                    state.activeTab === 'charts' ? colors.blue : isDarkMode ? '#080c25' : 'white',
                  paddingLeft: 12,
                  paddingRight: 12,
                }}
                typographyProps={{
                  style: {
                    color: state.activeTab === 'charts' ? 'white' : colors.lightGrey,
                  },
                }}
                onClick={() => {
                  setState({ activeTab: 'charts' });
                }}
              />
            </Box>
            <Box m={0.5}>
              <SteielButton
                text={ls.eventsLog}
                style={{
                  minWidth: isMobile ? undefined : 120,
                  backgroundColor:
                    state.activeTab === 'alarms' ? colors.blue : isDarkMode ? '#080c25' : 'white',
                  borderColor:
                    state.activeTab === 'alarms' ? colors.blue : isDarkMode ? '#080c25' : 'white',
                  paddingLeft: 12,
                  paddingRight: 12,
                }}
                typographyProps={{
                  style: {
                    color: state.activeTab === 'alarms' ? 'white' : colors.lightGrey,
                  },
                }}
                onClick={() => {
                  setState({ activeTab: 'alarms' });
                }}
              />
            </Box>
            {!auth.isViewer ? (
              <Box m={0.5}>
                <SteielButton
                  text={capitalize(ls.parameters)}
                  style={{
                    minWidth: isMobile ? undefined : 120,
                    backgroundColor:
                      state.activeTab === 'parameters'
                        ? colors.blue
                        : isDarkMode
                        ? '#080c25'
                        : 'white',
                    borderColor:
                      state.activeTab === 'parameters'
                        ? colors.blue
                        : isDarkMode
                        ? '#080c25'
                        : 'white',
                    paddingLeft: 12,
                    paddingRight: 12,
                  }}
                  typographyProps={{
                    style: {
                      color: state.activeTab === 'parameters' ? 'white' : colors.lightGrey,
                    },
                  }}
                  onClick={() => {
                    setState({ activeTab: 'parameters' });
                  }}
                />
              </Box>
            ) : null}
            {!auth.isViewer ? (
              <Box m={0.5}>
                <SteielButton
                  text={capitalize(ls.notifications)}
                  style={{
                    minWidth: isMobile ? undefined : 120,
                    backgroundColor:
                      state.activeTab === 'notifications'
                        ? colors.blue
                        : isDarkMode
                        ? '#080c25'
                        : 'white',
                    borderColor:
                      state.activeTab === 'notifications'
                        ? colors.blue
                        : isDarkMode
                        ? '#080c25'
                        : 'white',
                    paddingLeft: 12,
                    paddingRight: 12,
                  }}
                  typographyProps={{
                    style: {
                      color: state.activeTab === 'notifications' ? 'white' : colors.lightGrey,
                    },
                  }}
                  onClick={() => {
                    setState({ activeTab: 'notifications' });
                  }}
                />
              </Box>
            ) : null}
          </Flex>
          <Box
            flex={1}
            // sx={{ overflowY: 'auto' }}
            width='100%'
            height='100%'
          >
            {TabComponent}
          </Box>
          {auth.isViewer ? <Box height={42} /> : null}
          {actionBtnText && !auth.isViewer ? (
            <>
              <Box height={42} />
              <Box className='separator' width='100%' />
              <Flex
                py={2}
                mr={isMobile ? 0 : 8}
                width='100%'
                justifyContent={isMobile ? 'center' : 'space-between'}
              >
                <Box>
                  {!isMobile &&
                  deviceModule?.type === MachineType.NovaNsp &&
                  deviceModule?.lastConnection
                    ? LastConn
                    : null}
                </Box>
                <Box>
                  {isMobile &&
                  deviceModule?.type === MachineType.NovaNsp &&
                  deviceModule?.lastConnection ? (
                    <Center>{LastConn}</Center>
                  ) : null}
                  <Center mt={isMobile ? 1 : 0}>
                    <SteielButton sx={{ height: 35 }} onClick={actionBtn}>
                      <Center>
                        <Box mb={-1.5}>
                          <CustomSvgIcon
                            src={actionBtnIcon}
                            style={{ width: 20, height: 20 }}
                            onClick={() => {}}
                          />
                        </Box>
                        <Box ml={1}>
                          <Body1 color='white'>{actionBtnText}</Body1>
                        </Box>
                      </Center>
                    </SteielButton>
                  </Center>
                </Box>
              </Flex>
            </>
          ) : null}
        </Flex>
      ) : null}
    </Box>
  );
};

export default DeviceInterface;
