import { proxy } from 'valtio';
import { User } from '../graphql/codegen/graphql-react';

interface AuthState {
  user?: User | null;
  jwtToken?: string | null;
}

export const initialAuthState: AuthState = {
  jwtToken: null,
  user: null,
};

export const authState = proxy<AuthState>(initialAuthState);
