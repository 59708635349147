import * as React from 'react';
import { ls } from '../../i18n/translations';
import { dayjs, isDeviceInAlarm } from '../../helpers/utils';
import { PageName } from '../../routes';
import { Link, useNavigate } from 'react-router-dom';
import Center from '../ui/Center';
import { colors } from '../../theme';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { defaultMUIDataTableOptions } from '../../helpers/datatableUtils';
import { Device } from '../../graphql/codegen/graphql-react';
import DeviceIconStatus from '../DeviceIconStatus';
import SteielButton from '../ui/SteielButton';
import { useDarkMode } from '../../hook/useDarkMode';

const options: MUIDataTableOptions = {
  ...defaultMUIDataTableOptions,
};
interface DevicesTableProps {
  devices: Device[];
  isDeviceManagement?: boolean;
}

const DevicesTable: React.FC<DevicesTableProps> = ({ devices, isDeviceManagement }) => {
  const { isDarkMode } = useDarkMode();
  const navigate = useNavigate();
  const dateTimeFile = dayjs().format('YYYY-MM-DD_HH_mm');

  const localStorageRowsPerPageKey = isDeviceManagement
    ? 'devicesmanagement_rowsPerPage'
    : 'devices_rowsPerPage';

  const tableDevices = ([...(devices || [])].map((d, i) => {
    const isSomeOffline = d.modules?.some((el) => !el?.online);
    const inAlarm = isDeviceInAlarm(d) || isSomeOffline;
    const isOnline = d.modules?.some((el) => el?.online);
    let lastConnection =
      d.modules
        ?.map((el) => el?.lastConnection)
        .sort()
        ?.reverse()?.[0] || '';
    lastConnection =
      lastConnection && !isOnline ? dayjs(lastConnection).format('YYYY-MM-DD HH:mm') : '';

    return {
      id: d?.id || `${i}`,
      name: d?.name || '',
      place: d?.place || '',
      status: isOnline ? (inAlarm ? ls.onlineWithAlarm : ls.online) : ls.offline,
      company: d?.Company?.name || '',
      lastConnection,
    };
  }) || []) as {
    id: string;
    name: string;
    place: string;
    status: string;
    company: string;
    lastConnection: string;
  }[];

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'status',
      label: ls.status.toUpperCase(),
      options: {
        filter: true,
        sort: true,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          return <DeviceIconStatus deviceStatus={value} />;
        },
      },
    },
    {
      name: 'name',
      label: ls.name.toUpperCase(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'company',
      label: ls.company.toUpperCase(),
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: 'dateAndTime',
    //   label: ls.dateAndTime.toUpperCase(),
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    {
      name: 'place',
      label: ls.place.toUpperCase(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'lastConnection',
      label: ls.lastConnection.toUpperCase(),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id',
      label: ls.details.toUpperCase(),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        download: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Center justifyContent={'flex-start'}>
              <Link
                style={{ textDecoration: 'none' }}
                to={
                  isDeviceManagement
                    ? PageName.editDevice.replace('{0}', value)
                    : PageName.device.replace('{0}', value)
                }
              >
                <SteielButton
                  text={ls.open.toUpperCase()}
                  // size='small'
                  style={{
                    backgroundColor: 'transparent',
                    borderColor: isDarkMode ? colors.lightGrey : colors.blue,
                    height: 28,
                  }}
                  typographyProps={{
                    style: { color: isDarkMode ? colors.lightGrey : colors.blue },
                    size: '0.9rem',
                    mt: 0.2,
                  }}
                />
                {/* <CustomSvgIcon
                  src={isDeviceManagement ? assets.settingsBlue : assets.info}
                  style={{
                    width: isDeviceManagement ? 24 : 18,
                    height: isDeviceManagement ? 24 : 18,
                    marginBottom: isDeviceManagement ? -8 : -5,
                  }}
                  onClick={() => {}}
                /> */}
              </Link>
            </Center>
          );
        },
      },
    },
  ];

  return (
    <MUIDataTable
      title=''
      data={tableDevices}
      columns={columns}
      options={{
        ...options,
        downloadOptions: {
          filename: `Devices_${dateTimeFile}.csv`,
        },
        rowsPerPage: localStorage.getItem(localStorageRowsPerPageKey)
          ? Number(localStorage.getItem(localStorageRowsPerPageKey))
          : 10,
        onChangeRowsPerPage: (numberOfRows) => {
          localStorage.setItem(localStorageRowsPerPageKey, `${numberOfRows}`);
        },
      }}
    />
  );
};

export default DevicesTable;
