import React from 'react';
import DashboardLayout from '../../components/ui/DashboardLayout';
import { useNavigate } from 'react-router-dom';
import { Body2 } from '../../components/ui/Typography';
import { ls } from '../../i18n/translations';
import { Box } from '@mui/material';
import { capitalize, getErrorMessage, logErr } from '../../helpers/utils';
import Center from '../../components/ui/Center';
import DeviceInterface from '../../components/DeviceInterface';
import { useMount, useSetState, useUnmount } from 'react-use';
import { useMQ } from '../../hook/useMQ';
import config from '../../config';
import { useDevice } from '../../hook/useDevice';
import ErrorMessage from '../../components/ErrorMessage';
import LoadingPage from '../../components/ui/LoadingPage';
import { useSearchParams } from '../../hook/useSearchParams';
import DeviceDetailInfo from '../../components/DeviceDetailInfo';
import { useGS } from '../../hook/useGS';

const DeviceDetail: React.FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useMQ();
  const [state, setState] = useSetState<{
    showDefaultWarn: boolean;
    errorFromServer: any;
    resettingSettings: boolean;
  }>({
    showDefaultWarn: false,
    errorFromServer: '',
    resettingSettings: false,
  });
  const { deviceIdParam } = useSearchParams();
  const {
    device,
    deviceError,
    deviceLoading,
    deviceStartPolling,
    deviceStopPolling,
    deviceRefetch,
  } = useDevice(deviceIdParam);

  useMount(() => {
    if (!config.env.isDev) {
      window.scroll(0, 0);
    }
    deviceStartPolling(5000);
  });

  useUnmount(() => {
    deviceStopPolling();
  });

  if (deviceLoading) {
    return <LoadingPage />;
  }

  return (
    <DashboardLayout>
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={getErrorMessage(deviceError || state.errorFromServer)}
      />
      <DeviceDetailInfo
        backPageName={capitalize(ls.deviceStatus)}
        device={device}
        onResetDone={() => {
          deviceRefetch().catch((err) => {
            logErr(err);
          });
        }}
      />
      {device.modules && device.modules.length > 0 ? (
        <Box mt={isMobile ? 3 : 6}>
          {device.modules?.map((m, i) => {
            return (
              <Box key={m?.id || i}>
                <Box mt={i !== 0 ? 1.5 : 0}>
                  <DeviceInterface moduleId={m?.id || 'n/a'} />
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : (
        <Center mt={4}>
          <Body2 size='1.1rem' textAlign='center'>
            {capitalize(ls.noInterfaceConnected)}
          </Body2>
        </Center>
      )}
    </DashboardLayout>
  );
};

export default DeviceDetail;
