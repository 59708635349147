import validator from 'validator';

interface ValidationOutput {
  isValid: boolean;
  errorTranslationKey: string;
}

export const PASSWORD_MIN_LENGTH = 6;

export const validateEmail = (email: string): ValidationOutput => {
  if (!email || email.trim().length === 0) {
    return { errorTranslationKey: 'fieldRequired', isValid: false };
  }
  if (!validator.isEmail(email)) {
    return { errorTranslationKey: 'invalidEmail', isValid: false };
  }
  return { isValid: true, errorTranslationKey: '' };
};

export const validatePassword = (password: string, min = PASSWORD_MIN_LENGTH): ValidationOutput => {
  if (!password || password.trim().length === 0) {
    return { errorTranslationKey: 'fieldRequired', isValid: false };
  }
  if (password.length < min) {
    return {
      errorTranslationKey: 'passwordTooShort',
      isValid: false,
    };
  }
  return { isValid: true, errorTranslationKey: '' };
};

export const validateRequiredField = (fieldValue: string): ValidationOutput => {
  if (!fieldValue || fieldValue.trim().length === 0) {
    return { errorTranslationKey: 'fieldRequired', isValid: false };
  }
  return { isValid: true, errorTranslationKey: '' };
};
