import React from 'react';
import DashboardLayout from '../../components/ui/DashboardLayout';
import { PageName } from '../../routes';
import Flex from '../../components/ui/Flex';
import { Body1, Body2 } from '../../components/ui/Typography';
import { ls } from '../../i18n/translations';
import { Box } from '@mui/material';
import { colors } from '../../theme';
import { capitalize } from '../../helpers/utils';
import Breadcrumbs from '../../components/ui/Breadcrumbs';
import NotificationsTable from '../../components/tables/NotificationsTable';
import { useMQ } from '../../hook/useMQ';
import { useMount } from 'react-use';
import config from '../../config';

const Notifications: React.FC = () => {
  const { isMobile } = useMQ();

  useMount(() => {
    if (!config.env.isDev) {
      window.scroll(0, 0);
    }
  });

  return (
    <DashboardLayout>
      <Box p={2} pt={isMobile ? 0 : 2}>
        <Breadcrumbs
          // comeBackLink={PageName.customers}
          backPageName={ls.managerList}
        />
        <Box mt={2} ml={3.5}>
          <Flex justifyContent='flex-start'>
            <Box>
              <Body2 size='1.1rem' weight={600}>
                {`${capitalize(ls.name)}:`}
              </Body2>
            </Box>
            <Box ml={0.5}>
              <Body2 size='1.1rem' color={colors.stroke}>
                {'Test Piscine'}
              </Body2>
            </Box>
          </Flex>
          <Flex justifyContent='flex-start'>
            <Box>
              <Body2 size='1.1rem' weight={600}>
                {`${capitalize(ls.locality)}:`}
              </Body2>
            </Box>
            <Box ml={0.5}>
              <Body2 size='1.1rem' color={colors.stroke}>
                {'Ponte San Nicolò - PD'}
              </Body2>
            </Box>
          </Flex>
        </Box>
        <Box mt={4} pb={2} bgcolor='white' borderRadius={1} sx={{ boxShadow: 1 }}>
          <Box p={2}>
            <Body1 weight={600} size={'1.2rem'}>
              {ls.manageNotifications}
            </Body1>
          </Box>
          <Box className='separator' width='100%' />
          <Box mt={2}>
            <NotificationsTable />
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Notifications;
