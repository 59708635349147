import React from 'react';
import DeviceInterfaceChart from './charts/DeviceInterfaceChart';
import { OnlyOnMobile, OnlyOnTabletAndDesktop } from './ui/Responsive';
import { Body2 } from './ui/Typography';
import { Box, CircularProgress, IconButton, TextField } from '@mui/material';
import {
  Maybe,
  MeasurementHistory,
  Module,
  useMeasurementsHistoryQuery,
} from '../graphql/codegen/graphql-react';
import { capitalize, dayjs, getErrorMessage, logErr } from '../helpers/utils';
import ErrorMessage from './ErrorMessage';
import Center from './ui/Center';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSetState } from 'react-use';
import { ls } from '../i18n/translations';
import Flex from './ui/Flex';
import { useMQ } from '../hook/useMQ';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { colors } from '../theme';
import DeviceAlarmsTable from './tables/DeviceAlarmsTable';
import { MeasurementHistoryType, ModuleSettingType } from '../graphql/dataTypes';
import SteielSelect from './ui/SteielSelect';
import SteielCheckbox from './ui/SteielCheckBox';
import clone from 'just-clone';
import { measurementsColors, measurementsColorsDarkMode } from './DeviceInterface';
import { Memo } from '@legendapp/state/react';
import { useDarkMode } from '../hook/useDarkMode';

interface ChartsDetailProps {
  module: Maybe<Module>;
}

const ChartsDetail: React.FC<ChartsDetailProps> = ({ module }) => {
  const {
    loading: measurementHistoryLoading,
    error: measurementHistoryError,
    data: measurementHistoryData,
    refetch: measurementHistoryRefetch,
  } = useMeasurementsHistoryQuery({ variables: { input: { moduleId: module?.id || 'n/a' } } });
  const [state, setState] = useSetState<{
    from: dayjs.Dayjs | null;
    to: dayjs.Dayjs | null;
    isRefetching: boolean;
    measuresFilter: boolean[];
    chartRootKey: number;
  }>({
    from: null,
    to: null,
    isRefetching: false,
    measuresFilter: Array(50).fill(true),
    chartRootKey: Date.now(),
  });
  const { isMobile, isMobileOrTablet } = useMQ();
  const { isDarkMode } = useDarkMode();

  if (!module) {
    return null;
  }

  if (measurementHistoryLoading) {
    return (
      <Center mt={2} mb={6}>
        <CircularProgress />
      </Center>
    );
  }

  const moduleSettings = (module.ModuleSettings?.settings || []) as ModuleSettingType[];
  const measurementsHistoryData = (measurementHistoryData?.measurementsHistory ||
    []) as MeasurementHistory[];

  const allowedChartSelectValues: { name: string; measureIdx: number }[] = moduleSettings
    .map((el, i) => {
      if (el?.enabled) {
        return { name: `${capitalize(ls.measure)} ${i + 1} (${el.name})`, measureIdx: i + 1 };
      }
      return null;
    })
    .filter((el) => el) as { name: string; measureIdx: number }[];
  const getChartData = (
    measuresFilter?: boolean[],
  ): (MeasurementHistoryType & { dt?: string })[] => {
    const data: (MeasurementHistoryType & { dt?: string })[] = measurementsHistoryData
      .map((el) => ({
        ...el.measurements,
        dt: el.createdAt ? dayjs(el.createdAt).format('YYYY-MM-DD HH:mm') : undefined,
      }))
      .filter((e) => e.dt)
      .reverse();
    if (measuresFilter) {
      return data.map((el) => {
        const tempObj = {};
        for (let j = 0; j < allowedChartSelectValues.length; j++) {
          const allowedChartSelectValue = allowedChartSelectValues[j];
          if (measuresFilter[allowedChartSelectValue.measureIdx]) {
            // @ts-ignore
            tempObj[`measurement${allowedChartSelectValue.measureIdx}`] =
              // @ts-ignore
              el[`measurement${allowedChartSelectValue.measureIdx}`];
          }
        }
        return {
          dt: el.dt,
          ...tempObj,
        };
      });
    }
    return data;
  };

  return (
    <Box>
      <Box m={3}>
        <ErrorMessage
          containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
          message={getErrorMessage(measurementHistoryError)}
        />
        <Box ml={isMobile ? 0 : 6}>
          {/* <Body2 size={'1.1rem'} weight={600}>
            {ls.deviceInterfaceChartTitle}
          </Body2> */}
          <OnlyOnTabletAndDesktop mt={1}>
            <Body2>{ls.deviceInterfaceChartTip}</Body2>
          </OnlyOnTabletAndDesktop>
          <OnlyOnMobile mt={1}>
            <Body2 color={colors.orange}>{ls.deviceInterfaceChartMobileTip}</Body2>
          </OnlyOnMobile>
        </Box>
      </Box>
      <Flex alignItems='flex-start' mt={0.5}>
        <Flex
          ml={2}
          justifyContent={isMobileOrTablet ? 'center' : 'flex-start'}
          alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
          flexDirection={isMobileOrTablet ? 'column' : 'row'}
        >
          <Box>
            <DatePicker
              renderInput={(props) => <TextField size='small' {...props} />}
              label={capitalize(ls.from)}
              value={state.from}
              onChange={(newValue) => {
                setState({ from: newValue });
                measurementHistoryRefetch({
                  input: {
                    moduleId: module?.id || 'n/a',
                    from: newValue ? newValue.toDate() : undefined,
                    to: state.to ? state.to.toDate() : undefined,
                  },
                })
                  .then(() => {
                    setState({ chartRootKey: Date.now() });
                  })
                  .catch((err) => {
                    logErr(err);
                  });
              }}
              disableFuture
              inputFormat='YYYY/MM/DD'
              InputProps={{
                style: {
                  fontSize: '1.1rem',
                },
              }}
            />
          </Box>
          <Box mt={isMobileOrTablet ? 1 : 0} ml={isMobileOrTablet ? 0 : 4}>
            <DatePicker
              renderInput={(props) => <TextField size='small' {...props} />}
              label={capitalize(ls.to)}
              value={state.to}
              onChange={(newValue) => {
                setState({ to: newValue });
                measurementHistoryRefetch({
                  input: {
                    moduleId: module?.id || 'n/a',
                    from: state.from ? state.from.toDate() : undefined,
                    to: newValue ? newValue.toDate() : undefined,
                  },
                })
                  .then(() => {
                    setState({ chartRootKey: Date.now() });
                  })
                  .catch((err) => {
                    logErr(err);
                  });
              }}
              inputFormat='YYYY/MM/DD'
              disableFuture
              InputProps={{
                style: {
                  fontSize: '1.1rem',
                },
              }}
            />
          </Box>
          <Box mt={isMobileOrTablet ? 1 : 0} ml={isMobileOrTablet ? 0 : 4}>
            <Flex
              justifyContent={isMobileOrTablet ? 'center' : 'flex-start'}
              alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
              flexDirection={isMobileOrTablet ? 'column' : 'row'}
            >
              <Flex
                flexWrap='wrap'
                mt={isMobileOrTablet ? 1 : 0.5}
                ml={isMobileOrTablet ? 0 : 2}
                justifyContent={'flex-start'}
                alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
              >
                {/* <SteielSelect
                  allowedValues={allowedChartSelectValues}
                  onChange={(value) => {
                    const index = allowedChartSelectValues.findIndex((el) => el === value);
                    setState({ measuresFilter: index });
                  }}
                  initialVal={capitalize(ls.all)}
                /> */}
                {allowedChartSelectValues.map((el, i) => {
                  return (
                    <Box key={i}>
                      <SteielCheckbox
                        label={allowedChartSelectValues[i].name}
                        checked={state.measuresFilter[allowedChartSelectValues[i].measureIdx]}
                        labelProps={{ weight: 400 }}
                        checkedColor={
                          isDarkMode
                            ? measurementsColorsDarkMode[allowedChartSelectValues[i].measureIdx - 1]
                            : measurementsColors[allowedChartSelectValues[i].measureIdx - 1]
                        }
                        onChangeValue={() => {
                          setState((prevState) => {
                            const newMeasuresFilter = clone(prevState.measuresFilter);
                            newMeasuresFilter[allowedChartSelectValues[i].measureIdx] =
                              !newMeasuresFilter?.[allowedChartSelectValues[i].measureIdx];
                            return {
                              ...prevState,
                              measuresFilter: newMeasuresFilter,
                              chartRootKey: Date.now(),
                            };
                          });
                        }}
                      />
                    </Box>
                  );
                })}
              </Flex>
            </Flex>
          </Box>
        </Flex>
        <Box mx={2}>
          <IconButton
            disabled={state.isRefetching}
            onClick={async () => {
              try {
                setState({ isRefetching: true });
                await measurementHistoryRefetch({
                  input: {
                    moduleId: module?.id || 'n/a',
                    from: state.from ? state.from.toDate() : undefined,
                    to: state.to ? state.to.toDate() : undefined,
                  },
                });
                setState({ chartRootKey: Date.now() });
              } catch (error) {
                logErr('error on refetch measurementHistory', error);
              } finally {
                setState({ isRefetching: false });
              }
            }}
          >
            {state.isRefetching ? (
              <Center height={22}>
                <CircularProgress size={20} />
              </Center>
            ) : (
              <Center height={22}>
                <RefreshIcon style={{ color: isDarkMode ? colors.lightBlue : colors.blue }} />
              </Center>
            )}
          </IconButton>
        </Box>
      </Flex>
      <Center mt={4} mb={6}>
        <Memo key={state.chartRootKey}>
          {() => (
            <DeviceInterfaceChart
              data={getChartData(state.measuresFilter)}
              moduleSettings={moduleSettings}
              measuresFilter={state.measuresFilter}
            />
          )}
        </Memo>
      </Center>
    </Box>
  );
};

export default ChartsDetail;
