import React from 'react';
import DashboardLayout from '../../components/ui/DashboardLayout';
import { useNavigate } from 'react-router-dom';
import { PageName } from '../../routes';
import Flex from '../../components/ui/Flex';
import { Body1, Body2 } from '../../components/ui/Typography';
import { ls } from '../../i18n/translations';
import { Box, CircularProgress, IconButton, TextField } from '@mui/material';
import { capitalize, getErrorMessage, logErr } from '../../helpers/utils';
import SteielButton from '../../components/ui/SteielButton';
import Center from '../../components/ui/Center';
import Breadcrumbs from '../../components/ui/Breadcrumbs';
import UsersTable from '../../components/tables/UsersTable';
import { useMQ } from '../../hook/useMQ';
import { OnlyOnMobile, OnlyOnTabletAndDesktop } from '../../components/ui/Responsive';
import { useMount } from 'react-use';
import config from '../../config';
import ErrorMessage from '../../components/ErrorMessage';
import { useUsers } from '../../hook/useUsers';
import LoadingPage from '../../components/ui/LoadingPage';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { colors } from '../../theme';
import { useDarkMode } from '../../hook/useDarkMode';

const Users: React.FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useMQ();
  const { users, usersLoading, usersError, usersRefetch } = useUsers();
  const [isRefetching, setIsRefetching] = React.useState(false);
  const { isDarkMode } = useDarkMode();

  useMount(() => {
    if (!config.env.isDev) {
      window.scroll(0, 0);
    }
  });

  if (usersLoading) {
    return <LoadingPage />;
  }

  return (
    <DashboardLayout>
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={getErrorMessage(usersError)}
      />
      <Box p={2} pt={isMobile ? 0 : 2}>
        <Breadcrumbs
          // comeBackLink={PageName.home}
          backPageName={capitalize(ls.dashboard)}
        />
        {/* <Flex
          mt={2}
          justifyContent='flex-start'
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'flex-start' : 'center'}
        >
          <Flex justifyContent='flex-start'>
            <Box ml={isMobile ? 4 : 0}>
              <Body2 weight={600}>{`${capitalize(ls.name)}:`}</Body2>
            </Box>
            <Box ml={0.5}>
              <Body2>{'STEIEL'}</Body2>
            </Box>
          </Flex>
          <Flex ml={4} justifyContent='flex-start'>
            <Box>
              <Body2 weight={600}>{`${capitalize(ls.locality)}:`}</Body2>
            </Box>
            <Box ml={0.5}>
              <Body2>{'Ponte San Nicolò - PD'}</Body2>
            </Box>
          </Flex>
          <OnlyOnTabletAndDesktop>
            <Box ml={4}>
              <SteielButton
                sx={{ height: 35 }}
                text={ls.modifyManager}
                onClick={() => {
                  navigate(PageName.editCustomer);
                }}
              />
            </Box>
          </OnlyOnTabletAndDesktop>
        </Flex> */}
        {/* <OnlyOnMobile>
          <Flex width='100%' mt={2} flexWrap={'wrap'}>
            <Box mr={2} mb={1}>
              <SteielButton
                sx={{ height: 35 }}
                text={ls.modifyManager}
                onClick={() => {
                  navigate(PageName.editCustomer);
                }}
              />
            </Box>
            <Box mb={1}>
              <SteielButton
                sx={{ height: 35 }}
                onClick={() => {
                  navigate(PageName.newUser);
                }}
              >
                <Center>
                  <Center flex={1} height='100%'>
                    <Box
                      bgcolor='#fff'
                      width={14}
                      border='1px solid'
                      borderColor='#fff'
                      borderRadius={2}
                      height={2}
                      position='relative'
                    >
                      <Box
                        position='absolute'
                        top={-7}
                        left={5}
                        bgcolor='#fff'
                        height={14}
                        border='1px solid'
                        borderColor='#fff'
                        borderRadius={2}
                        width={2}
                      />
                    </Box>
                  </Center>
                  <Box ml={1}>
                    <Body1 color='white'>{ls.newUser}</Body1>
                  </Box>
                </Center>
              </SteielButton>
            </Box>
          </Flex>
        </OnlyOnMobile> */}
        <OnlyOnMobile>
          <Box mt={1}>
            <SteielButton
              sx={{ height: 35 }}
              onClick={() => {
                navigate(PageName.newUser);
              }}
            >
              <Center>
                <Center flex={1} height='100%'>
                  <Box
                    bgcolor='#fff'
                    width={14}
                    border='1px solid'
                    borderColor='#fff'
                    borderRadius={2}
                    height={2}
                    position='relative'
                  >
                    <Box
                      position='absolute'
                      top={-7}
                      left={5}
                      bgcolor='#fff'
                      height={14}
                      border='1px solid'
                      borderColor='#fff'
                      borderRadius={2}
                      width={2}
                    />
                  </Box>
                </Center>
                <Box ml={1}>
                  <Body1 color='white'>{ls.newUser}</Body1>
                </Box>
              </Center>
            </SteielButton>
          </Box>
        </OnlyOnMobile>
      </Box>
      <Box
        borderRadius={1}
        sx={{ boxShadow: 1 }}
        bgcolor={isDarkMode ? colors.darkBackground2 : 'white'}
        pb={2}
      >
        <Flex p={2}>
          <Box>
            <Body1 weight={600} size='1.1rem' color={isDarkMode ? colors.lightBlue : undefined}>
              {ls.usersList}
            </Body1>
          </Box>
          <OnlyOnTabletAndDesktop>
            <Center>
              <SteielButton
                sx={{ height: 35 }}
                onClick={() => {
                  navigate(PageName.newUser);
                }}
              >
                <Center>
                  <Center flex={1} height='100%'>
                    <Box
                      bgcolor='#fff'
                      width={14}
                      border='1px solid'
                      borderColor='#fff'
                      borderRadius={2}
                      height={2}
                      position='relative'
                    >
                      <Box
                        position='absolute'
                        top={-7}
                        left={5}
                        bgcolor='#fff'
                        height={14}
                        border='1px solid'
                        borderColor='#fff'
                        borderRadius={2}
                        width={2}
                      />
                    </Box>
                  </Center>
                  <Box ml={1}>
                    <Body1 color='white'>{ls.newUser}</Body1>
                  </Box>
                </Center>
              </SteielButton>
              <Box width={42}>
                <IconButton
                  disabled={isRefetching}
                  onClick={async () => {
                    try {
                      setIsRefetching(true);
                      await usersRefetch();
                    } catch (error) {
                      logErr('error on refetch users', error);
                    } finally {
                      setIsRefetching(false);
                    }
                  }}
                >
                  {isRefetching ? (
                    <Center height={22} width={22}>
                      <CircularProgress size={20} color={isDarkMode ? 'secondary' : undefined} />
                    </Center>
                  ) : (
                    <Center height={22} width={22}>
                      <RefreshIcon style={{ color: isDarkMode ? colors.lightBlue : colors.blue }} />
                    </Center>
                  )}
                </IconButton>
              </Box>
            </Center>
          </OnlyOnTabletAndDesktop>
          <OnlyOnMobile>
            <Box width={42}>
              <IconButton
                disabled={isRefetching}
                onClick={async () => {
                  try {
                    setIsRefetching(true);
                    await usersRefetch();
                  } catch (error) {
                    logErr('error on refetch users', error);
                  } finally {
                    setIsRefetching(false);
                  }
                }}
              >
                {isRefetching ? (
                  <Center height={22} width={22}>
                    <CircularProgress size={20} color={isDarkMode ? 'secondary' : undefined} />
                  </Center>
                ) : (
                  <Center height={22} width={22}>
                    <RefreshIcon style={{ color: isDarkMode ? colors.lightBlue : colors.blue }} />
                  </Center>
                )}
              </IconButton>
            </Box>
          </OnlyOnMobile>
        </Flex>
        <Box className='separator' width='100%' />
        <Box>
          <UsersTable users={users} />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Users;
