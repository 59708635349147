import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { Body2 } from './ui/Typography';

interface ErrorMessageProps {
  message?: string;
  containerStyle?: BoxProps;
  textStyle?: React.CSSProperties;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, containerStyle, textStyle }) => {
  if (!message) {
    return null;
  }
  return (
    <Box {...containerStyle}>
      <Body2 color='error' style={{ textAlign: 'center', ...textStyle }}>
        {message}
      </Body2>
    </Box>
  );
};

export default ErrorMessage;
