const keys = {
  sessionData: 'session-data',
};

export const sessionData = {
  set: (data: string) => {
    localStorage.setItem(keys.sessionData, data);
  },
  get: (): string => {
    return localStorage.getItem(keys.sessionData) || '';
  },
  remove: () => {
    localStorage.removeItem(keys.sessionData);
  },
};
