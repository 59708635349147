import React from 'react';
import Center from '../../components/ui/Center';
import MainLayout from '../../components/ui/MainLayout';
import { useMount, useWindowSize } from 'react-use';
import { useMQ } from '../../hook/useMQ';
import { navbarHeight } from '../../components/ui/MainNavbar';
import PasswordRecoveryForm from '../../components/forms/PasswordRecoveryForm';
import config from '../../config';
import { assets } from '../../helpers/assets';
import { isHTHCompany } from '../../helpers/utils';

const PasswordRecovery: React.FC = () => {
  const windowSize = useWindowSize();
  const { isUpSm } = useMQ();
  const isHTH = isHTHCompany();

  useMount(() => {
    if (!config.env.isDev) {
      window.scroll(0, 0);
    }
  });

  return (
    <MainLayout>
      {isHTH && isUpSm ? (
        <Center style={{ marginTop: navbarHeight + 40 }}>
          <img alt='Logo Cycleau' src={assets.logoCycleau} style={{ height: 160 }} />
        </Center>
      ) : null}
      <Center
        style={{
          marginTop: isUpSm
            ? isHTH
              ? 40
              : Math.max(windowSize.height * 0.3, navbarHeight + 12)
            : navbarHeight + 12,
        }}
        width='100%'
        height='100%'
      >
        <PasswordRecoveryForm />
      </Center>
    </MainLayout>
  );
};

export default PasswordRecovery;
