import React from 'react';
import Icon from '@mui/material/Icon';

export const assets = {
  // png
  logo: '/images/logo.png',
  logoCycleau: '/images/cycleau-logo.png',
  linkCycleau: '/images/cycleau-link.png',
  hthProUrl: '/images/hth-pro-url.png',
  poweredSteiel: '/images/powered-steiel.png',
  poweredSteielWhite: '/images/powered-steiel-white.png',
  hthMainBg: '/images/hth-main-bg.png',
  hthMainBgMobile: '/images/hth-main-bg-mobile.png',
  // svg
  menuDashboard: '/svgs/menu-dashboard.svg',
  menuDashboardActive: '/svgs/menu-dashboard-active.svg',
  menuDeviceManagement: '/svgs/menu-device-management.svg',
  menuDeviceManagementActive: '/svgs/menu-device-management-active.svg',
  cancel: '/svgs/cancel.svg',
  edit: '/svgs/edit.svg',
  save: '/svgs/save.svg',
  trash: '/svgs/trash.svg',
  lock: '/svgs/lock.svg',
  menuUser: '/svgs/menu-user.svg',
  menuUserActive: '/svgs/menu-user-active.svg',
  profile: '/svgs/profile.svg',
  profileDark: '/svgs/profile-dark.svg',
  logout: '/svgs/logout.svg',
  logoutDark: '/svgs/logout-dark.svg',
  menu: '/svgs/menu.svg',
  menuDark: '/svgs/dark-menu.svg',
  info: '/svgs/info.svg',
  minor: '/svgs/minor.svg',
  infoOutline: '/svgs/info-outline.svg',
  reload: '/svgs/reload.svg',
  setting: '/svgs/setting.svg',
  interfaceDetail: '/svgs/interface-detail.svg',
  paramsDetail: '/svgs/params-detail.svg',
  pencil: '/svgs/pencil.svg',
  chartPin: '/svgs/chart-pin.svg',
  download: '/svgs/download.svg',
  menuUserWhite: '/svgs/menu-user-white.svg',
  filter: '/svgs/filter.svg',
  settingsBlue: '/svgs/settings-blue.svg',
  email: '/svgs/email.svg',
  greenSave: '/svgs/green-save.svg',
  online: '/svgs/online.svg',
  offline: '/svgs/offline.svg',
  warning: '/svgs/warning.svg',
  commOk: '/svgs/comm-ok.svg',
  commErr: '/svgs/comm-err.svg',
};

export const CustomSvgIcon: React.FC<
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
    onClick?: React.MouseEventHandler<HTMLSpanElement>;
  }
> = (props) => {
  return (
    <Icon
      style={{
        height: '100%',
        width: '100%',
        cursor: props.onClick ? 'pointer' : 'auto',
      }}
      onClick={props.onClick}
    >
      <img
        style={{
          display: 'flex',
          height: 20,
          width: 20,
          ...props.style,
        }}
        alt=''
        {...props}
      />
    </Icon>
  );
};
