import React, { PropsWithChildren } from 'react';
import { Button, ButtonProps, useTheme } from '@mui/material';
import { Body1, CustomTypograhyProps } from './Typography';
import { colors } from '../../theme';

export interface SteielButtonProps {
  text?: string;
  typographyProps?: CustomTypograhyProps;
  borderColor?: string;
}

const SteielButton: React.FC<SteielButtonProps & ButtonProps & PropsWithChildren> = ({
  text,
  typographyProps,
  borderColor,
  children,
  ...buttonProps
}) => {
  const theme = useTheme();
  return (
    <Button
      variant='text'
      size='small'
      {...buttonProps}
      style={{
        backgroundColor: buttonProps.disabled ? colors.stroke4 : theme.palette.primary.main,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: buttonProps.disabled
          ? colors.stroke4
          : borderColor
          ? borderColor
          : theme.palette.primary.main,
        borderRadius: 4,
        paddingTop: 3,
        paddingBottom: 3,
        paddingLeft: 20,
        paddingRight: 20,
        ...buttonProps?.style,
      }}
    >
      {children ? (
        children
      ) : (
        <Body1 style={{ color: '#fff' }} weight={400} {...typographyProps}>
          {text}
        </Body1>
      )}
    </Button>
  );
};

export default SteielButton;
