import React from 'react';
import { Box, BoxProps, InputAdornment, LinearProgress } from '@mui/material';
import { ls } from '../../i18n/translations';
import SteielTextInput from '../ui/SteielTextInput';
import SteielButton from '../ui/SteielButton';
import ErrorMessage from '../ErrorMessage';
import { Form, Formik } from 'formik';
import { validateEmail } from '../../helpers/validators';
import {
  capitalize,
  catchErrors,
  getCompanyDomaninFromUrl,
  isHTHCompany,
  isObjEmpty,
  log,
  logErr,
} from '../../helpers/utils';
import { useNavigate } from 'react-router-dom';
import { PageName } from '../../routes';
import { Body2, H2 } from '../ui/Typography';
import { useMQ } from '../../hook/useMQ';
import { colors } from '../../theme';
import { useWindowSize } from 'react-use';
import Center from '../ui/Center';
import Flex from '../ui/Flex';
import { assets, CustomSvgIcon } from '../../helpers/assets';
import sdkClient from '../../graphql/sdkClient';
import SteielDialog from '../ui/SteielDialog';
import { useDarkMode } from '../../hook/useDarkMode';

interface FormData {
  email: string;
}

const initialFormData: FormData = {
  email: '',
};

const PasswordRecoveryForm: React.FC = () => {
  const [errorFromServer, setErrorFromServer] = React.useState('');
  const [showDialog, setShowDialog] = React.useState(false);
  const navigate = useNavigate();
  const { isMobile } = useMQ();
  const windowSize = useWindowSize();
  const { isDarkMode } = useDarkMode();
  const isHTH = isHTHCompany();

  let boxProps: BoxProps = {
    width: '100%',
    bgcolor: isHTH && isMobile ? 'transparent' : isDarkMode ? colors.darkBackground2 : '#fff',
    borderRadius: 1,
    sx: { boxShadow: 3 },
  };
  if (!isMobile) {
    boxProps = {
      ...boxProps,
      width: Math.min(windowSize.width * 0.8, 500),
    };
  } else {
    boxProps = {
      ...boxProps,
      width: '95%',
      bgcolor:
        isHTH && isMobile ? 'transparent' : isDarkMode ? colors.darkBackground : colors.background,
      sx: { boxShadow: 0 },
    };
  }

  return (
    <Box {...boxProps} mb={4}>
      <Box mt={isHTH && isMobile ? 8 : 4} mb={2} textAlign='center'>
        <H2 style={{ fontWeight: 600 }}>{ls.forgetPasswordQuestion}</H2>
      </Box>
      <Center mt={3} px={isMobile ? 0 : 6}>
        <Body2
          textAlign='center'
          style={{ color: isHTH && isMobile ? colors.blue : colors.lightGrey }}
        >
          {ls.lostPasswordInstructions}
        </Body2>
      </Center>
      <Box>
        <Formik
          initialValues={initialFormData}
          validate={(values) => {
            const errors: Partial<FormData> = {};
            const email = validateEmail(values.email);
            if (!email.isValid) {
              errors.email = ls.getString(email.errorTranslationKey);
            }
            // reset errorFromServer
            if (!isObjEmpty(errors)) {
              setErrorFromServer('');
            }
            return errors;
          }}
          onSubmit={async (values, actions) => {
            try {
              log('submitting', values);
              setErrorFromServer('');
              const companyDomain = getCompanyDomaninFromUrl();
              const res = await sdkClient().passwordRecovery({
                input: { email: values.email, companyDomain },
              });
              if (!res.passwordRecovery) {
                throw new Error(ls.sww);
              }
              setShowDialog(true);
            } catch (error) {
              logErr('error on submit password recovery form', error);
              catchErrors(error, setErrorFromServer);
            } finally {
              actions.setSubmitting(false);
            }
          }}
        >
          {(props) => {
            const emailError = (props.dirty && props.touched.email && props.errors.email) || '';

            const firstError = emailError || errorFromServer || '';

            return (
              <Form>
                <Box mx={isMobile ? 0 : 4}>
                  <Flex mt={4} justifyContent='flex-start'>
                    {/* <Box
                      mr={-1}
                      zIndex={10}
                      bgcolor={colors.lightGrey}
                      style={{
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5,
                        height: 40,
                        width: 50,
                      }}
                    >
                      <Center>
                        <CustomSvgIcon src={assets.menuUserWhite} />
                      </Center>
                    </Box> */}
                    <SteielTextInput
                      fieldName='email'
                      formProps={props}
                      textFieldProps={{
                        placeholder: ls.email,
                        required: true,
                        type: 'email',
                        error: Boolean(emailError),
                        size: 'small',
                        color: 'secondary',
                        // focused: true,
                        style: { backgroundColor: isDarkMode ? colors.darkBackground : '#fff' },
                        InputProps: {
                          sx: {
                            // '& .MuiInputBase-input': {
                            //   color: 'secondary.main',
                            // },
                          },
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Box
                                ml={-1.7}
                                mt={0}
                                bgcolor={
                                  isHTH
                                    ? '#3463A2'
                                    : isDarkMode
                                    ? colors.darkMenuBg
                                    : colors.lightGrey
                                }
                                width={50}
                                height={41.5}
                                style={{
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0,
                                }}
                              >
                                <CustomSvgIcon
                                  src={assets.email}
                                  style={{ marginLeft: 0.5, marginTop: 7, width: 28, height: 28 }}
                                />
                              </Box>
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </Flex>
                </Box>
                {firstError ? (
                  <ErrorMessage
                    containerStyle={{
                      my: 2,
                    }}
                    message={firstError}
                  />
                ) : (
                  <Box />
                )}
                {props.isSubmitting && (
                  <Box my={2}>
                    <LinearProgress />
                  </Box>
                )}
                <Flex mt={4} mb={2} px={isMobile ? 0 : 8} width='100%'>
                  <Box width={150}>
                    <SteielButton
                      text={ls.cancel}
                      disabled={props.isSubmitting}
                      fullWidth
                      style={{
                        backgroundColor: 'transparent',
                        borderColor: isDarkMode ? colors.lightGrey : colors.blue,
                      }}
                      typographyProps={{
                        style: { color: isDarkMode ? colors.lightGrey : colors.blue },
                      }}
                      onClick={() => {
                        navigate(PageName.signin);
                      }}
                    />
                  </Box>
                  <Box width={150}>
                    <SteielButton
                      text={ls.send}
                      type='submit'
                      disabled={props.isSubmitting}
                      fullWidth
                      style={
                        isHTH ? { backgroundColor: '#E2211C', borderColor: '#E2211C' } : undefined
                      }
                    />
                  </Box>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </Box>
      <SteielDialog
        title={capitalize(ls.success)}
        content={capitalize(ls.recoveryPasswordEmailSent)}
        onClose={() => {
          setShowDialog(false);
          navigate(PageName.root);
        }}
        onOkActionClick={() => {
          setShowDialog(false);
          navigate(PageName.root);
        }}
        open={showDialog}
        okLabel={capitalize(ls.ok)}
      />
    </Box>
  );
};

export default PasswordRecoveryForm;
