import { useApolloClient } from '@apollo/client';
import React from 'react';
import { Device, useDevicesQuery } from '../graphql/codegen/graphql-react';

export const useDevices = () => {
  const apolloClient = useApolloClient();
  const {
    data: devicesData,
    loading: devicesLoading,
    error: devicesError,
    refetch: devicesRefetch,
    startPolling: devicesStartPolling,
    stopPolling: devicesStopPolling,
  } = useDevicesQuery();

  const devices: Device[] = [...((devicesData?.devices || []) as Device[])];

  const resetApolloCache = async () => {
    try {
      await apolloClient.cache.reset();
    } catch (error) {}
  };

  return {
    devices,
    devicesLoading,
    devicesError,
    devicesRefetch,
    devicesStartPolling,
    devicesStopPolling,
    resetApolloCache,
  };
};
