import { useLocation } from 'react-router-dom';

export const useSearchParams = () => {
  const search = useLocation().search;
  const urlParams = new URLSearchParams(search);

  const id = urlParams.get('id') || '-';
  const deviceId = urlParams.get('deviceId') || '-';
  const moduleId = urlParams.get('moduleId') || '-';
  const userId = urlParams.get('userId') || '-';
  const companyId = urlParams.get('companyId') || '-';

  return {
    idParam: id,
    deviceIdParam: deviceId,
    moduleIdParam: moduleId,
    userIdParam: userId,
    companyIdParam: companyId,
  };
};
