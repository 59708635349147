import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ls } from '../../i18n/translations';
import { Body2 } from '../ui/Typography';
import SteielButton from '../ui/SteielButton';
import { Box, TextField } from '@mui/material';
import { colors } from '../../theme';
import { assets, CustomSvgIcon } from '../../helpers/assets';
import Center from '../ui/Center';
import SteielSelect from '../ui/SteielSelect';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageName } from '../../routes';
import { capitalize } from '../../helpers/utils';

const rows: {
  mac: string;
  name: string;
  type: string;
  tool: string;
  version: string;
}[] = Array(3)
  .fill(0)
  .map((el, i) => {
    return {
      mac: 'AA:BB:CC:DD:EE:FF',
      name: 'FOSFATANTE',
      type: 'SERIAL',
      tool: "CYCL'EAU",
      version: 'PRO',
    };
  });

interface DeviceSetupTableProps {
  isDeviceManagement?: boolean;
}

const DeviceSetupTable: React.FC<DeviceSetupTableProps> = ({ isDeviceManagement }) => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const deviceId = new URLSearchParams(search).get('id') || '-';

  return (
    <TableContainer style={{ minHeight: rows.length * 75 }}>
      <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell>
              <Body2 weight={600} color={colors.grey}>
                {ls.macAddress.toUpperCase()}
              </Body2>
            </TableCell>
            <TableCell>
              <Body2 weight={600} color={colors.grey}>
                {ls.name.toUpperCase()}
              </Body2>
            </TableCell>
            <TableCell>
              <Body2 weight={600} color={colors.grey}>
                {ls.type.toUpperCase()}
              </Body2>
            </TableCell>
            <TableCell>
              <Body2 weight={600} color={colors.grey}>
                {ls.tool.toUpperCase()}
              </Body2>
            </TableCell>
            <TableCell>
              <Body2 weight={600} color={colors.grey}>
                {ls.version.toUpperCase()}
              </Body2>
            </TableCell>
            {!isDeviceManagement ? <TableCell></TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component='th' scope='row'>
                <Body2>{row.mac}</Body2>
              </TableCell>
              <TableCell width={250}>
                <TextField size='small' placeholder={ls.name} value={row.name} />
              </TableCell>
              <TableCell>
                <Box width={150}>
                  <SteielSelect
                    allowedValues={['SERIAL']}
                    onChange={(value) => {}}
                    initialVal='SERIAL'
                  />
                </Box>
              </TableCell>
              <TableCell>
                <Box width={150}>
                  <SteielSelect
                    allowedValues={["CYCL'EAU"]}
                    onChange={(value) => {}}
                    initialVal="CYCL'EAU"
                  />
                </Box>
              </TableCell>
              <TableCell>
                <Box width={150}>
                  <SteielSelect
                    allowedValues={['PRO', 'FIRST']}
                    onChange={(value) => {}}
                    initialVal='PRO'
                  />
                </Box>
              </TableCell>
              {!isDeviceManagement ? (
                <TableCell>
                  <SteielButton
                    sx={{ height: 35 }}
                    onClick={() => {
                      navigate(
                        PageName.deviceMeasurements.replace('{0}', deviceId).replace('{1}', `${i}`),
                      );
                    }}
                  >
                    <Center>
                      <Box mb={-1}>
                        <CustomSvgIcon
                          src={assets.pencil}
                          style={{ width: 16, height: 16 }}
                          onClick={() => {}}
                        />
                      </Box>
                      <Box ml={1}>
                        <Body2 color='white' size='0.9rem'>
                          {capitalize(ls.customizeMeasurements, false)}
                        </Body2>
                      </Box>
                    </Center>
                  </SteielButton>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeviceSetupTable;
