import React from 'react';
import { setCookie, destroyCookie, parseCookies } from 'nookies';
import { useSnapshot } from 'valtio';
import {
  SignInInput,
  UserRole,
  useMeLazyQuery,
  useUpdateProfileMutation,
  UpdateProfileInput,
  UpdateProfileMutation,
  SignInMutation,
} from '../graphql/codegen/graphql-react';
import sdkClient from '../graphql/sdkClient';
import { authState } from '../store/authStore';
import { dayjs, isHTHCompany, log, logErr, updateThemeMetaAndManifest } from '../helpers/utils';
import { sessionData } from '../helpers/localStorage';
import { FetchResult, useApolloClient } from '@apollo/client';
import { allowedLang, defaultLang, ls } from '../i18n/translations';

export const useAuth = () => {
  const authSnapshot = useSnapshot(authState);
  const [updateProfileMutation] = useUpdateProfileMutation();
  const [meQuery, { loading: meLoading, error: meError, data: meData, refetch: meRefetch }] =
    useMeLazyQuery();
  const apolloClient = useApolloClient();

  React.useEffect(() => {
    if (meError) {
      authState.user = null;
      authState.jwtToken = null;
    } else if (meData && meData.me) {
      authState.user = meData.me.user;
      authState.jwtToken = meData.me.jwtToken;
    }
  }, [meData, meError]);

  const isLogged = Boolean(authSnapshot.user?.email);

  const signIn = async (input: SignInInput, admin?: boolean): Promise<SignInMutation> => {
    try {
      const result = await sdkClient().signIn({ input });
      const { signIn } = result;
      if (signIn && signIn.jwtToken && signIn.user && signIn.session) {
        if (admin && !Boolean(signIn.user?.roles?.includes(UserRole.Admin))) {
          throw new Error(ls.notAuthorized);
        }
        authState.jwtToken = signIn.jwtToken;
        authState.user = signIn.user;
        setCookie(null, 'token', signIn.jwtToken, {
          path: '/',
          expires: dayjs().utc().add(1, 'year').toDate(),
        });
        sessionData.set(signIn.session);
        updateThemeMetaAndManifest({
          companyLogoUrl: signIn.user?.Company?.logoUrl || undefined,
          documentTitle: signIn.user?.Company?.name || undefined,
        });
        // set lang
        const userLang =
          signIn.user.languageCode?.toLowerCase() || (isHTHCompany() ? 'fr' : defaultLang);
        if (allowedLang.includes(userLang)) {
          ls.setLanguage(userLang);
        }
        // reset cache
        await apolloClient.cache.reset();
      }
      return result;
    } catch (error) {
      logErr('error on signIn', error);
      authState.user = null;
      authState.jwtToken = null;
      throw error;
    }
  };

  // const signUp = async (input: SignUpInput): Promise<SignUpMutation> => {
  //   try {
  //     const result = await sdkClient().signUp({ input });
  //     const { signUp } = result;
  //     if (signUp && signUp.jwtToken && signUp.user && signUp.session) {
  //       authState.jwtToken = signUp.jwtToken;
  //       authState.user = signUp.user;
  //       setCookie(null, 'token', signUp.jwtToken, {
  //         path: '/',
  //         expires: dayjs().utc().add(1, 'year').toDate(),
  //       });
  //       sessionData.set(signUp.session);
  //       // reset cache
  //       await apolloClient.cache.reset();
  //     }
  //     return result;
  //   } catch (error) {
  //     logErr('error on signUp', error);
  //     authState.user = null;
  //     authState.jwtToken = null;
  //     throw error;
  //   }
  // };

  const signOut = async () => {
    try {
      await sdkClient().signOut();
    } catch (error) {
      logErr('error on signOut', error);
    } finally {
      localStorage.clear();
      destroyCookie(null, 'token');
      authState.user = null;
      authState.jwtToken = null;
      apolloClient.cache
        .reset()
        .then(() => {
          log('apollo client cache resetted');
        })
        .catch((err) => {
          logErr('error resetting apollo client cache', err);
        });
    }
  };

  const updateProfile = async (
    input: UpdateProfileInput,
  ): Promise<FetchResult<
    UpdateProfileMutation,
    Record<string, any>,
    Record<string, any>
  > | null> => {
    try {
      const result = await updateProfileMutation({ variables: { input } });
      if (result && result.data && result.data.updateProfile) {
        authState.user = result.data.updateProfile;
      }
      return result;
    } catch (error) {
      logErr('error on updateProfile', error);
      throw error;
    }
  };

  return {
    user: authSnapshot.user,
    jwtToken: authSnapshot.jwtToken,
    isLogged,
    isAdmin: isLogged && Boolean(authSnapshot.user?.roles?.includes(UserRole.Admin)),
    isAdmin2: isLogged && Boolean(authSnapshot.user?.roles?.includes(UserRole.Admin2)),
    isSuperTechnician:
      isLogged && Boolean(authSnapshot.user?.roles?.includes(UserRole.Supertechnician)),
    isTechnician: isLogged && Boolean(authSnapshot.user?.roles?.includes(UserRole.Technician)),
    isViewer: isLogged && Boolean(authSnapshot.user?.roles?.includes(UserRole.Viewer)),
    me: {
      load: meQuery,
      meLoading,
      meError,
      meData,
      meRefetch,
    },
    // signUp,
    signIn,
    signOut,
    updateProfile,
  };
};
