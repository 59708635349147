import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import MainNavbar from './MainNavbar';
import { isHTHCompany, setHTHBgBody } from '../../helpers/utils';
import Flex from './Flex';
import { assets } from '../../helpers/assets';
import { useMount, useWindowSize } from 'react-use';
import { useDarkMode } from '../../hook/useDarkMode';
import { useMQ } from '../../hook/useMQ';

const MainLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const { isDarkMode } = useDarkMode();
  const isHTH = isHTHCompany();
  const { isMobile } = useMQ();

  useMount(() => {
    const isHTH = isHTHCompany();
    if (isHTH) {
      // set custom bg image
      setHTHBgBody(isMobile);
    }
  });

  return (
    <Box width='100%' height='100%'>
      <MainNavbar />
      <Box width='100%' height='100%'>
        {children}
      </Box>
      {/* footer only for hth */}
      {isHTH && !isMobile ? (
        <Box position='absolute' bottom={0} left={0} mb={2} mx={2} width={windowWidth - 32}>
          <Flex>
            <a href='https://www.hth-pro.com' target='_blank' rel='noopener noreferrer'>
              <img
                alt='hth pro link'
                src={assets.hthProUrl}
                style={{ height: 24, marginBottom: -6 }}
              />
            </a>
            {isDarkMode ? (
              <img alt='powered by STEIEL' src={assets.poweredSteielWhite} style={{ height: 40 }} />
            ) : (
              <img alt='powered by STEIEL' src={assets.poweredSteiel} style={{ height: 40 }} />
            )}
          </Flex>
        </Box>
      ) : null}
    </Box>
  );
};

export default MainLayout;
