import * as React from 'react';
import { ls } from '../../i18n/translations';
import {
  capitalize,
  isHTHCompany,
  parseMachineType,
  parseMachineVersion,
} from '../../helpers/utils';
import { Box, IconButton, TextField } from '@mui/material';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { defaultMUIDataTableOptions } from '../../helpers/datatableUtils';
import { MachineType, MachineVersion, Module } from '../../graphql/codegen/graphql-react';
import SteielAutocomplete from '../ui/SteielAutocomplete';
import SteielSelect from '../ui/SteielSelect';
import { Clear as ClearIcon } from '@mui/icons-material';
import { useMQ } from '../../hook/useMQ';

const options: MUIDataTableOptions = {
  ...defaultMUIDataTableOptions,
  filter: false,
  sort: false,
  download: false,
  search: false,
  pagination: false,
  viewColumns: false,
  fixedSelectColumn: false,
};

interface ModulesTableProps {
  modules: Partial<Module>[];
  macOptions: string[];
  setFieldValue: (field: string, value: any) => void;
}

const ModulesTable: React.FC<ModulesTableProps> = ({ modules, macOptions, setFieldValue }) => {
  const { isMobile } = useMQ();
  const tableModules = ([...(modules || [])].map((m, i) => {
    return {
      mac: m.mac || '',
      name: m.name || '',
      tool: m.tool || '',
      type: m.type ? parseMachineType(m.type) : parseMachineType(MachineType.Unknown),
      version: m.modelVersion
        ? parseMachineVersion(m.modelVersion)
        : parseMachineVersion(MachineVersion.Unknown),
    };
  }) || []) as {
    mac: string;
    name: string;
    type: string;
    tool: string;
    version: string;
  }[];

  const maxUiFixRows = 6;
  const FixUiComp =
    !isMobile && tableModules.length > 0 && tableModules.length < maxUiFixRows ? (
      <Box height={300} />
    ) : null;

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'mac',
      label: ls.macAddress.toUpperCase(),
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box minWidth={200}>
              <SteielAutocomplete
                options={macOptions}
                value={value}
                onChange={(v) => {
                  const newModules = [...modules];
                  newModules[tableMeta.rowIndex] = { ...newModules[tableMeta.rowIndex], mac: v };
                  setFieldValue('modules', newModules);
                }}
              />
              {tableMeta.rowIndex === tableModules.length - 1 ? FixUiComp : null}
            </Box>
          );
        },
      },
    },
    {
      name: 'name',
      label: ls.name.toUpperCase(),
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box minWidth={200}>
              <TextField
                placeholder={capitalize(ls.name)}
                fullWidth
                size='small'
                InputProps={{ style: { fontSize: '1.1rem' } }}
                value={value}
                onChange={(e) => {
                  const newModules = [...modules];
                  newModules[tableMeta.rowIndex] = {
                    ...newModules[tableMeta.rowIndex],
                    name: e.target.value,
                  };
                  setFieldValue('modules', newModules);
                }}
              />
              {tableMeta.rowIndex === tableModules.length - 1 ? FixUiComp : null}
            </Box>
          );
        },
      },
    },
    {
      name: 'type',
      label: ls.tool.toUpperCase(),
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box>
              <SteielSelect
                allowedValues={
                  isHTHCompany()
                    ? [parseMachineType(MachineType.Cycleau)]
                    : [
                        parseMachineType(MachineType.Cycleau),
                        parseMachineType(MachineType.Mc14Evo),
                        parseMachineType(MachineType.Mco14Evo),
                        parseMachineType(MachineType.NovaNsp),
                        parseMachineType(MachineType.Ef315),
                        // parseMachineType(MachineType.Evolution),
                        parseMachineType(MachineType.Ew2Pool),
                        parseMachineType(MachineType.Nova2Pool),
                        parseMachineType(MachineType.Unknown),
                      ]
                }
                initialVal={value}
                onChange={(v) => {
                  const newModules = [...modules];
                  newModules[tableMeta.rowIndex] = {
                    ...newModules[tableMeta.rowIndex],
                    type: v as MachineType,
                  };
                  setFieldValue('modules', newModules);
                }}
              />
              {tableMeta.rowIndex === tableModules.length - 1 ? FixUiComp : null}
            </Box>
          );
        },
      },
    },
    // {
    //   name: 'tool',
    //   label: ls.tool.toUpperCase(),
    //   options: {
    //     // eslint-disable-next-line react/display-name
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <Box>
    //           <SteielSelect
    //             allowedValues={["CYCL'EAU"]}
    //             initialVal={value}
    //             onChange={(v) => {
    //               const newModules = [...modules];
    //               newModules[tableMeta.rowIndex] = { ...newModules[tableMeta.rowIndex], tool: v };
    //               setFieldValue('modules', newModules);
    //             }}
    //           />
    //           {tableMeta.rowIndex === tableModules.length - 1 ? FixUiComp : null}
    //         </Box>
    //       );
    //     },
    //   },
    // },
    {
      name: 'version',
      label: ls.version.toUpperCase(),
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          const allowedValues = [];
          switch (tableModules[tableMeta.rowIndex].type) {
            case parseMachineType(MachineType.Cycleau): {
              allowedValues.push(parseMachineVersion(MachineVersion.First));
              allowedValues.push(parseMachineVersion(MachineVersion.Pro));
              break;
            }
            case parseMachineType(MachineType.Mc14Evo): {
              allowedValues.push(parseMachineVersion(MachineVersion.Mc14evoPHRxCle12Tc));
              allowedValues.push(parseMachineVersion(MachineVersion.Mc14evoPHRxCacTc));
              allowedValues.push(parseMachineVersion(MachineVersion.Mc14evo_4CanaliTc));
              break;
            }
            case parseMachineType(MachineType.Mco14Evo): {
              allowedValues.push(parseMachineVersion(MachineVersion.Mco14_3SezCl2));
              allowedValues.push(parseMachineVersion(MachineVersion.Mco14_5SezCl2ClTClC));
              break;
            }
            case parseMachineType(MachineType.NovaNsp): {
              allowedValues.push(parseMachineVersion(MachineVersion.Nsp161CMin));
              allowedValues.push(parseMachineVersion(MachineVersion.Nsp161Ma));
              allowedValues.push(parseMachineVersion(MachineVersion.Nsp162PHRx));
              allowedValues.push(parseMachineVersion(MachineVersion.Nsp163ACle11_12));
              allowedValues.push(parseMachineVersion(MachineVersion.Nsp163BCle16));
              break;
            }
            case parseMachineType(MachineType.Ef315): {
              allowedValues.push(parseMachineVersion(MachineVersion.PHRxTc));
              allowedValues.push(parseMachineVersion(MachineVersion.PHClTc));
              allowedValues.push(parseMachineVersion(MachineVersion.PHCacTc));
              break;
            }
            case parseMachineType(MachineType.Evolution): {
              allowedValues.push(parseMachineVersion(MachineVersion.Ew2Pool));
              break;
            }
            case parseMachineType(MachineType.Ew2Pool): {
              allowedValues.push(parseMachineVersion(MachineVersion.PHOrpTc));
              allowedValues.push(parseMachineVersion(MachineVersion.PHCacTc));
              allowedValues.push(parseMachineVersion(MachineVersion.PHCleTc));
              break;
            }
            case parseMachineType(MachineType.Nova2Pool): {
              allowedValues.push(parseMachineVersion(MachineVersion.PHOrpTc));
              allowedValues.push(parseMachineVersion(MachineVersion.PHCacTc));
              allowedValues.push(parseMachineVersion(MachineVersion.PHCleTc));
              break;
            }
            default:
              allowedValues.push(parseMachineVersion(MachineVersion.Unknown));
              break;
          }
          return (
            <Box>
              <SteielSelect
                allowedValues={allowedValues}
                initialVal={value}
                onChange={(v) => {
                  const newModules = [...modules];
                  newModules[tableMeta.rowIndex] = {
                    ...newModules[tableMeta.rowIndex],
                    modelVersion: v as MachineVersion,
                  };
                  setFieldValue('modules', newModules);
                }}
              />
              {tableMeta.rowIndex === tableModules.length - 1 ? FixUiComp : null}
            </Box>
          );
        },
      },
    },
    {
      name: '',
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box>
              <IconButton
                onClick={() => {
                  const newModules = [...modules];
                  newModules.splice(tableMeta.rowIndex, 1);
                  setFieldValue('modules', newModules);
                }}
              >
                <ClearIcon color='primary' />
              </IconButton>
              {tableMeta.rowIndex === tableModules.length - 1 ? FixUiComp : null}
            </Box>
          );
        },
      },
    },
  ];

  return (
    <MUIDataTable
      title=''
      data={tableModules}
      columns={columns}
      options={{
        ...options,
        elevation: isMobile ? 0 : 1,
        rowHover: false,
        rowsPerPage: localStorage.getItem('modules_rowsPerPage')
          ? Number(localStorage.getItem('modules_rowsPerPage'))
          : 10,
        onChangeRowsPerPage: (numberOfRows) => {
          localStorage.setItem('modules_rowsPerPage', `${numberOfRows}`);
        },
      }}
    />
  );
};

export default ModulesTable;
