import React from 'react';
import { Autocomplete, Box, TextField, TextFieldProps } from '@mui/material';
import { colors } from '../../theme';
import { useDarkMode } from '../../hook/useDarkMode';

export interface SteielAutocompleteProps {
  options: string[];
  textFieldProps?: TextFieldProps;
  onChange?: (value: string | null) => void;
  value?: string;
  disabled?: boolean;
}

const SteielAutocomplete: React.FC<SteielAutocompleteProps> = ({
  options,
  onChange,
  textFieldProps,
  value,
  disabled,
}) => {
  const { isDarkMode } = useDarkMode();
  return (
    <Autocomplete
      disablePortal
      id={`autocomplete_${Date.now()}`}
      options={options}
      onChange={(_event: any, newValue: string | null) => {
        onChange?.(newValue || '');
      }}
      onInputChange={(_event, newValue, reason) => {
        onChange?.(newValue || '');
      }}
      inputValue={value}
      value={value}
      fullWidth
      disabled={disabled}
      ListboxProps={{
        style: {
          fontSize: '1.1rem',
          backgroundColor: isDarkMode ? colors.darkBackground : undefined,
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size='small'
          InputProps={{ ...params.InputProps, style: { fontSize: '1.1rem' } }}
          {...textFieldProps}
        />
      )}
    />
  );
};

export default SteielAutocomplete;
