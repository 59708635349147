import React from 'react';
import { Box, BoxProps, IconButton, TextField, TextFieldProps, useTheme } from '@mui/material';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { FormikProps } from 'formik';
import { Body1, CustomTypograhyProps } from './Typography';
import Flex from './Flex';
import { useMQ } from '../../hook/useMQ';
import { useDarkMode } from '../../hook/useDarkMode';
import { colors } from '../../theme';

interface BasicTextInputProps {
  initialVal: string;
  onChange: (val: string) => void;
  textInputProps?: TextFieldProps;
}

export const BasicTextInput: React.FC<BasicTextInputProps> = ({
  initialVal,
  onChange,
  textInputProps,
}) => {
  const [val, setVal] = React.useState(initialVal ?? '');
  return (
    <TextField
      {...textInputProps}
      value={val}
      hiddenLabel
      variant='standard'
      size='small'
      onChange={(e) => {
        const newVal = e.target.value;
        setVal(newVal);
        onChange(newVal);
      }}
    />
  );
};

interface SteielTextInputProps {
  fieldName: string;
  formProps: FormikProps<any>;
  textFieldProps: Partial<TextFieldProps>;
  isPassword?: boolean;
  onChangeCb?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  disabled?: boolean;
  label?: string;
  eyeIconColor?: string;
  labelStyle?: CustomTypograhyProps;
  containerStyle?: BoxProps;
}

const SteielTextInput: React.FC<SteielTextInputProps> = ({
  isPassword,
  fieldName,
  textFieldProps,
  formProps,
  onChangeCb,
  disabled,
  label,
  eyeIconColor,
  labelStyle,
  containerStyle,
}) => {
  const theme = useTheme();
  const { isDarkMode } = useDarkMode();
  const [isPasswordType, setIsPasswordType] = React.useState(isPassword);
  const { isMobileOrTablet } = useMQ();
  const showEyePass = isPassword;
  const type = showEyePass ? (isPasswordType ? 'password' : 'text') : textFieldProps.type;
  const placeholder = textFieldProps.required
    ? textFieldProps.placeholder + ' *'
    : textFieldProps.placeholder;
  const labelParsed = label ? `${label}:` : '';
  const isDisabled = formProps.isSubmitting || disabled;
  return (
    <Box width={'100%'}>
      <Flex
        justifyContent={isMobileOrTablet ? 'center' : 'flex-start'}
        width={isMobileOrTablet ? '100%' : undefined}
        flexDirection={isMobileOrTablet ? 'column' : 'row'}
        alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
        {...containerStyle}
      >
        {label ? (
          <Body1 {...labelStyle} style={{ marginLeft: 2, marginBottom: 2, ...labelStyle?.style }}>
            {labelParsed}
          </Body1>
        ) : null}
        <Box flex={1} width={isMobileOrTablet ? '100%' : undefined}>
          <TextField
            {...textFieldProps}
            inputProps={{
              ...textFieldProps?.inputProps,
              style: {
                // color: theme.palette.text.primary,
                fontWeight: 400,
                fontSize: '1.1rem',
                borderRadius: 0,
                backgroundColor: isDarkMode ? colors.darkBackground : '#fff',
                opacity: isDisabled ? 0.7 : 1,
                ...textFieldProps?.inputProps?.style,
                ...textFieldProps?.style,
              },
            }}
            variant='outlined'
            fullWidth
            type={type}
            placeholder={placeholder}
            value={formProps.values[fieldName]}
            onChange={(e) => {
              formProps.setFieldValue(fieldName, e.target.value);
              onChangeCb?.(e);
            }}
            onBlur={formProps.handleBlur(fieldName)}
            disabled={isDisabled}
          />
        </Box>
      </Flex>
      {showEyePass ? (
        <Box position='relative' width='100%'>
          {isPasswordType ? (
            <Box position='absolute' top={-41.1} right={2} zIndex={100}>
              <IconButton
                aria-label='show password'
                onClick={() => {
                  setIsPasswordType(false);
                }}
              >
                <VisibilityOffIcon
                  style={{ color: eyeIconColor ? eyeIconColor : theme.palette.text.disabled }}
                />
              </IconButton>
            </Box>
          ) : (
            <Box position='absolute' top={-41.1} right={2} zIndex={100}>
              <IconButton
                aria-label='hide password'
                onClick={() => {
                  setIsPasswordType(true);
                }}
              >
                <VisibilityIcon
                  style={{ color: eyeIconColor ? eyeIconColor : theme.palette.text.disabled }}
                />
              </IconButton>
            </Box>
          )}
        </Box>
      ) : null}
    </Box>
  );
};

export default SteielTextInput;
