import { GraphQLClient } from 'graphql-request';
import { parseCookies } from 'nookies';
import config from '../config';
import { getSdk } from './codegen/sdk';

const sdkClient = () => {
  return getSdk(
    new GraphQLClient(config.env.endpoint, {
      // credentials: 'include',
      headers: {
        authorization: parseCookies().token ? `Bearer ${parseCookies().token}` : '',
      },
    }),
  );
};

export default sdkClient;
