import React from 'react';
import { ls } from '../i18n/translations';
import Flex from './ui/Flex';
import { assets, CustomSvgIcon } from '../helpers/assets';
import Center from './ui/Center';
import { colors } from '../theme';
import { Box } from '@mui/material';
import { Body2 } from './ui/Typography';
import { useMQ } from '../hook/useMQ';

interface DeviceIconStatusProps {
  deviceStatus: string;
  withDesc?: boolean;
  alwaysShowDesc?: boolean;
}

const DeviceIconStatus: React.FC<DeviceIconStatusProps> = ({
  deviceStatus,
  withDesc,
  alwaysShowDesc,
}) => {
  const { isMobile } = useMQ();
  const showDesc = alwaysShowDesc || (withDesc && !isMobile);
  if (deviceStatus === ls.online || deviceStatus === ls.onlineWithAlarm) {
    return (
      <Flex justifyContent='flex-start'>
        <Center>
          <CustomSvgIcon src={assets.online} />
        </Center>
        {showDesc ? (
          <Box ml={0.5} mb={-0.2}>
            <Body2 color={colors.grey}>{ls.online.toUpperCase()}</Body2>
          </Box>
        ) : null}
        {deviceStatus === ls.onlineWithAlarm ? (
          <>
            <Center ml={1}>
              <CustomSvgIcon src={assets.warning} />
            </Center>
            {showDesc ? (
              <Box ml={0.5} mb={-0.2}>
                <Body2 color={colors.grey}>{ls.alarm.toUpperCase()}</Body2>
              </Box>
            ) : null}
          </>
        ) : null}
      </Flex>
    );
  }
  return (
    <Flex justifyContent='flex-start'>
      <Center>
        <CustomSvgIcon src={assets.offline} />
      </Center>
      {showDesc ? (
        <Box ml={0.5} mb={-0.2}>
          <Body2 color={colors.grey}>{ls.offline.toUpperCase()}</Body2>
        </Box>
      ) : null}
    </Flex>
  );
};

export default DeviceIconStatus;
