import React from 'react';
import { Box, BoxProps, LinearProgress } from '@mui/material';
import { ls } from '../../i18n/translations';
import SteielTextInput from '../ui/SteielTextInput';
import SteielButton from '../ui/SteielButton';
import ErrorMessage from '../ErrorMessage';
import { Form, Formik } from 'formik';
import { PASSWORD_MIN_LENGTH, validatePassword } from '../../helpers/validators';
import { capitalize, catchErrors, isHTHCompany, isObjEmpty, logErr } from '../../helpers/utils';
import { useNavigate } from 'react-router-dom';
import { PageName } from '../../routes';
import { H2 } from '../ui/Typography';
import { useMQ } from '../../hook/useMQ';
import { colors } from '../../theme';
import { useWindowSize } from 'react-use';
import Flex from '../ui/Flex';
import sdkClient from '../../graphql/sdkClient';
import SteielDialog from '../ui/SteielDialog';
import { useDarkMode } from '../../hook/useDarkMode';

interface FormData {
  password: string;
  repeatPassword: string;
}

const initialFormData: FormData = {
  password: '',
  repeatPassword: '',
};

const SetNewPasswordForm: React.FC = () => {
  const [errorFromServer, setErrorFromServer] = React.useState('');
  const [showDialog, setShowDialog] = React.useState(false);
  const navigate = useNavigate();
  const { isMobile } = useMQ();
  const { isDarkMode } = useDarkMode();
  const windowSize = useWindowSize();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const isHTH = isHTHCompany();

  let boxProps: BoxProps = {
    width: '100%',
    bgcolor: isHTH && isMobile ? 'transparent' : isDarkMode ? colors.darkBackground2 : '#fff',
    borderRadius: 1,
    sx: { boxShadow: 3 },
  };
  if (!isMobile) {
    boxProps = {
      ...boxProps,
      width: Math.min(windowSize.width * 0.8, 500),
    };
  } else {
    boxProps = {
      ...boxProps,
      width: '95%',
      bgcolor:
        isHTH && isMobile ? 'transparent' : isDarkMode ? colors.darkBackground : colors.background,
      sx: { boxShadow: 0 },
    };
  }

  return (
    <Box {...boxProps} mb={4}>
      <Box mt={isHTH && isMobile ? 8 : 4} mb={2} textAlign='center'>
        <H2 style={{ fontWeight: 600 }}>{ls.modifyPassword}</H2>
      </Box>
      <Box>
        <Formik
          initialValues={initialFormData}
          validate={(values) => {
            const errors: Partial<FormData> = {};
            const password = validatePassword(values.password);
            if (!password.isValid) {
              errors.password = ls
                .getString(password.errorTranslationKey)
                .replace('{0}', `${PASSWORD_MIN_LENGTH}`);
            }
            if (values.password !== values.repeatPassword) {
              errors.password = ls.passwordMismatch;
            }
            // reset errorFromServer
            if (!isObjEmpty(errors)) {
              setErrorFromServer('');
            }
            return errors;
          }}
          onSubmit={async (values, actions) => {
            try {
              // log('submitting', values);
              setErrorFromServer('');
              const res = await sdkClient().changePassword({
                input: { newPassword: values.password, token: urlParams.get('token') || '' },
              });
              if (!res.changePassword) {
                throw new Error(ls.sww);
              }
              setShowDialog(true);
            } catch (error) {
              logErr('error on submit password recovery form', error);
              catchErrors(error, setErrorFromServer);
            } finally {
              actions.setSubmitting(false);
            }
          }}
        >
          {(props) => {
            const passwordError =
              (props.dirty && props.touched.password && props.errors.password) || '';
            const firstError = passwordError || errorFromServer || '';

            return (
              <Form>
                <Box mt={3} mx={isMobile ? 0 : 4}>
                  <SteielTextInput
                    fieldName='password'
                    formProps={props}
                    eyeIconColor={colors.lightGrey}
                    textFieldProps={{
                      placeholder: capitalize(ls.password),
                      required: true,
                      error: Boolean(passwordError),
                      size: 'small',
                      style: { backgroundColor: isDarkMode ? colors.darkBackground : '#fff' },
                    }}
                    isPassword
                  />
                  <Box mt={1.5}>
                    <SteielTextInput
                      fieldName='repeatPassword'
                      formProps={props}
                      eyeIconColor={colors.lightGrey}
                      textFieldProps={{
                        placeholder: capitalize(ls.repeatPassword),
                        required: true,
                        size: 'small',
                        style: { backgroundColor: isDarkMode ? colors.darkBackground : '#fff' },
                      }}
                      isPassword
                    />
                  </Box>
                </Box>
                {firstError ? (
                  <ErrorMessage
                    containerStyle={{
                      my: 2,
                    }}
                    message={firstError}
                  />
                ) : (
                  <Box />
                )}
                {props.isSubmitting && (
                  <Box my={2}>
                    <LinearProgress />
                  </Box>
                )}
                <Flex mt={4} mb={2} px={isMobile ? 0 : 8} width='100%'>
                  <Box width={150}>
                    <SteielButton
                      text={ls.cancel}
                      disabled={props.isSubmitting}
                      fullWidth
                      style={{
                        backgroundColor: 'transparent',
                        borderColor: isDarkMode ? colors.lightGrey : colors.blue,
                      }}
                      typographyProps={{
                        style: { color: isDarkMode ? colors.lightGrey : colors.blue },
                      }}
                      onClick={() => {
                        navigate(PageName.signin);
                      }}
                    />
                  </Box>
                  <Box width={150}>
                    <SteielButton
                      text={ls.confirm}
                      type='submit'
                      disabled={props.isSubmitting}
                      fullWidth
                      style={
                        isHTH ? { backgroundColor: '#E2211C', borderColor: '#E2211C' } : undefined
                      }
                    />
                  </Box>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </Box>
      <SteielDialog
        title={capitalize(ls.success)}
        content={capitalize(ls.passwordChangedSuccessfully)}
        onClose={() => {
          setShowDialog(false);
          navigate(PageName.root);
        }}
        onOkActionClick={() => {
          setShowDialog(false);
          navigate(PageName.root);
        }}
        open={showDialog}
        okLabel={capitalize(ls.ok)}
      />
    </Box>
  );
};

export default SetNewPasswordForm;
