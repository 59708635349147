export default Object.freeze({
  env: {
    endpoint: (process.env.REACT_APP_HTTP_ENDPOINT as string) || '',
    frontend: (process.env.REACT_APP_HTTP_FRONTEND as string) || '',
    stage: (process.env.REACT_APP_STAGE as string) || '',
    isProd: process.env.REACT_APP_STAGE === 'production',
    isDemo: process.env.REACT_APP_STAGE === 'demo',
    isStaging: process.env.REACT_APP_STAGE === 'staging',
    isDev: process.env.REACT_APP_STAGE === 'development',
  },
});
