import React, { PropsWithChildren } from 'react';
import { Box, BoxProps } from '@mui/material';
import { useMQ } from '../../hook/useMQ';

export const OnlyOnMobile: React.FC<PropsWithChildren & BoxProps> = ({ children, ...props }) => {
  const { isMobile } = useMQ();
  if (!isMobile) {
    return null;
  }
  return <Box {...props}>{children}</Box>;
};

export const OnlyOnTablet: React.FC<PropsWithChildren & BoxProps> = ({ children, ...props }) => {
  const { isTablet } = useMQ();
  if (!isTablet) {
    return null;
  }
  return <Box {...props}>{children}</Box>;
};

export const OnlyOnDesktop: React.FC<PropsWithChildren & BoxProps> = ({ children, ...props }) => {
  const { isDesktop } = useMQ();
  if (!isDesktop) {
    return null;
  }
  return <Box {...props}>{children}</Box>;
};

export const OnlyOnMobileAndTablet: React.FC<PropsWithChildren & BoxProps> = ({
  children,
  ...props
}) => {
  const { isMobile, isTablet } = useMQ();
  if (!isMobile && !isTablet) {
    return null;
  }
  return <Box {...props}>{children}</Box>;
};

export const OnlyOnTabletAndDesktop: React.FC<PropsWithChildren & BoxProps> = ({
  children,
  ...props
}) => {
  const { isTablet, isDesktop } = useMQ();
  if (!isTablet && !isDesktop) {
    return null;
  }
  return <Box {...props}>{children}</Box>;
};
