import React from 'react';
import { Box, BoxProps } from '@mui/material';

const Center: React.FC<BoxProps> = ({ children, ...otherProps }) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      {...otherProps}
      style={{ textAlign: 'center', ...otherProps.style }}
    >
      {children}
    </Box>
  );
};

export default Center;
