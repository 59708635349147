import { Box } from '@mui/material';
import React from 'react';
import { useWindowSize } from 'react-use';
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  Line,
  Brush,
  Label,
} from 'recharts';
import { MeasurementHistoryType, ModuleSettingType } from '../../graphql/dataTypes';
import { capitalize, parseUnitOfMeasure } from '../../helpers/utils';
import { ls } from '../../i18n/translations';
import { colors } from '../../theme';
import { measurementsColors, measurementsColorsDarkMode } from '../DeviceInterface';
import Center from '../ui/Center';
import { Body2 } from '../ui/Typography';
import { useDarkMode } from '../../hook/useDarkMode';

interface DeviceInterfaceChartProps {
  data: (MeasurementHistoryType & { dt?: string })[];
  moduleSettings: ModuleSettingType[];
  measuresFilter?: boolean[];
}

const DeviceInterfaceChart: React.FC<DeviceInterfaceChartProps> = ({
  data,
  moduleSettings,
  measuresFilter,
}) => {
  const { isDarkMode } = useDarkMode();
  const { width: windowWidth } = useWindowSize();
  const widthCart = windowWidth * 0.75;
  const YAxisComponents: React.ReactNode[] = [];
  const LineComponents: React.ReactNode[] = [];
  for (let i = 0; i < moduleSettings.length; i++) {
    const moduleSetting = moduleSettings[i];
    if (!moduleSetting?.enabled || (measuresFilter && !measuresFilter[i + 1])) {
      continue;
    }
    YAxisComponents.push(
      <YAxis
        // unit='K'
        key={i}
        yAxisId={`left${i + 1}`}
        dataKey={`measurement${i + 1}`}
        width={40}
        orientation='left'
        tick={isDarkMode ? { fill: colors.lightGrey } : {}}
      >
        <Label
          angle={-90}
          position='insideLeft'
          value={(
            capitalize(ls.measure) +
            ` ${i + 1} (` +
            parseUnitOfMeasure(moduleSetting?.unitOfMeasure || 'n/a') +
            ')'
          ).trim()}
          fill={isDarkMode ? colors.lightGrey : undefined}
        />
      </YAxis>,
    );
    LineComponents.push(
      <Line
        key={i}
        name={(
          capitalize(ls.measure) +
          ` ${i + 1} (` +
          parseUnitOfMeasure(moduleSetting?.unitOfMeasure || 'n/a') +
          ')'
        ).trim()}
        yAxisId={`left${i + 1}`}
        dataKey={`measurement${i + 1}`}
        stroke={(isDarkMode ? measurementsColorsDarkMode[i] : measurementsColors[i]) || '#000'}
        dot={false}
        type='monotone'
      />,
    );
  }

  if (moduleSettings.every((el) => !el?.enabled)) {
    return (
      <Center mt={3}>
        <Body2 size='1.1rem' textAlign='center'>
          {capitalize(ls.noMeasurementsFoundOrEnabled)}
        </Body2>
      </Center>
    );
  }

  return (
    <Box width={widthCart}>
      <Box width='100%' height='100%'>
        <ComposedChart
          width={widthCart}
          height={600}
          data={data}
          //   margin={{
          //     top: 20,
          //     right: 80,
          //     bottom: 20,
          //     left: 20,
          //   }}
        >
          <CartesianGrid
            stroke={isDarkMode ? '#5a5c6f' : colors.stroke4}
            strokeWidth={isDarkMode ? 0.5 : undefined}
          />
          <XAxis
            dataKey='dt'
            // label={{ value: capitalize(ls.dates), position: 'insideBottomRight', offset: 0 }}
            // scale='band'
            // interval={Math.max(1, Math.floor(data.length / 8))}
            // tick={isDarkMode ? { fill: colors.lightGrey } : {}}
          />
          {/* <YAxis
            // unit='K'
            yAxisId='left1'
            dataKey='ph'
            label={{ value: ls.ph, angle: -90, position: 'insideLeft' }}
            orientation='left'
          />
          <YAxis
            // unit='mil'
            dataKey='freeChlorine'
            label={{
              value: capitalize(ls.freeChlorine) + ' [ppm]',
              angle: -90,
              position: 'insideLeft',
            }}
            yAxisId='left2'
            orientation='left'
          />
          <YAxis
            // unit='l'
            dataKey='temp'
            label={{
              value: capitalize(ls.temperature) + ' [°C]',
              angle: -90,
              position: 'insideLeft',
            }}
            yAxisId='left3'
            orientation='left'
          />
          <YAxis
            // unit='mil'
            dataKey='totalChlorine'
            label={{
              value: capitalize(ls.totalChlorine) + ' [ppm]',
              angle: -90,
              position: 'insideLeft',
            }}
            yAxisId='left4'
            orientation='left'
          /> */}
          {YAxisComponents}
          <Tooltip
            contentStyle={{ backgroundColor: isDarkMode ? colors.darkBackground : undefined }}
          />
          <Legend />
          {/* <Line
            name={ls.ph}
            yAxisId='left1'
            dataKey='ph'
            stroke={colors.lightBlue}
            dot={false}
            type='monotone'
          />
          <Line
            name={capitalize(ls.freeChlorine) + ' [ppm]'}
            yAxisId='left2'
            dataKey='freeChlorine'
            stroke={colors.green}
            dot={false}
            type='monotone'
          />
          <Line
            name={capitalize(ls.temperature) + ' [°C]'}
            yAxisId='left3'
            dataKey='temp'
            stroke={colors.blue}
            dot={false}
            type='monotone'
          />
          <Line
            name={capitalize(ls.totalChlorine) + ' [ppm]'}
            yAxisId='left4'
            dataKey='totalChlorine'
            stroke={colors.orange}
            dot={false}
            type='monotone'
          /> */}
          {LineComponents}
          <Brush fill={isDarkMode ? colors.darkBackground : undefined} />
        </ComposedChart>
      </Box>
    </Box>
  );
};

export default DeviceInterfaceChart;
