import React from 'react';
import { subscribe, useSnapshot } from 'valtio';
import { globalState } from '../store/globalStore';

export const useGS = (onStateChange?: (gs: typeof globalState) => void) => {
  const snap = useSnapshot(globalState);

  React.useEffect(() => {
    const unsubscribe = subscribe(globalState, () => {
      onStateChange?.(globalState);
    });
    return () => {
      unsubscribe();
    };
  }, [onStateChange]);

  return {
    snap,
    state: globalState,
  };
};
