import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ls } from '../../i18n/translations';
import { Body2 } from '../ui/Typography';
import { colors } from '../../theme';
import SteielButton from '../ui/SteielButton';
import { assets, CustomSvgIcon } from '../../helpers/assets';
import { Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { PageName } from '../../routes';
import Center from '../ui/Center';
import { User } from '../../graphql/codegen/graphql-react';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { defaultMUIDataTableOptions } from '../../helpers/datatableUtils';
import { dayjs } from '../../helpers/utils';
import { useMQ } from '../../hook/useMQ';

const options: MUIDataTableOptions = {
  ...defaultMUIDataTableOptions,
};
interface CustomersTableProps {
  users: User[];
}

const CustomersTable: React.FC<CustomersTableProps> = ({ users }) => {
  const navigate = useNavigate();
  const { isMobile } = useMQ();
  const dateTimeFile = dayjs().format('YYYY-MM-DD_HH_mm');

  const tableUsers = ([...(users || [])].map((u, i) => {
    // const lastConnection = d.modules?.filter(el => el?.online).map(el => el?.lastConnection).sort()
    return {
      id: u?.id || `${i}`,
      name: u?.name || '',
      email: u?.email || '',
      place: u.place || '',
    };
  }) || []) as {
    id: string;
    name: string;
    email: string;
    place: string;
  }[];

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'email',
      label: ls.email.toUpperCase(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: ls.name.toUpperCase(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'place',
      label: ls.place.toUpperCase(),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: '',
      label: ls.notifications.toUpperCase(),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        download: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box>
              <SteielButton
                text={ls.manageNotifications}
                onClick={() => {
                  navigate(PageName.notificationsManagement);
                }}
              />
            </Box>
          );
        },
      },
    },
    {
      name: '',
      label: ls.settings.toUpperCase(),
      options: {
        filter: false,
        sort: false,
        searchable: false,
        download: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Center justifyContent={isMobile ? 'flex-start' : 'center'}>
              <Link to={PageName.users}>
                <Center>
                  <Box mb={-2} width={35}>
                    <CustomSvgIcon
                      src={assets.settingsBlue}
                      style={{ width: 24, height: 24 }}
                      onClick={() => {}}
                    />
                  </Box>
                </Center>
              </Link>
            </Center>
          );
        },
      },
    },
  ];

  return (
    <MUIDataTable
      title=''
      data={tableUsers}
      columns={columns}
      options={{
        ...options,
        downloadOptions: {
          filename: `Customers_${dateTimeFile}.csv`,
        },
        rowsPerPage: localStorage.getItem('customers_rowsPerPage')
          ? Number(localStorage.getItem('customers_rowsPerPage'))
          : 10,
        onChangeRowsPerPage: (numberOfRows) => {
          localStorage.setItem('customers_rowsPerPage', `${numberOfRows}`);
        },
      }}
    />
  );
  // return (
  //   <TableContainer style={{ height: 600 }}>
  //     <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
  //       <TableHead>
  //         <TableRow>
  //           <TableCell>
  //             <Body2 weight={600} color={colors.grey}>
  //               {ls.name.toUpperCase()}
  //             </Body2>
  //           </TableCell>
  //           <TableCell>
  //             <Body2 weight={600} color={colors.grey}>
  //               {ls.locality.toUpperCase()}
  //             </Body2>
  //           </TableCell>
  //           <TableCell>
  //             <Body2 weight={600} color={colors.grey}>
  //               {ls.notifications.toUpperCase()}
  //             </Body2>
  //           </TableCell>
  //           <TableCell align='center'>
  //             <Body2 weight={600} color={colors.grey}>
  //               {ls.settings.toUpperCase()}
  //             </Body2>
  //           </TableCell>
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {rows.map((row, i) => (
  //           <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
  //             <TableCell component='th' scope='row'>
  //               <Body2 color={colors.stroke}>{row.name}</Body2>
  //             </TableCell>
  //             <TableCell>
  //               <Body2 color={colors.stroke}>{row.locality}</Body2>
  //             </TableCell>
  //             <TableCell>
  //               <Body2>
  //                 <SteielButton
  //                   text={ls.manageNotifications}
  //                   onClick={() => {
  //                     navigate(PageName.notificationsManagement);
  //                   }}
  //                 />
  //               </Body2>
  //             </TableCell>
  //             <TableCell align='center'>
  //               <Link to={PageName.users}>
  //                 <Center>
  //                   <Box mb={-2} width={35}>
  //                     <CustomSvgIcon
  //                       src={assets.settingsBlue}
  //                       style={{ width: 24, height: 24 }}
  //                       onClick={() => {}}
  //                     />
  //                   </Box>
  //                 </Center>
  //               </Link>
  //             </TableCell>
  //           </TableRow>
  //         ))}
  //       </TableBody>
  //     </Table>
  //   </TableContainer>
  // );
};

export default CustomersTable;
