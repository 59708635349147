import React from 'react';
import DashboardLayout from '../../components/ui/DashboardLayout';
import { useNavigate } from 'react-router-dom';
import { PageName } from '../../routes';
import Flex from '../../components/ui/Flex';
import { Body1 } from '../../components/ui/Typography';
import { ls } from '../../i18n/translations';
import { Box, CircularProgress, IconButton } from '@mui/material';
import SteielButton from '../../components/ui/SteielButton';
import Center from '../../components/ui/Center';
import DevicesTable from '../../components/tables/DevicesTable';
import { useMQ } from '../../hook/useMQ';
import {
  OnlyOnDesktop,
  OnlyOnMobile,
  OnlyOnTabletAndDesktop,
} from '../../components/ui/Responsive';
import { useMount } from 'react-use';
import LoadingPage from '../../components/ui/LoadingPage';
import config from '../../config';
import ErrorMessage from '../../components/ErrorMessage';
import { getErrorMessage, logErr } from '../../helpers/utils';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { colors } from '../../theme';
import { useDevices } from '../../hook/useDevices';
import DeviceStatusIconLegend from '../../components/ui/DeviceStatusIconLegend';
import { useAuth } from '../../hook/useAuth';
import { useDarkMode } from '../../hook/useDarkMode';

const DevicesManagement: React.FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useMQ();
  const auth = useAuth();
  const { devices, devicesLoading, devicesError, devicesRefetch } = useDevices();
  const [isRefetching, setIsRefetching] = React.useState(false);
  const { isDarkMode } = useDarkMode();

  useMount(() => {
    if (!config.env.isDev) {
      window.scroll(0, 0);
    }
  });

  if (devicesLoading) {
    return <LoadingPage />;
  }

  return (
    <DashboardLayout>
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={getErrorMessage(devicesError)}
      />
      <Box
        borderRadius={1}
        sx={{ boxShadow: isMobile ? 0 : 1 }}
        bgcolor={
          isMobile
            ? isDarkMode
              ? colors.darkBackground
              : 'transparent'
            : isDarkMode
            ? colors.darkBackground2
            : 'white'
        }
        pb={2}
      >
        <Flex p={2} pt={isMobile ? 0 : 2}>
          <Box>
            <Body1 weight={600} size='1.1rem' color={isDarkMode ? colors.lightBlue : undefined}>
              {ls.deviceManagement}
            </Body1>
          </Box>
          <Center>
            <OnlyOnDesktop mr={2}>
              <DeviceStatusIconLegend />
            </OnlyOnDesktop>
            {!auth.isViewer ? (
              <OnlyOnTabletAndDesktop>
                <SteielButton
                  sx={{ height: 35, mr: 2 }}
                  onClick={() => {
                    navigate(PageName.newDevice);
                  }}
                >
                  <Center>
                    <Center flex={1} height='100%'>
                      <Box
                        bgcolor='#fff'
                        width={14}
                        border='1px solid'
                        borderColor='#fff'
                        borderRadius={2}
                        height={2}
                        position='relative'
                      >
                        <Box
                          position='absolute'
                          top={-7}
                          left={5}
                          bgcolor='#fff'
                          height={14}
                          border='1px solid'
                          borderColor='#fff'
                          borderRadius={2}
                          width={2}
                        />
                      </Box>
                    </Center>
                    <Box ml={1}>
                      <Body1 color='white'>{isMobile ? ls.new : ls.newDevice}</Body1>
                    </Box>
                  </Center>
                </SteielButton>
              </OnlyOnTabletAndDesktop>
            ) : null}
            <Box width={42}>
              <IconButton
                disabled={isRefetching}
                onClick={async () => {
                  try {
                    setIsRefetching(true);
                    await devicesRefetch();
                  } catch (error) {
                    logErr('error on refetch devices', error);
                  } finally {
                    setIsRefetching(false);
                  }
                }}
              >
                {isRefetching ? (
                  <Center height={22} width={22}>
                    <CircularProgress size={20} color={isDarkMode ? 'secondary' : undefined} />
                  </Center>
                ) : (
                  <Center height={22} width={22}>
                    <RefreshIcon style={{ color: isDarkMode ? colors.lightBlue : colors.blue }} />
                  </Center>
                )}
              </IconButton>
            </Box>
          </Center>
        </Flex>
        {!auth.isViewer ? (
          <OnlyOnMobile display='flex' justifyContent='flex-start' alignItems='center' width='100%'>
            <SteielButton
              sx={{ height: 35, ml: 2 }}
              onClick={() => {
                navigate(PageName.newDevice);
              }}
            >
              <Center>
                <Center flex={1} height='100%'>
                  <Box
                    bgcolor='#fff'
                    width={14}
                    border='1px solid'
                    borderColor='#fff'
                    borderRadius={2}
                    height={2}
                    position='relative'
                  >
                    <Box
                      position='absolute'
                      top={-7}
                      left={5}
                      bgcolor='#fff'
                      height={14}
                      border='1px solid'
                      borderColor='#fff'
                      borderRadius={2}
                      width={2}
                    />
                  </Box>
                </Center>
                <Box ml={1}>
                  <Body1 color='white'>{isMobile ? ls.new : ls.newDevice}</Body1>
                </Box>
              </Center>
            </SteielButton>
          </OnlyOnMobile>
        ) : null}
        <OnlyOnMobile px={2} pt={1}>
          <Flex>
            <Center>
              <DeviceStatusIconLegend />
            </Center>
          </Flex>
        </OnlyOnMobile>
        <OnlyOnTabletAndDesktop>
          <Box className='separator' width='100%' />
        </OnlyOnTabletAndDesktop>
        <OnlyOnDesktop>
          <Box mt={-1} />
        </OnlyOnDesktop>
        <Box mt={isMobile ? 4 : 2} mx={isMobile ? 2 : undefined}>
          <DevicesTable isDeviceManagement devices={devices} />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default DevicesManagement;
