import React from 'react';
import DashboardLayout from '../../components/ui/DashboardLayout';
import { PageName } from '../../routes';
import Flex from '../../components/ui/Flex';
import { Body1 } from '../../components/ui/Typography';
import { ls } from '../../i18n/translations';
import { Box } from '@mui/material';
import EditCustomerForm from '../../components/forms/EditCustomerForm';
import Breadcrumbs from '../../components/ui/Breadcrumbs';
import { useMQ } from '../../hook/useMQ';
import { useMount } from 'react-use';
import config from '../../config';

const EditCustomer: React.FC = () => {
  const { isMobileOrTablet } = useMQ();

  useMount(() => {
    if (!config.env.isDev) {
      window.scroll(0, 0);
    }
  });

  return (
    <DashboardLayout>
      <Box p={2} pt={isMobileOrTablet ? 0 : 2}>
        <Breadcrumbs
          // comeBackLink={PageName.customers}
          backPageName={ls.managerList}
        />
      </Box>
      <Box mx={isMobileOrTablet ? 2 : 0}>
        <Box borderRadius={1} sx={{ boxShadow: 1, maxWidth: 1000 }} bgcolor='white' pb={2}>
          <Flex p={2}>
            <Box>
              <Body1 weight={600} size='1.1rem'>
                {ls.modifyManager}
              </Body1>
            </Box>
          </Flex>
          <Box className='separator' width='100%' />
          <Box m={2}>
            <EditCustomerForm />
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default EditCustomer;
