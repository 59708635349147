import React from 'react';
import { Box, BoxProps, InputAdornment, LinearProgress } from '@mui/material';
import { ls } from '../../i18n/translations';
import SteielTextInput from '../ui/SteielTextInput';
import SteielButton from '../ui/SteielButton';
import ErrorMessage from '../ErrorMessage';
import { Form, Formik } from 'formik';
import {
  PASSWORD_MIN_LENGTH,
  validateEmail,
  validatePassword,
  validateRequiredField,
} from '../../helpers/validators';
import { capitalize, catchErrors, isObjEmpty, logErr } from '../../helpers/utils';
import { useAuth } from '../../hook/useAuth';
import { Link, useNavigate } from 'react-router-dom';
import { PageName } from '../../routes';
import { Body1, Body2, H2 } from '../ui/Typography';
import { useMQ } from '../../hook/useMQ';
import { colors } from '../../theme';
import { useWindowSize } from 'react-use';
import Center from '../ui/Center';
import Flex from '../ui/Flex';
import { assets, CustomSvgIcon } from '../../helpers/assets';
import SteielDialog from '../ui/SteielDialog';
import { useDarkMode } from '../../hook/useDarkMode';

interface FormData {
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;
}

const initialFormData: FormData = {
  currentPassword: '',
  newPassword: '',
  repeatPassword: '',
};

const ChangePasswordForm: React.FC = () => {
  const [errorFromServer, setErrorFromServer] = React.useState('');
  const [showDialog, setShowDialog] = React.useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const { isMobileOrTablet } = useMQ();
  const windowSize = useWindowSize();
  const { isDarkMode } = useDarkMode();

  const labelWidth = isMobileOrTablet ? undefined : 180;
  const textFieldWidth = isMobileOrTablet ? '100%' : 500;
  const rootBoxWidth = isMobileOrTablet
    ? '100%'
    : // @ts-ignore
      labelWidth + textFieldWidth;

  return (
    <Box>
      <Box width={rootBoxWidth}>
        <Formik
          initialValues={initialFormData}
          validate={(values) => {
            const errors: Partial<FormData> = {};
            const currentPassword = validateRequiredField(values.currentPassword);
            const newPassword = validatePassword(values.newPassword);
            if (!newPassword.isValid) {
              errors.newPassword = ls
                .getString(newPassword.errorTranslationKey)
                .replace('{0}', `${PASSWORD_MIN_LENGTH}`);
            }
            if (values.newPassword !== values.repeatPassword) {
              errors.newPassword = ls.passwordMismatch;
            }
            if (!currentPassword.isValid) {
              errors.currentPassword = ls.getString(currentPassword.errorTranslationKey);
            }
            // reset errorFromServer
            if (!isObjEmpty(errors)) {
              setErrorFromServer('');
            }
            return errors;
          }}
          onSubmit={async (values, actions) => {
            try {
              // log('submitting', values);
              setErrorFromServer('');
              const res = await auth.updateProfile({
                oldPassword: values.currentPassword,
                newPassword: values.newPassword,
              });
              if (res?.data?.updateProfile?.id !== auth.user?.id) {
                throw new Error(ls.sww);
              }
              setShowDialog(true);
            } catch (error) {
              logErr('error on submit password recovery form', error);
              catchErrors(error, setErrorFromServer);
            } finally {
              actions.setSubmitting(false);
            }
          }}
        >
          {(props) => {
            const currentPasswordError =
              (props.dirty && props.touched.currentPassword && props.errors.currentPassword) || '';
            const newPasswordError =
              (props.dirty && props.touched.newPassword && props.errors.newPassword) || '';
            const firstError = currentPasswordError || newPasswordError || errorFromServer || '';

            return (
              <Form>
                <Box mt={3}>
                  <SteielTextInput
                    fieldName='currentPassword'
                    formProps={props}
                    eyeIconColor={colors.lightGrey}
                    label={capitalize(ls.currentPassword)}
                    labelStyle={{
                      weight: 600,
                      width: labelWidth,
                    }}
                    textFieldProps={{
                      placeholder: capitalize(ls.currentPassword),
                      required: true,
                      error: Boolean(currentPasswordError),
                      size: 'small',
                      style: {
                        backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                        width: textFieldWidth,
                      },
                    }}
                    isPassword
                  />
                  <Box mt={1.5}>
                    <SteielTextInput
                      fieldName='newPassword'
                      formProps={props}
                      eyeIconColor={colors.lightGrey}
                      label={capitalize(ls.newPassword)}
                      labelStyle={{
                        weight: 600,
                        width: labelWidth,
                      }}
                      textFieldProps={{
                        placeholder: capitalize(ls.newPassword),
                        required: true,
                        error: Boolean(newPasswordError),
                        size: 'small',
                        style: {
                          backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                          width: textFieldWidth,
                        },
                      }}
                      isPassword
                    />
                  </Box>
                  <Box mt={1.5}>
                    <SteielTextInput
                      fieldName='repeatPassword'
                      formProps={props}
                      eyeIconColor={colors.lightGrey}
                      label={capitalize(ls.repeatPassword)}
                      labelStyle={{
                        weight: 600,
                        width: labelWidth,
                      }}
                      textFieldProps={{
                        placeholder: capitalize(ls.repeatPassword),
                        required: true,
                        error: false,
                        size: 'small',
                        style: {
                          backgroundColor: isDarkMode ? colors.darkBackground2 : '#fff',
                          width: textFieldWidth,
                        },
                      }}
                      isPassword
                    />
                  </Box>
                </Box>
                {firstError ? (
                  <ErrorMessage
                    containerStyle={{
                      my: 2,
                    }}
                    message={firstError}
                  />
                ) : (
                  <Box />
                )}
                {props.isSubmitting && (
                  <Box my={2}>
                    <LinearProgress />
                  </Box>
                )}
                <Flex
                  mt={isMobileOrTablet ? 4 : 3}
                  mb={2}
                  width={
                    isMobileOrTablet
                      ? undefined
                      : // @ts-ignore
                        textFieldWidth + labelWidth
                  }
                >
                  <Box flex={1} />
                  <Box width={150}>
                    <SteielButton
                      text={ls.apply}
                      type='submit'
                      disabled={props.isSubmitting}
                      fullWidth
                      style={{
                        backgroundColor: colors.green,
                        borderColor: colors.green,
                      }}
                    />
                  </Box>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </Box>
      <SteielDialog
        title={capitalize(ls.changesCompleted, false)}
        content={capitalize(ls.passwordChangedSuccessfully)}
        onClose={() => {
          setShowDialog(false);
        }}
        onOkActionClick={() => {
          setShowDialog(false);
        }}
        open={showDialog}
        okLabel={capitalize(ls.ok)}
      />
    </Box>
  );
};

export default ChangePasswordForm;
