import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from,
  fromPromise,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import config from '../config';
import { authState } from '../store/authStore';
import { onError } from '@apollo/client/link/error';
import { dayjs, log } from '../helpers/utils';
import { RetryLink } from '@apollo/client/link/retry';
import sdkClient from './sdkClient';
import { sessionData } from '../helpers/localStorage';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const httpLink = new HttpLink({ uri: config.env.endpoint });

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  const { token } = parseCookies();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});

const getNewToken = async () => {
  try {
    const session = sessionData.get();
    if (session) {
      const { authenticate } = await sdkClient().authenticate({ input: { session } });
      if (authenticate && authenticate.jwtToken && authenticate.session) {
        sessionData.set(authenticate.session);
        authState.user = authenticate.user;
        authState.jwtToken = authenticate.jwtToken;
        setCookie(null, 'token', authenticate.jwtToken, {
          path: '/',
          expires: dayjs().utc().add(1, 'year').toDate(),
        });
        log('new token generated successfully');
        return authenticate.jwtToken;
      }
    }
    throw new Error('empty session');
  } catch (error) {
    log('error on getNewToken', error);
    destroyCookie(null, 'token');
    sessionData.remove();
    window.location.reload();
    return '';
  }
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations?.toString()}, Path: ${path}`,
      );
    });
    for (const err of graphQLErrors) {
      // refresh expired token
      if (
        err &&
        err.message &&
        typeof err.message === 'string' &&
        err.message.toLowerCase().includes('expired')
      ) {
        console.log('refreshing token...');
        return fromPromise(
          getNewToken().catch((error) => {
            // Handle token refresh errors e.g clear stored tokens, redirect to login
            console.log('error on getNewToken', error);
          }),
        )
          .filter((value) => Boolean(value))
          .flatMap((accessToken) => {
            const oldHeaders = operation.getContext().headers;
            // modify the operation context with a new token
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${accessToken}`,
              },
            });

            // retry the request, returning the new observable
            return forward(operation);
          });
      }
    }
  } else if (networkError) {
    console.log('Network error', networkError.stack);
  }
});

const retryLink = new RetryLink({ attempts: { max: 3 }, delay: { max: 3000 } });

const mergedLink = from([errorLink, retryLink, authLink, httpLink]);

// eslint-disable-next-line prefer-const
apolloClient = new ApolloClient({
  link: mergedLink,
  cache: new InMemoryCache(),
});

export { apolloClient };
