import React from 'react';
import { Box, CircularProgress, IconButton, TextField } from '@mui/material';
import {
  AlarmHistory,
  Device,
  Maybe,
  Module,
  useAlarmsHistoryQuery,
} from '../graphql/codegen/graphql-react';
import { capitalize, dayjs, getErrorMessage, logErr } from '../helpers/utils';
import ErrorMessage from './ErrorMessage';
import Center from './ui/Center';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSetState } from 'react-use';
import { ls } from '../i18n/translations';
import Flex from './ui/Flex';
import { useMQ } from '../hook/useMQ';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { colors } from '../theme';
import DeviceAlarmsTable from './tables/DeviceAlarmsTable';
import { useDarkMode } from '../hook/useDarkMode';

interface AlarmsDetailProps {
  module: Maybe<Module>;
}

const AlarmsDetail: React.FC<AlarmsDetailProps> = ({ module }) => {
  const {
    loading: alarmsHistoryLoading,
    error: alarmsHistoryError,
    data: alarmsHistoryData,
    refetch: alarmsHistoryRefetch,
  } = useAlarmsHistoryQuery({ variables: { input: { moduleId: module?.id || 'n/a' } } });
  const [state, setState] = useSetState<{
    from: dayjs.Dayjs | null;
    to: dayjs.Dayjs | null;
    isRefetching: boolean;
  }>({
    from: null,
    to: null,
    isRefetching: false,
  });
  const { isMobile, isMobileOrTablet } = useMQ();
  const { isDarkMode } = useDarkMode();

  if (!module) {
    return null;
  }

  if (alarmsHistoryLoading) {
    return (
      <Center mt={2} mb={6}>
        <CircularProgress />
      </Center>
    );
  }

  const alarmsData = (alarmsHistoryData?.alarmsHistory || []) as AlarmHistory[];

  return (
    <Box my={2}>
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={getErrorMessage(alarmsHistoryError)}
      />
      <Flex alignItems='flex-start' mt={0.5}>
        <Flex
          ml={2}
          justifyContent={isMobileOrTablet ? 'center' : 'flex-start'}
          alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
          flexDirection={isMobileOrTablet ? 'column' : 'row'}
        >
          <Box>
            <DatePicker
              renderInput={(props) => <TextField size='small' {...props} />}
              label={capitalize(ls.from)}
              value={state.from}
              onChange={(newValue) => {
                setState({ from: newValue });
                alarmsHistoryRefetch({
                  input: {
                    moduleId: module?.id || 'n/a',
                    from: newValue ? newValue.toDate() : undefined,
                    to: state.to ? state.to.toDate() : undefined,
                  },
                }).catch((err) => {
                  logErr(err);
                });
              }}
              disableFuture
              inputFormat='YYYY/MM/DD'
              InputProps={{
                style: {
                  fontSize: '1.1rem',
                },
              }}
            />
          </Box>
          <Box mt={isMobileOrTablet ? 1 : 0} ml={isMobileOrTablet ? 0 : 4}>
            <DatePicker
              renderInput={(props) => <TextField size='small' {...props} />}
              label={capitalize(ls.to)}
              value={state.to}
              onChange={(newValue) => {
                setState({ to: newValue });
                alarmsHistoryRefetch({
                  input: {
                    moduleId: module?.id || 'n/a',
                    from: state.from ? state.from.toDate() : undefined,
                    to: newValue ? newValue.toDate() : undefined,
                  },
                }).catch((err) => {
                  logErr(err);
                });
              }}
              inputFormat='YYYY/MM/DD'
              disableFuture
              InputProps={{
                style: {
                  fontSize: '1.1rem',
                },
              }}
            />
          </Box>
        </Flex>
        <Box mx={2}>
          <IconButton
            disabled={state.isRefetching}
            onClick={async () => {
              try {
                setState({ isRefetching: true });
                await alarmsHistoryRefetch({
                  input: {
                    moduleId: module?.id || 'n/a',
                    from: state.from ? state.from.toDate() : undefined,
                    to: state.to ? state.to.toDate() : undefined,
                  },
                });
              } catch (error) {
                logErr('error on refetch measurementHistory', error);
              } finally {
                setState({ isRefetching: false });
              }
            }}
          >
            {state.isRefetching ? (
              <Center height={22}>
                <CircularProgress size={20} />
              </Center>
            ) : (
              <Center height={22}>
                <RefreshIcon style={{ color: isDarkMode ? colors.lightBlue : colors.blue }} />
              </Center>
            )}
          </IconButton>
        </Box>
      </Flex>
      <Box mt={isMobile ? 2 : 0}>
        <DeviceAlarmsTable data={alarmsData} module={module} />
      </Box>
    </Box>
  );
};

export default AlarmsDetail;
