import React from 'react';
import { Box, MenuItem, Select } from '@mui/material';
import { useSetState } from 'react-use';

interface SteielSelectProps {
  allowedValues: string[];
  onChange: (value: string) => void;
  initialVal?: string;
}

const SteielSelect: React.FC<SteielSelectProps> = ({ initialVal, onChange, allowedValues }) => {
  const compIdRef = React.useRef(`${Date.now()}_${Math.random()}`);
  const [state, setState] = useSetState({
    selectValue: initialVal ?? (allowedValues || [''])[0] ?? '',
  });
  if (allowedValues.length === 0) {
    return null;
  }

  const menuItems: React.ReactNode[] = [];
  for (let i = 0; i < allowedValues.length; i++) {
    const allowedValue = allowedValues[i];
    menuItems.push(
      <MenuItem key={allowedValue} value={allowedValue}>
        {`${allowedValue}`}
      </MenuItem>,
    );
  }
  return (
    <Box>
      <Select
        labelId={`label id select_${compIdRef.current}`}
        id={`id-select-${compIdRef.current}`}
        value={state.selectValue}
        size='small'
        fullWidth
        style={{ fontSize: '1.1rem', height: 40 }}
        onChange={(e) => {
          setState({
            selectValue: e.target.value,
          });
          onChange(e.target.value);
        }}
      >
        {menuItems}
      </Select>
    </Box>
  );
};

export default SteielSelect;
