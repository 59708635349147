import React from 'react';
import DashboardLayout from '../../components/ui/DashboardLayout';
import { useNavigate } from 'react-router-dom';
import { PageName } from '../../routes';
import Flex from '../../components/ui/Flex';
import { Body1 } from '../../components/ui/Typography';
import { ls } from '../../i18n/translations';
import { Box, CircularProgress, IconButton, TextField } from '@mui/material';
import SteielButton from '../../components/ui/SteielButton';
import Center from '../../components/ui/Center';
import CustomersTable from '../../components/tables/CustomersTable';
import { useMQ } from '../../hook/useMQ';
import { OnlyOnTabletAndDesktop } from '../../components/ui/Responsive';
import { useMount } from 'react-use';
import config from '../../config';
import { useUsers } from '../../hook/useUsers';
import LoadingPage from '../../components/ui/LoadingPage';
import ErrorMessage from '../../components/ErrorMessage';
import { getErrorMessage, logErr } from '../../helpers/utils';
import { UserRole } from '../../graphql/codegen/graphql-react';
import { colors } from '../../theme';
import { Refresh as RefreshIcon } from '@mui/icons-material';

const Customers: React.FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useMQ();
  const { users, usersError, usersLoading, usersRefetch } = useUsers();
  const [isRefetching, setIsRefetching] = React.useState(false);

  useMount(() => {
    if (!config.env.isDev) {
      window.scroll(0, 0);
    }
  });

  if (usersLoading) {
    return <LoadingPage />;
  }

  const customers = users.filter(
    (u) => u.roles?.includes(UserRole.Supertechnician) || u.roles?.includes(UserRole.Admin),
  );

  return (
    <DashboardLayout>
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={getErrorMessage(usersError)}
      />
      <Box
        borderRadius={1}
        sx={{ boxShadow: isMobile ? 0 : 1 }}
        bgcolor={isMobile ? 'transparent' : 'white'}
        pb={2}
      >
        <Flex p={isMobile ? 0 : 2} mx={isMobile ? 2 : 0}>
          <Box>
            <Body1 weight={600} size='1.1rem'>
              {ls.managerList}
            </Body1>
          </Box>
          <Center>
            <Box>
              <SteielButton
                sx={{ height: 35, mr: 2 }}
                onClick={() => {
                  navigate(PageName.newCustomer);
                }}
              >
                <Center>
                  <Center flex={1} height='100%'>
                    <Box
                      bgcolor='#fff'
                      width={14}
                      border='1px solid'
                      borderColor='#fff'
                      borderRadius={2}
                      height={2}
                      position='relative'
                    >
                      <Box
                        position='absolute'
                        top={-7}
                        left={5}
                        bgcolor='#fff'
                        height={14}
                        border='1px solid'
                        borderColor='#fff'
                        borderRadius={2}
                        width={2}
                      />
                    </Box>
                  </Center>
                  <Box ml={1}>
                    <Body1 color='white'>{isMobile ? ls.new : ls.newManager}</Body1>
                  </Box>
                </Center>
              </SteielButton>
            </Box>
            <Box width={42}>
              <IconButton
                disabled={isRefetching}
                onClick={async () => {
                  try {
                    setIsRefetching(true);
                    await usersRefetch();
                  } catch (error) {
                    logErr('error on refetch devices', error);
                  } finally {
                    setIsRefetching(false);
                  }
                }}
              >
                {isRefetching ? (
                  <Center height={22}>
                    <CircularProgress size={20} />
                  </Center>
                ) : (
                  <Center height={22}>
                    <RefreshIcon style={{ color: colors.blue }} />
                  </Center>
                )}
              </IconButton>
            </Box>
          </Center>
        </Flex>
        <OnlyOnTabletAndDesktop>
          <Box className='separator' width='100%' />
        </OnlyOnTabletAndDesktop>
        <Box mt={isMobile ? 4 : 2}>
          <CustomersTable users={customers} />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Customers;
