import { Box } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { CustomSvgIcon, assets } from '../../helpers/assets';
import { ls } from '../../i18n/translations';
import { colors } from '../../theme';
import Flex from './Flex';
import { Body1, Body2 } from './Typography';
import { useNavigate } from 'react-router-dom';

interface BreadcrumbsProps {
  backPageName: string;
  comeBackLink?: string;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ comeBackLink, backPageName }) => {
  const navigation = useNavigate();

  const LinkComeBack: React.ReactNode = (
    <Flex>
      <Box>
        <CustomSvgIcon src={assets.minor} onClick={() => {}} />
      </Box>
      <Box flex={1} mb={0.5} ml={1}>
        <Body1 size='1.1rem' weight={600}>
          {ls.comeback}
        </Body1>
      </Box>
    </Flex>
  );
  return (
    <Flex>
      {comeBackLink ? (
        <Link to={comeBackLink} style={{ textDecoration: 'none' }}>
          {LinkComeBack}
        </Link>
      ) : (
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigation(-1);
          }}
        >
          {LinkComeBack}
        </Box>
      )}
      <Box flex={1} mb={0.45} ml={0.5}>
        <Body2 size='1.1rem' weight={400} color={colors.lightGrey}>
          {' / '}
          {backPageName}
        </Body2>
      </Box>
    </Flex>
  );
};

export default Breadcrumbs;
