import React from 'react';
import DashboardLayout from '../../components/ui/DashboardLayout';
import { PageName } from '../../routes';
import Flex from '../../components/ui/Flex';
import { Body1, Body2 } from '../../components/ui/Typography';
import { ls } from '../../i18n/translations';
import { Box } from '@mui/material';
import { colors } from '../../theme';
import { capitalize, getErrorMessage } from '../../helpers/utils';
import SteielButton from '../../components/ui/SteielButton';
import SamplingTimeSelect from '../../components/selects/SamplingTimeSelect';
// import SelectTimezoneMaterialUi from 'select-timezone-material-ui';
import DeviceSetupTable from '../../components/tables/DeviceSetupTable';
import { useMQ } from '../../hook/useMQ';
import Breadcrumbs from '../../components/ui/Breadcrumbs';
import { useMount, useSetState } from 'react-use';
import config from '../../config';
import { useSearchParams } from '../../hook/useSearchParams';
import { useDevice } from '../../hook/useDevice';
import LoadingPage from '../../components/ui/LoadingPage';
import ErrorMessage from '../../components/ErrorMessage';
import { Module } from '../../graphql/codegen/graphql-react';

const DeviceSetup: React.FC = () => {
  const { isMobile } = useMQ();
  const { deviceIdParam } = useSearchParams();
  const { device, deviceError, deviceLoading } = useDevice(deviceIdParam);

  useMount(() => {
    if (!config.env.isDev) {
      window.scroll(0, 0);
    }
  });

  if (deviceLoading) {
    return <LoadingPage />;
  }

  const deviceModules: Module[] = (device.modules || []) as Module[];

  return (
    <DashboardLayout>
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={getErrorMessage(deviceError)}
      />
      <Box p={2} pt={isMobile ? 0 : 2}>
        <Breadcrumbs
          backPageName={ls.deviceDetails}
          // comeBackLink={PageName.device.replace('{0}', deviceIdParam)}
        />
        <Flex mt={4} justifyContent='flex-start'>
          <Box>
            <Body2 size='1.1rem' weight={600}>
              {`${capitalize(ls.name)}:`}
            </Body2>
          </Box>
          <Box ml={0.5}>
            <Body2 size='1.1rem' color={colors.stroke}>
              {'Test Piscine'}
            </Body2>
          </Box>
        </Flex>
        <Flex
          justifyContent='flex-start'
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'flex-start' : undefined}
          mt={isMobile ? 0.5 : 0}
        >
          <Flex
            justifyContent='flex-start'
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'center'}
          >
            <Box>
              <Body2 size='1.1rem' weight={600}>
                {`${ls.samplingTime}:`}
              </Body2>
            </Box>
            <Box ml={isMobile ? 0 : 1} mt={isMobile ? 1 : 0}>
              <SamplingTimeSelect onChange={(min) => {}} />
            </Box>
          </Flex>
          <Flex
            justifyContent='flex-start'
            ml={isMobile ? 0 : 4}
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            mt={isMobile ? 0.5 : 0}
          >
            <Box>
              <Body2 size='1.1rem' weight={600}>
                {`${ls.timeZone}:`}
              </Body2>
            </Box>
            {/* <Box ml={isMobile ? 0 : 1} mt={isMobile ? -0.5 : -1} width={200}>
              <SelectTimezoneMaterialUi
                onChange={(tz) => {
                  console.log('on tz', tz);
                }}
              />
            </Box> */}
          </Flex>
        </Flex>
      </Box>
      <Box mt={2} pb={2} bgcolor='white' borderRadius={1} sx={{ boxShadow: 1 }}>
        <Box p={2}>
          <Body1 weight={600} size={'1.2rem'}>
            {ls.setup}
          </Body1>
        </Box>
        <Box className='separator' width='100%' />
        <Box mt={2}>
          <DeviceSetupTable />
          <Flex mt={1}>
            <Box flex={1} />
            <Box mr={2}>
              <SteielButton
                style={{ borderColor: colors.green, backgroundColor: colors.green, width: 150 }}
                text={ls.apply}
              />
            </Box>
          </Flex>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default DeviceSetup;
