import React from 'react';
import { Box, Radio, RadioProps, TypographyProps } from '@mui/material';
import { Body2 } from './Typography';
import { colors } from '../../theme';
import { useDarkMode } from '../../hook/useDarkMode';

interface SteielRadioButtonProps {
  label: string;
  checked: boolean;
  onClick: () => void;
  labelProps?: TypographyProps;
}
const SteielRadioButton: React.FC<SteielRadioButtonProps & RadioProps> = ({
  label,
  checked,
  onClick,
  labelProps,
  ...radioProps
}) => {
  const { isDarkMode } = useDarkMode();
  return (
    <Box display='flex' flexDirection='row' alignItems='center'>
      <Radio
        checked={checked}
        onClick={onClick}
        style={{
          color: checked ? (isDarkMode ? colors.lightBlue : colors.blue) : colors.lightGrey,
        }}
        {...radioProps}
      />
      <Body2 style={{ cursor: 'pointer' }} onClick={onClick} {...labelProps}>
        {label}
      </Body2>
    </Box>
  );
};

export default SteielRadioButton;
