import React from 'react';
import DashboardLayout from '../../components/ui/DashboardLayout';
import { PageName } from '../../routes';
import Flex from '../../components/ui/Flex';
import { Body1 } from '../../components/ui/Typography';
import { ls } from '../../i18n/translations';
import { Box } from '@mui/material';
import { colors } from '../../theme';
import SteielButton from '../../components/ui/SteielButton';
import Breadcrumbs from '../../components/ui/Breadcrumbs';
import EditUserForm from '../../components/forms/EditUserForm';
import { useMQ } from '../../hook/useMQ';
import { useMount, useSetState } from 'react-use';
import config from '../../config';
import sdkClient from '../../graphql/sdkClient';
import SteielDialog from '../../components/ui/SteielDialog';
import { capitalize, catchErrors, getErrorMessage, logErr } from '../../helpers/utils';
import { useSearchParams } from '../../hook/useSearchParams';
import { useUser } from '../../hook/useUser';
import ErrorMessage from '../../components/ErrorMessage';
import LoadingPage from '../../components/ui/LoadingPage';
import { useUsers } from '../../hook/useUsers';
import { useNavigate } from 'react-router-dom';
import { Action } from '../../graphql/codegen/graphql-react';
import { useDarkMode } from '../../hook/useDarkMode';

const EditUser: React.FC = () => {
  const { isMobileOrTablet } = useMQ();
  const [errorFromServer, setErrorFromServer] = React.useState('');
  const { userIdParam } = useSearchParams();
  const { user, userError, userLoading } = useUser(userIdParam);
  const { usersError, usersLoading, usersRefetch } = useUsers();
  const [state, setState] = useSetState({ showDeleteUserDialog: false, deletingUser: false });
  const navigate = useNavigate();
  const { isDarkMode } = useDarkMode();

  useMount(() => {
    if (!config.env.isDev) {
      window.scroll(0, 0);
    }
  });

  const deleteUser = async () => {
    try {
      setErrorFromServer('');
      setState({ deletingUser: true });
      await sdkClient().manageUser({ input: { action: Action.Delete, userId: user.id } });
      await usersRefetch();
      navigate(PageName.users);
    } catch (error) {
      logErr('error deleting user', user.id, error);
      catchErrors(error, setErrorFromServer);
    } finally {
      setState({ deletingUser: false });
    }
  };

  if (userLoading || state.deletingUser || usersLoading) {
    return <LoadingPage />;
  }

  return (
    <DashboardLayout>
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={getErrorMessage(errorFromServer)}
      />
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={getErrorMessage(userError)}
      />
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={getErrorMessage(usersError)}
      />
      <Box p={2} pt={isMobileOrTablet ? 0 : 2}>
        <Breadcrumbs
          //  comeBackLink={PageName.users}
          backPageName={ls.usersList}
        />
      </Box>
      <Box mx={isMobileOrTablet ? 2 : 0}>
        <Box
          borderRadius={1}
          sx={{ boxShadow: 1, maxWidth: 1000 }}
          bgcolor={isDarkMode ? colors.darkBackground2 : 'white'}
          pb={2}
        >
          <Flex p={2}>
            <Box>
              <Body1 weight={600} size='1.1rem'>
                {ls.modifyUser}
              </Body1>
            </Box>
            <Box>
              <SteielButton
                sx={{ height: 35 }}
                text={ls.deleteUser}
                style={{
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                }}
                typographyProps={{
                  style: { color: colors.red, textDecoration: 'underline' },
                  weight: 600,
                }}
                onClick={() => {
                  setState({ showDeleteUserDialog: true });
                }}
              />
            </Box>
          </Flex>
          <Box className='separator' width='100%' />
          <Box m={2}>
            <EditUserForm />
          </Box>
        </Box>
      </Box>
      <SteielDialog
        title={capitalize(ls.attention)}
        content={capitalize(ls.deleteUserQuestion.replace('{0}', user.email || '-'))}
        onClose={() => {
          setState({ showDeleteUserDialog: false });
        }}
        onOkActionClick={() => {
          setState({ showDeleteUserDialog: false });
          deleteUser();
        }}
        onKoActionClick={() => {
          setState({ showDeleteUserDialog: false });
        }}
        open={state.showDeleteUserDialog}
      />
    </DashboardLayout>
  );
};

export default EditUser;
