import React from 'react';
import DashboardLayout from '../../components/ui/DashboardLayout';
import { PageName } from '../../routes';
import Flex from '../../components/ui/Flex';
import { Body2 } from '../../components/ui/Typography';
import { ls } from '../../i18n/translations';
import { Box } from '@mui/material';
import { colors } from '../../theme';
import { capitalize, getErrorMessage, logErr } from '../../helpers/utils';
import Center from '../../components/ui/Center';
import DeviceInterface from '../../components/DeviceInterface';
import { useMount } from 'react-use';
import { useMQ } from '../../hook/useMQ';
import Breadcrumbs from '../../components/ui/Breadcrumbs';
import { OnlyOnMobile, OnlyOnTabletAndDesktop } from '../../components/ui/Responsive';
import config from '../../config';
import { useSearchParams } from '../../hook/useSearchParams';
import { useDevice } from '../../hook/useDevice';
import LoadingPage from '../../components/ui/LoadingPage';
import ErrorMessage from '../../components/ErrorMessage';
import DeviceMeasurementsForm from '../../components/forms/DeviceMeasurementsForm';
import { MeasurementsType } from '../../graphql/dataTypes';
import DeviceDetailInfo from '../../components/DeviceDetailInfo';
import { useDarkMode } from '../../hook/useDarkMode';

const DeviceMeasurements: React.FC = () => {
  const { isMobile, isMobileOrTablet } = useMQ();
  const { isDarkMode } = useDarkMode();
  const { deviceIdParam, moduleIdParam } = useSearchParams();
  const { device, deviceError, deviceLoading, getModuleSettings, getModuleById, deviceRefetch } =
    useDevice(deviceIdParam);

  useMount(() => {
    if (!config.env.isDev) {
      window.scroll(0, 0);
    }
    deviceRefetch().catch((err) => {
      console.error(err);
    });
  });

  if (deviceLoading) {
    return <LoadingPage />;
  }

  const deviceModule = getModuleById(moduleIdParam);
  const deviceModuleSettings = getModuleSettings(moduleIdParam);
  const deviceMeasurements = deviceModule?.Measurement?.measurements as MeasurementsType;

  return (
    <DashboardLayout>
      <ErrorMessage
        containerStyle={{ paddingBottom: 2, textAlign: 'center' }}
        message={getErrorMessage(deviceError)}
      />
      <DeviceDetailInfo
        backPageName={capitalize(ls.deviceDetails)}
        device={device}
        onResetDone={() => {
          deviceRefetch().catch((err) => {
            logErr(err);
          });
        }}
      />
      <Box mt={2} ml={4}>
        <Body2
          size={isMobile ? '1.23rem' : '1.33rem'}
          ml={0.5}
          weight={600}
          color={isDarkMode ? colors.lightBlue : colors.blue}
        >
          {capitalize(ls.customizeMeasurements, false)}
        </Body2>
      </Box>
      {Object.keys(deviceMeasurements || {}).length === 0 ||
      !deviceModuleSettings ||
      deviceModuleSettings.length === 0 ? (
        <Center mt={4}>
          <Body2 size='1.1rem' textAlign='center'>
            {capitalize(ls.noMeasurementFound)}
          </Body2>
        </Center>
      ) : (
        <>
          <Box mt={2}>
            <DeviceInterface moduleId={moduleIdParam} showCollapseBtn={false} />
          </Box>
          <Box mt={4}>
            {Object.keys(deviceModuleSettings || {}).map((ms, i) => {
              return (
                <Box key={i} mt={1.5}>
                  <DeviceMeasurementsForm
                    moduleSetting={deviceModuleSettings?.[i]}
                    title={`${capitalize(ls.measure)} ${i + 1}`}
                    index={i}
                    module={deviceModule}
                    onSettingsSaved={() => {
                      deviceRefetch();
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        </>
      )}
    </DashboardLayout>
  );
};

export default DeviceMeasurements;
