import { Box, CircularProgress, IconButton, TextField } from '@mui/material';
import React from 'react';
import { useSetState } from 'react-use';
import {
  Action,
  MachineType,
  MachineVersion,
  Maybe,
  Module,
} from '../graphql/codegen/graphql-react';
import { ParametersType } from '../graphql/dataTypes';
import sdkClient from '../graphql/sdkClient';
import { assets, CustomSvgIcon } from '../helpers/assets';
import { capitalize, getErrorMessage, log, logErr } from '../helpers/utils';
import { useMQ } from '../hook/useMQ';
import { ls } from '../i18n/translations';
import { colors } from '../theme';
import Center from './ui/Center';
import Flex from './ui/Flex';
import SteielSelect from './ui/SteielSelect';
import { Body1, Body2 } from './ui/Typography';
import validator from 'validator';
import ErrorMessage from './ErrorMessage';
import { OnlyOnDesktop, OnlyOnMobileAndTablet } from './ui/Responsive';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { allParameters, Parameter, parseAllParametersDesc } from '../helpers/allParameters';
import SteielDialog from './ui/SteielDialog';
import { useGS } from '../hook/useGS';
import { useDarkMode } from '../hook/useDarkMode';

interface ParametersDetailProps {
  module: Maybe<Module>;
  onParameterSavedSuccessfully?: () => void;
}

const ParametersDetail: React.FC<ParametersDetailProps> = ({
  module,
  onParameterSavedSuccessfully,
}) => {
  const { isMobileOrTablet } = useMQ();
  const { isDarkMode } = useDarkMode();
  const gs = useGS();
  let moduleParameters = module?.Parameter?.parameters as ParametersType;
  let parameters = allParameters.filter(
    (ap) => ap.type === module?.type && ap.version === module?.modelVersion && !ap.disabled,
  );
  parameters = parseAllParametersDesc(parameters, module);
  const primaryParameters = parameters.filter((el) => el.isFavorite);
  const secondaryParameters = parameters.filter((el) => !el.isFavorite);
  const [compKey, setCompKey] = React.useState(Date.now());
  if (module?.type === MachineType.Ef315) {
    moduleParameters = parameters.reduce((prev, curr) => {
      return { ...prev, [curr.name]: 0 };
    }, {});
  }
  const [state, setState] = useSetState<{
    moduleParameters: ParametersType;
    errorsFromServerPrimary: any[];
    errorsFromServerSecondary: any[];
    savingPrimary: boolean[];
    savingSecondary: boolean[];
    alreadyReadPrimary: boolean[];
    alreadyReadSecondary: boolean[];
    readingPrimary: boolean[];
    readingSecondary: boolean[];
    showErrorDialog: boolean;
    dialogError: string;
    showSaveConfirmDialog: boolean;
    paramToSave: {
      p: Parameter;
      pIdx: number;
      isPrimary: boolean;
    };
  }>({
    savingPrimary: Array(primaryParameters.length).fill(false),
    savingSecondary: Array(secondaryParameters.length).fill(false),
    moduleParameters,
    errorsFromServerPrimary: Array(primaryParameters.length).fill(''),
    errorsFromServerSecondary: Array(secondaryParameters.length).fill(''),
    showErrorDialog: false,
    dialogError: '',
    showSaveConfirmDialog: false,
    paramToSave: { p: {} as Parameter, pIdx: -1, isPrimary: false },
    readingPrimary: Array(primaryParameters.length).fill(false),
    readingSecondary: Array(secondaryParameters.length).fill(false),
    alreadyReadPrimary: Array(primaryParameters.length).fill(false),
    alreadyReadSecondary: Array(secondaryParameters.length).fill(false),
  });
  const moduleParametersRef = React.useRef(state.moduleParameters);

  const refreshComp = () => {
    moduleParametersRef.current = module?.Parameter?.parameters as ParametersType;
    setState({ moduleParameters: moduleParametersRef.current });
    setCompKey(Date.now());
  };

  if (!module) {
    return null;
  }

  const handleSaveParameter = async (p: Parameter, paramIdx: number, isPrimary: boolean) => {
    try {
      let parameterValue =
        // @ts-ignore
        state.moduleParameters[p.name];
      parameterValue = parseFloat(parameterValue);
      if (p.selectValues && p.selectValuesOffset) {
        parameterValue += p.selectValuesOffset || 0;
      }
      log('saving parameter...', p.name, parameterValue);
      setState((prevState) => {
        const newErrorsFromServer = [
          ...(isPrimary ? prevState.errorsFromServerPrimary : prevState.errorsFromServerSecondary),
        ];
        newErrorsFromServer[paramIdx] = '';
        const newSaving = [...(isPrimary ? prevState.savingPrimary : prevState.savingSecondary)];
        newSaving[paramIdx] = true;
        return {
          ...prevState,
          savingPrimary: isPrimary ? newSaving : prevState.savingPrimary,
          savingSecondary: !isPrimary ? newSaving : prevState.savingSecondary,
          errorsFromServerPrimary: isPrimary
            ? newErrorsFromServer
            : prevState.errorsFromServerPrimary,
          errorsFromServerSecondary: !isPrimary
            ? newErrorsFromServer
            : prevState.errorsFromServerSecondary,
        };
      });
      if (module.type === MachineType.Ef315) {
        const res = await sdkClient().handleEF315Param({
          input: {
            moduleId: module.id || 'n/a',
            parameterName: p.name,
            action: Action.Update,
            parameterValue,
          },
        });
        if (typeof res.handleEF315Param !== 'number' || isNaN(res.handleEF315Param)) {
          throw new Error(ls.sww);
        }
      } else {
        const res = await sdkClient().updateModuleParameter({
          input: {
            moduleId: module.id || 'n/a',
            parameterName: p.name,
            parameterValue,
          },
        });
        if (!res.updateModuleParameter) {
          throw new Error(ls.sww);
        }
      }
      moduleParametersRef.current = state.moduleParameters;
      onParameterSavedSuccessfully?.();
    } catch (error) {
      logErr('error saving parameter', error);
      setState((prevState) => {
        const newErrorsFromServer = [
          ...(isPrimary ? prevState.errorsFromServerPrimary : prevState.errorsFromServerSecondary),
        ];
        newErrorsFromServer[paramIdx] = error;
        return {
          ...prevState,
          errorsFromServerPrimary: isPrimary
            ? newErrorsFromServer
            : prevState.errorsFromServerPrimary,
          errorsFromServerSecondary: !isPrimary
            ? newErrorsFromServer
            : prevState.errorsFromServerSecondary,
          dialogError: getErrorMessage(error),
          showErrorDialog: true,
        };
      });
    } finally {
      setState((prevState) => {
        const newSaving = [...(isPrimary ? prevState.savingPrimary : prevState.savingSecondary)];
        newSaving[paramIdx] = false;
        return {
          ...prevState,
          savingPrimary: isPrimary ? newSaving : prevState.savingPrimary,
          savingSecondary: !isPrimary ? newSaving : prevState.savingSecondary,
        };
      });
    }
  };

  const handleReadParameter = async (p: Parameter, paramIdx: number, isPrimary: boolean) => {
    try {
      log('reading parameter...', p.name);
      setState((prevState) => {
        const newErrorsFromServer = [
          ...(isPrimary ? prevState.errorsFromServerPrimary : prevState.errorsFromServerSecondary),
        ];
        newErrorsFromServer[paramIdx] = '';
        const newReading = [...(isPrimary ? prevState.readingPrimary : prevState.readingSecondary)];
        newReading[paramIdx] = true;
        return {
          ...prevState,
          readingPrimary: isPrimary ? newReading : prevState.readingPrimary,
          readingSecondary: !isPrimary ? newReading : prevState.readingSecondary,
          errorsFromServerPrimary: isPrimary
            ? newErrorsFromServer
            : prevState.errorsFromServerPrimary,
          errorsFromServerSecondary: !isPrimary
            ? newErrorsFromServer
            : prevState.errorsFromServerSecondary,
        };
      });
      const res = await sdkClient().handleEF315Param({
        input: {
          moduleId: module.id || 'n/a',
          parameterName: p.name,
          action: Action.Read,
        },
      });
      if (typeof res.handleEF315Param !== 'number' || isNaN(res.handleEF315Param)) {
        throw new Error(ls.sww);
      }
      setState((prevState) => {
        const newModuleParameters = { ...prevState.moduleParameters };
        const newAlreadyRead = [
          ...(isPrimary ? prevState.alreadyReadPrimary : prevState.alreadyReadSecondary),
        ];
        // @ts-ignore
        newModuleParameters[p.name] = res.handleEF315Param!;
        moduleParametersRef.current = newModuleParameters;
        newAlreadyRead[paramIdx] = true;
        return {
          ...prevState,
          moduleParameters: newModuleParameters,
          alreadyRead: newAlreadyRead,
          alreadyReadPrimary: isPrimary ? newAlreadyRead : prevState.alreadyReadPrimary,
          alreadyReadSecondary: !isPrimary ? newAlreadyRead : prevState.alreadyReadSecondary,
        };
      });
    } catch (error) {
      logErr('error saving parameter', error);
      setState((prevState) => {
        const newErrorsFromServer = [
          ...(isPrimary ? prevState.errorsFromServerPrimary : prevState.errorsFromServerSecondary),
        ];
        newErrorsFromServer[paramIdx] = error;
        return {
          ...prevState,
          errorsFromServerPrimary: isPrimary
            ? newErrorsFromServer
            : prevState.errorsFromServerPrimary,
          errorsFromServerSecondary: !isPrimary
            ? newErrorsFromServer
            : prevState.errorsFromServerSecondary,
          dialogError: getErrorMessage(error),
          showErrorDialog: true,
        };
      });
    } finally {
      setState((prevState) => {
        const newReading = [...(isPrimary ? prevState.readingPrimary : prevState.readingSecondary)];
        newReading[paramIdx] = false;
        return {
          ...prevState,
          readingPrimary: isPrimary ? newReading : prevState.readingPrimary,
          readingSecondary: !isPrimary ? newReading : prevState.readingSecondary,
        };
      });
    }
  };

  const isSomeSaving = state.savingPrimary.some((s) => s) || state.savingSecondary.some((s) => s);
  const isSomeReading =
    state.readingPrimary.some((s) => s) || state.readingSecondary.some((s) => s);

  return (
    <Box key={compKey} mt={2} px={4} mb={3} position='relative'>
      <OnlyOnDesktop>
        <Flex justifyContent='flex-start'>
          <Box width={150}>
            <Body2 size='0.9rem' color={colors.lightGrey} weight={600}>
              {ls.parameter.toUpperCase()}
            </Body2>
          </Box>
          <Box width={150}>
            <Body2 size='0.9rem' color={colors.lightGrey} weight={600}>
              {ls.description.toUpperCase()}
            </Body2>
          </Box>
          <Box flex={1} />
          {module.type !== MachineType.Ef315 ? (
            <IconButton
              onClick={() => {
                refreshComp();
              }}
            >
              <RefreshIcon style={{ color: isDarkMode ? colors.lightBlue : colors.blue }} />
            </IconButton>
          ) : (
            <Box />
          )}
        </Flex>
      </OnlyOnDesktop>
      <Box mt={1}>
        {!state.moduleParameters || parameters.length === 0 ? (
          <Center>
            <Body2 textAlign='center'>{capitalize(ls.noParametersFound)}</Body2>
          </Center>
        ) : null}
        {state.moduleParameters &&
          primaryParameters.map((p, i) => {
            const isValidInput =
              validator.isNumeric(
                // @ts-ignore
                String(state.moduleParameters[p.name]),
              ) &&
              (p.hideMinMax ||
                // @ts-ignore
                (state.moduleParameters[p.name] >= p.min &&
                  // @ts-ignore
                  state.moduleParameters[p.name] <= p.max));
            const isSaving = state.savingPrimary[i];
            const isReading = state.readingPrimary[i];
            const errorFromServer = state.errorsFromServerPrimary[i];
            const isValueChanged =
              isValidInput &&
              // @ts-ignore
              moduleParametersRef.current[p.name] !== state.moduleParameters[p.name];
            return (
              <Box key={i} my={isMobileOrTablet ? 2 : 0.5}>
                {i !== 0 ? <Box mb={0.5} className='separator' width='100%' /> : null}
                <Flex
                  justifyContent={isMobileOrTablet ? 'center' : 'flex-start'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                >
                  <Box width={isMobileOrTablet ? '100%' : 150}>
                    <OnlyOnDesktop>
                      <Body2 size='1.1rem'>{p.idx}</Body2>
                    </OnlyOnDesktop>
                    <OnlyOnMobileAndTablet>
                      <Flex>
                        <Body2 size='1.1rem'>{`${p.idx} - ${capitalize(
                          // @ts-ignore
                          ls[p.translationKey] || '-',
                        )}`}</Body2>
                        {i === 0 && module.type !== MachineType.Ef315 ? (
                          <IconButton
                            onClick={() => {
                              refreshComp();
                            }}
                          >
                            <RefreshIcon color='primary' />
                          </IconButton>
                        ) : null}
                      </Flex>
                    </OnlyOnMobileAndTablet>
                  </Box>
                  <OnlyOnDesktop flex={1}>
                    <Box mr={2}>
                      <Body2 size='1.1rem'>
                        {capitalize(
                          // @ts-ignore
                          ls[p.translationKey] || '-',
                        )}
                      </Body2>
                    </Box>
                  </OnlyOnDesktop>
                  <Flex alignItems={isMobileOrTablet ? 'flex-end' : 'center'}>
                    {p.selectValues ? (
                      <Box width={200} mt={isMobileOrTablet ? 0.5 : undefined}>
                        <SteielSelect
                          allowedValues={p.selectValues}
                          onChange={(v) => {
                            setState((prevState) => {
                              const newModuleParameters = {
                                ...prevState.moduleParameters,
                              };
                              // @ts-ignore
                              newModuleParameters[p.name] = p.selectValues?.findIndex(
                                (sv) => sv === v,
                              );
                              return {
                                ...prevState,
                                moduleParameters: newModuleParameters,
                              };
                            });
                          }}
                          initialVal={
                            p.selectValues[
                              Number(
                                // @ts-ignore
                                state.moduleParameters[p.name] || 0,
                              )
                            ]
                          }
                        />
                      </Box>
                    ) : (
                      <Flex
                        justifyContent={isMobileOrTablet ? 'center' : 'flex-start'}
                        alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                        flexDirection={isMobileOrTablet ? 'column' : 'row'}
                      >
                        {!p.hideMinMax ? (
                          <Body2
                            mr={1}
                            size='0.8rem'
                            mt={0.5}
                          >{`${ls.min}: ${p.min}, ${ls.max}: ${p.max}`}</Body2>
                        ) : null}
                        <Box width={200} mt={isMobileOrTablet ? 0.5 : undefined}>
                          <TextField
                            size='small'
                            fullWidth
                            type='number'
                            disabled={
                              module.type === MachineType.Ef315
                                ? !state.alreadyReadPrimary[i]
                                : false
                            }
                            value={
                              module.type === MachineType.Ef315
                                ? state.alreadyReadPrimary[i] // @ts-ignore
                                  ? state.moduleParameters[p.name] ?? ''
                                  : ''
                                : // @ts-ignore
                                  state.moduleParameters[p.name] ?? ''
                            }
                            onChange={(e) => {
                              setState((prevState) => {
                                const newModuleParameters = {
                                  ...prevState.moduleParameters,
                                };
                                // @ts-ignore
                                newModuleParameters[p.name] = e.target.value;
                                return {
                                  ...prevState,
                                  moduleParameters: newModuleParameters,
                                };
                              });
                            }}
                          />
                        </Box>
                      </Flex>
                    )}
                    {module.type === MachineType.Ef315 ? (
                      <>
                        {isReading ? (
                          <Center width={42}>
                            <IconButton disabled={true} style={{ marginLeft: 6 }}>
                              <CircularProgress size={20} style={{ color: colors.lightBlue }} />
                            </IconButton>
                          </Center>
                        ) : (
                          <Center width={42}>
                            <IconButton
                              disabled={isSomeSaving || isSomeReading}
                              style={{ marginLeft: 6 }}
                              onClick={() => {
                                handleReadParameter(p, i, true);
                              }}
                            >
                              <RefreshIcon color='primary' />
                            </IconButton>
                          </Center>
                        )}
                      </>
                    ) : null}
                    {isSaving ? (
                      <Center width={42}>
                        <IconButton disabled={true} style={{ marginLeft: 6 }}>
                          <CircularProgress size={20} style={{ color: colors.green }} />
                        </IconButton>
                      </Center>
                    ) : (
                      <Center width={42}>
                        {isValueChanged ? (
                          <IconButton
                            disabled={!isValidInput || isSomeSaving || isSomeReading}
                            style={{ marginLeft: 6 }}
                            onClick={() => {
                              setState({
                                paramToSave: { p, pIdx: i, isPrimary: true },
                                showSaveConfirmDialog: true,
                              });
                            }}
                          >
                            <CustomSvgIcon src={assets.greenSave} onClick={() => {}} />
                          </IconButton>
                        ) : null}
                      </Center>
                    )}
                  </Flex>
                </Flex>
                {/* <ErrorMessage
                  containerStyle={{ mt: 1 }}
                  message={getErrorMessage(errorFromServer)}
                /> */}
              </Box>
            );
          })}
        {primaryParameters.length > 0 ? <Box mb={0.5} className='separator' width='100%' /> : null}
        {secondaryParameters.length > 0 ? (
          <Box
            my={2}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              gs.state.secondaryParameterOpen = !gs.snap.secondaryParameterOpen;
            }}
          >
            <Flex>
              <Body1 size='1.1rem' weight={700}>
                {capitalize(ls.secondaryParameters)}
              </Body1>
              <IconButton
                onClick={() => {}}
                style={{
                  rotate: gs.snap.secondaryParameterOpen ? '180deg' : '0deg',
                }}
              >
                <CustomSvgIcon src={assets.paramsDetail} onClick={() => {}} />
              </IconButton>
            </Flex>
          </Box>
        ) : null}
        {state.moduleParameters &&
          gs.snap.secondaryParameterOpen &&
          secondaryParameters.map((p, i) => {
            const isValidInput =
              validator.isNumeric(
                // @ts-ignore
                String(state.moduleParameters[p.name]),
              ) &&
              (p.hideMinMax ||
                // @ts-ignore
                (state.moduleParameters[p.name] >= p.min &&
                  // @ts-ignore
                  state.moduleParameters[p.name] <= p.max));
            const isSaving = state.savingSecondary[i];
            const isReading = state.readingSecondary[i];
            const errorFromServer = state.errorsFromServerSecondary[i];
            const isValueChanged =
              isValidInput &&
              // @ts-ignore
              moduleParametersRef.current[p.name] !== state.moduleParameters[p.name];
            return (
              <Box key={i} my={isMobileOrTablet ? 2 : 0.5}>
                {i !== 0 ? <Box mb={0.5} className='separator' width='100%' /> : null}
                <Flex
                  justifyContent={isMobileOrTablet ? 'center' : 'flex-start'}
                  alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                  flexDirection={isMobileOrTablet ? 'column' : 'row'}
                >
                  <Box width={isMobileOrTablet ? '100%' : 150}>
                    <OnlyOnDesktop>
                      <Body2 size='1.1rem'>{p.idx}</Body2>
                    </OnlyOnDesktop>
                    <OnlyOnMobileAndTablet>
                      <Flex>
                        <Body2 size='1.1rem'>{`${p.idx} - ${capitalize(
                          // @ts-ignore
                          ls[p.translationKey] || '-',
                        )}`}</Body2>
                        {i === 0 ? (
                          <IconButton
                            onClick={() => {
                              refreshComp();
                            }}
                          >
                            <RefreshIcon color='primary' />
                          </IconButton>
                        ) : null}
                      </Flex>
                    </OnlyOnMobileAndTablet>
                  </Box>
                  <OnlyOnDesktop flex={1}>
                    <Box mr={2}>
                      <Body2 size='1.1rem'>
                        {capitalize(
                          // @ts-ignore
                          ls[p.translationKey] || '-',
                        )}
                      </Body2>
                    </Box>
                  </OnlyOnDesktop>
                  <Flex alignItems={isMobileOrTablet ? 'flex-end' : 'center'}>
                    {p.selectValues ? (
                      <Box width={200} mt={isMobileOrTablet ? 0.5 : undefined}>
                        <SteielSelect
                          allowedValues={p.selectValues}
                          onChange={(v) => {
                            setState((prevState) => {
                              const newModuleParameters = {
                                ...prevState.moduleParameters,
                              };
                              // @ts-ignore
                              newModuleParameters[p.name] = p.selectValues?.findIndex(
                                (sv) => sv === v,
                              );
                              return {
                                ...prevState,
                                moduleParameters: newModuleParameters,
                              };
                            });
                          }}
                          initialVal={
                            p.selectValues[
                              Number(
                                // @ts-ignore
                                state.moduleParameters[p.name] || 0,
                              )
                            ]
                          }
                        />
                      </Box>
                    ) : (
                      <Flex
                        justifyContent={isMobileOrTablet ? 'center' : 'flex-start'}
                        alignItems={isMobileOrTablet ? 'flex-start' : 'center'}
                        flexDirection={isMobileOrTablet ? 'column' : 'row'}
                      >
                        {!p.hideMinMax ? (
                          <Body2
                            mr={1}
                            size='0.8rem'
                            mt={0.5}
                          >{`${ls.min}: ${p.min}, ${ls.max}: ${p.max}`}</Body2>
                        ) : null}
                        <Box width={200} mt={isMobileOrTablet ? 0.5 : undefined}>
                          <TextField
                            size='small'
                            fullWidth
                            type='number'
                            disabled={
                              module.type === MachineType.Ef315
                                ? !state.alreadyReadSecondary[i]
                                : false
                            }
                            value={
                              module.type === MachineType.Ef315
                                ? state.alreadyReadSecondary[i] // @ts-ignore
                                  ? state.moduleParameters[p.name] ?? ''
                                  : ''
                                : // @ts-ignore
                                  state.moduleParameters[p.name] ?? ''
                            }
                            onChange={(e) => {
                              setState((prevState) => {
                                const newModuleParameters = {
                                  ...prevState.moduleParameters,
                                };
                                // @ts-ignore
                                newModuleParameters[p.name] = e.target.value;
                                return {
                                  ...prevState,
                                  moduleParameters: newModuleParameters,
                                };
                              });
                            }}
                          />
                        </Box>
                      </Flex>
                    )}
                    {module.type === MachineType.Ef315 ? (
                      <>
                        {isReading ? (
                          <Center width={42}>
                            <IconButton disabled={true} style={{ marginLeft: 6 }}>
                              <CircularProgress size={20} style={{ color: colors.lightBlue }} />
                            </IconButton>
                          </Center>
                        ) : (
                          <Center width={42}>
                            <IconButton
                              disabled={isSomeSaving || isSomeReading}
                              style={{ marginLeft: 6 }}
                              onClick={() => {
                                handleReadParameter(p, i, false);
                              }}
                            >
                              <RefreshIcon color='primary' />
                            </IconButton>
                          </Center>
                        )}
                      </>
                    ) : null}
                    {isSaving ? (
                      <Center width={42}>
                        <IconButton disabled={true} style={{ marginLeft: 6 }}>
                          <CircularProgress size={20} style={{ color: colors.green }} />
                        </IconButton>
                      </Center>
                    ) : (
                      <Center width={42}>
                        {isValueChanged ? (
                          <IconButton
                            disabled={!isValidInput || isSomeSaving || isSomeReading}
                            style={{ marginLeft: 6 }}
                            onClick={() => {
                              setState({
                                paramToSave: { p, pIdx: i, isPrimary: false },
                                showSaveConfirmDialog: true,
                              });
                            }}
                          >
                            <CustomSvgIcon src={assets.greenSave} onClick={() => {}} />
                          </IconButton>
                        ) : null}
                      </Center>
                    )}
                  </Flex>
                </Flex>
                {/* <ErrorMessage
                  containerStyle={{ mt: 1 }}
                  message={getErrorMessage(errorFromServer)}
                /> */}
              </Box>
            );
          })}
      </Box>
      <SteielDialog
        title={capitalize(ls.attention)}
        content={capitalize(ls.saveChangesQuestion)}
        onClose={() => {
          setState({ showSaveConfirmDialog: false });
        }}
        onOkActionClick={async () => {
          setState({ showSaveConfirmDialog: false });
          await handleSaveParameter(
            state.paramToSave.p,
            state.paramToSave.pIdx,
            state.paramToSave.isPrimary,
          );
        }}
        onKoActionClick={() => {
          setState({ showSaveConfirmDialog: false });
        }}
        open={state.showSaveConfirmDialog}
      />
      <SteielDialog
        open={state.showErrorDialog}
        title={capitalize(ls.attention)}
        content=''
        okLabel={capitalize(ls.ok)}
        onClose={() => {
          setState({ showErrorDialog: false });
        }}
        onOkActionClick={() => {
          setState({ showErrorDialog: false });
        }}
        DialogChildren={() => (
          <Box>
            <Body2 textAlign='center'>{capitalize(ls.updateParamFailed) + '!'}</Body2>
            {state.dialogError ? (
              <Body1 mt={2} textAlign='center' color={colors.red}>
                {state.dialogError}
              </Body1>
            ) : null}
          </Box>
        )}
      />
    </Box>
  );
};

export default ParametersDetail;
