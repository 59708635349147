import React from 'react';
import { Box, Switch } from '@mui/material';
import Flex from './ui/Flex';
import { colors } from '../theme';
import { Body2 } from './ui/Typography';
import { ls } from '../i18n/translations';
import SteielButton from './ui/SteielButton';
import { useMQ } from '../hook/useMQ';
import { OnlyOnMobile, OnlyOnTabletAndDesktop } from './ui/Responsive';
import { useDarkMode } from '../hook/useDarkMode';

interface InOutItemProps {
  name: string;
  isActive: boolean;
  onBtnClick?: () => void;
  // onSwitchValueChange?: (v: boolean) => void;
  // switchValue?: boolean;
  bgColor?: string;
  customActiveText?: string;
  customNotActiveText?: string;
}

const InOutItem: React.FC<InOutItemProps> = ({
  isActive,
  name,
  onBtnClick,
  // onSwitchValueChange,
  // switchValue,
  bgColor,
  customActiveText,
  customNotActiveText,
}) => {
  const { isMobile } = useMQ();
  const { isDarkMode } = useDarkMode();

  // const SwitchComp: React.ReactNode = onSwitchValueChange ? (
  //   <Switch
  //     checked={switchValue}
  //     onChange={(e) => {
  //       onSwitchValueChange?.(e.target.checked);
  //     }}
  //     color={'success'}
  //   />
  // ) : null;

  return (
    <Box width='100%'>
      <Box
        borderRadius={1}
        border='1px solid'
        borderColor={colors.lightGrey}
        py={1}
        px={isMobile ? 1 : 2}
        width='100%'
        // height={56}
      >
        <Flex>
          <Box>
            <Body2
              color={isDarkMode ? colors.lightGrey : colors.stroke}
              size={isMobile ? '0.9rem' : undefined}
            >
              {name}
            </Body2>
          </Box>
          <Box>
            {/* {isMobile ? SwitchComp : null} */}
            <SteielButton
              text={
                isActive
                  ? customActiveText || ls.active.toUpperCase()
                  : customNotActiveText || ls.deactivated.toUpperCase()
              }
              style={{
                backgroundColor: bgColor ? bgColor : isActive ? colors.green : colors.red,
                minWidth: isMobile ? 70 : 110,
                marginTop: 0.5,
                paddingLeft: 8,
                paddingRight: 8,
                borderColor: isDarkMode
                  ? 'transparent'
                  : bgColor
                  ? bgColor
                  : isActive
                  ? colors.green
                  : colors.red,
              }}
              typographyProps={{
                size: isMobile ? '0.7rem' : undefined,
              }}
              borderColor={bgColor ? bgColor : isActive ? colors.green : colors.red}
              disabled
              onClick={onBtnClick}
            />
            {/* {isDesktop ? SwitchComp : null} */}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default InOutItem;
