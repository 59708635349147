import { useMediaQuery, useTheme } from '@mui/material';

export const useMQ = () => {
  const theme = useTheme();
  const isUpXs = useMediaQuery(theme.breakpoints.up('xs'));
  const isUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isUpXl = useMediaQuery(theme.breakpoints.up('xl'));
  const isDownXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isDownXl = useMediaQuery(theme.breakpoints.down('xl'));

  const isMobile = isDownSm;
  const isTablet = !isDownSm && isDownLg;
  const isDesktop = (!isDownSm && !isDownLg && isDownXl) || isUpXl;

  return {
    isUpXs,
    isUpSm,
    isUpMd,
    isUpLg,
    isUpXl,
    isDownXs,
    isDownSm,
    isDownMd,
    isDownLg,
    isDownXl,
    // isXs: isDownXs,
    // isSm: isUpXs && isDownXs,
    // isMd: isUpSm && isDownSm,
    // isLg: isUpMd && isDownMd,
    // isXl: isUpLg && isDownLg,
    isMobile,
    isTablet,
    isDesktop,
    isMobileOrTablet: isMobile || isTablet,
    isTabletOrDesktop: isTablet || isDesktop,
  };
};
