import React from 'react';
import { Box, BoxProps } from '@mui/material';

const Flex: React.FC<BoxProps> = ({ children, ...otherProps }) => {
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' {...otherProps}>
      {children}
    </Box>
  );
};

export const Stack: React.FC<BoxProps> = ({ children, ...otherProps }) => {
  return (
    <Box display='flex' flexDirection='column' {...otherProps}>
      {children}
    </Box>
  );
};

export const Group: React.FC<BoxProps> = ({ children, ...otherProps }) => {
  return (
    <Box display='flex' flexDirection='row' {...otherProps}>
      {children}
    </Box>
  );
};

export default Flex;
